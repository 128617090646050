import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

interface MuiAccordion {
  defaultProps?: ComponentsProps['MuiAccordion']
  styleOverrides?: ComponentsOverrides['MuiAccordion']
  variants?: ComponentsVariants['MuiAccordion']
}

export const muiAccordion: MuiAccordion = {
  styleOverrides: {
    root: {
      margin: 0,
      backgroundColor: 'white',
      boxShadow: 'none',
      borderBottom: '1px solid rgba(0,0,0,.12)',
      transition: 'all 150ms cubic-bezier(.4,0,.2,1) 0ms',
      '&::before': {
        display: 'none',
      },
    },
  },
  defaultProps: {
    disableGutters: true,
    square: true,
  },
}

export const muiAccordionSummary = {
  styleOverrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}

export const muiAccordionDetails = {
  styleOverrides: {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
}
