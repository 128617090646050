import { IconVariants, UibCustomIcon } from '@/assets'
import { Link } from '@/generated/openapi'
import { LinkCategory } from '@/hooks'
import { uibRed50, uibRed600 } from '@/styles'
import { Box, Link as MuiLink, Typography, styled } from '@mui/material'

const StyledMainLink = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  width: '74px',
  alignItems: 'center',
  justifyContent: 'start',
  marginBottom: 'auto',
  '&:hover': {
    textDecoration: 'underline',
    '.linkIcon': {
      backgroundColor: 'transparent',
      svg: {
        path: {
          fill: uibRed600,
        },
      },
    },
  },
}))

export const MainLinks = ({ categoryEntry, isMobile }: { categoryEntry: LinkCategory; isMobile: boolean }) => {
  return (
    <Box
      maxWidth={{ xs: 'none', md: '179px' }}
      display={'flex'}
      flexDirection={{ xs: 'row', md: 'column', lg: 'row' }}
      flexWrap={'wrap'}
      gap={isMobile ? '0rem' : '1rem'}
      justifyContent={'space-between'}
      alignItems={{ xs: 'space-between', md: 'start' }}>
      {categoryEntry.links.map((link, index) => {
        /** There is some fizzy logic going on here.
         *  We want the backend to be able to control the objects, but we also need to override certain elements.
         * We cannot match for sure as things might change and we do not have any moving keys.
         * Therefore: fizzy logic to allow some elements to change for now.
         *  */

        if (link.url?.includes('mitt.uib')) {
          return <LargeNavigationLinkButtons key={index} link={link} icon='monitor' />
        }
        if (link.url?.includes('outlook')) {
          return <LargeNavigationLinkButtons key={index} link={link} icon='mail' />
        }
        if (link.title?.includes('hjelp') || link.title?.includes('help')) {
          return <LargeNavigationLinkButtons key={index} link={link} icon='help' />
        }
        if (link.title?.toLocaleLowerCase()?.includes('microsoft')) {
          return <LargeNavigationLinkButtons key={index} link={link} icon='grid' />
        }
      })}
    </Box>
  )
}

const LargeNavigationLinkButtons = ({ link, icon }: { link: Link; icon: IconVariants }) => {
  return (
    <StyledMainLink href={link.url}>
      <Box
        className='linkIcon'
        sx={{
          width: '52px',
          height: '52px',
          borderRadius: '50px',
          backgroundColor: uibRed50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <UibCustomIcon icon={icon} sx={{ fontSize: '2rem' }} colorOverride={uibRed600} />
      </Box>
      <Typography textAlign={'center'}>{link.title}</Typography>
    </StyledMainLink>
  )
}
