import { useNotifications } from '@/api/hooks'
import { UibCustomIcon } from '@/assets'
import { Badge } from '@mui/material'
import { useNavigate } from '@tanstack/react-location'

import { StyledNavbarIconButton } from './StyledNavbarIconButton'

export const NotificationNavbarItem = () => {
  const { numberOfUnreadNotifications } = useNotifications(true)
  const nav = useNavigate()

  return (
    <StyledNavbarIconButton onClick={() => nav({ to: 'notifications' })}>
      <Badge badgeContent={numberOfUnreadNotifications} max={9} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {/** @todo aria label for what the button represents */}
        <UibCustomIcon icon='notification_bell' />
      </Badge>
    </StyledNavbarIconButton>
  )
}
