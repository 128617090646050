import { Breakpoints, ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { palette, uibGreen400, uibRed500 } from '../palette'

interface MuiBadge {
  defaultProps?: ComponentsProps['MuiBadge']
  styleOverrides?: ComponentsOverrides['MuiBadge']
  variants?: ComponentsVariants['MuiBadge']
}

const marginX = 1
const marginY = 0.25
const desiredOverLapX = 1.25
const desiredOverLapY = 1.25

const createMenuAnchorOverride = (vertical: 'Top' | 'Bottom', horizontal: 'Left' | 'Right') => {
  // translate tells the badge to move relative to its parent (container),
  //  putting it into a corner with additional marginX/marginY to get slightly "outside" the bbox
  const translateX = `translateX(${horizontal === 'Right' ? '' : '-'}${marginX}rem)`
  const translateY = `translateY(${vertical === 'Top' ? '-' : ''}${marginY}rem)`

  return {
    [`&.MuiBadge-anchorOrigin${vertical}${horizontal}`]: {
      transform: `scale(1) ${translateX} ${translateY}`,

      // However, if the icon grows it will overlap more with text or whichever element
      //  To prevent this we also need to offset its position by the badge's width
      //  And move the element back inside the container if needed
      top: vertical === 'Top' ? 'auto' : `calc(100% - ${desiredOverLapY}rem)`,
      right: horizontal === 'Right' ? 'auto' : `calc(100% - ${desiredOverLapX}rem)`,
      bottom: vertical === 'Bottom' ? 'auto' : `calc(100% - ${desiredOverLapY}rem)`,
      left: horizontal === 'Left' ? 'auto' : `calc(100% - ${desiredOverLapX}rem)`,
    },
  }
}

const createSize = (multiplier: number, padding: string) => ({
  fontSize: `${multiplier * 3}px`,
  padding: padding,
  height: `${multiplier * 4}px`,
  minWidth: `${multiplier * 4}px`,
  borderRadius: `${multiplier * 2}px`,
})

const createSizes = (breakpoints: Breakpoints) => {
  const lgSize = createSize(6, `3px 0 3px 0`)
  const mdSize = createSize(5, `2px 0 2px 0`)

  return Object.assign(lgSize, {
    [breakpoints.down('md')]: mdSize,
  })
}

export const getMuiBadge = (breakpoints: Breakpoints, fontFamily: string) => {
  const muiBadge: MuiBadge = {
    styleOverrides: {
      root: {},
      badge: {
        fontFamily: fontFamily,
        color: 'white',
        backgroundColor: uibRed500,
        fontWeight: '500',
        border: '1px solid currentColor',
      },
    },
    variants: [
      {
        props: { variant: 'menuBadge' },
        style: {
          '.MuiBadge-badge': Object.assign(
            createMenuAnchorOverride('Top', 'Right'),
            createMenuAnchorOverride('Top', 'Left'),
            createMenuAnchorOverride('Bottom', 'Right'),
            createMenuAnchorOverride('Bottom', 'Left'),
            createSizes(breakpoints)
          ),
        },
      },
      {
        props: { color: 'secondary' },
        style: {
          '.MuiBadge-badge': {
            backgroundColor: uibGreen400,
            color: palette.text?.primary,
            border: 'none',
          },
        },
      },
      {
        props: { variant: 'dot' },
        style: {
          '& > .MuiBadge-dot': {
            height: '10px',
            width: '10px',
            borderRadius: '10px',
            '&.MuiBadge-anchorOriginTopLeft': {
              left: '-5px',
            },
          },
        },
      },
    ],
  }
  return muiBadge
}
