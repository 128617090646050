import { PropsWithChildren, useEffect } from 'react'

import { axiosInstance, profileClient } from '@/api/client'
import { unsubscribeFromPushNotifications } from '@/api/hooks/usePushSubscription'
import useUserStorageState from '@/api/hooks/useUserStorageState'
import axios from 'axios'

import UserContext from './UserContext'

interface Props {
  checkOnInit?: boolean
}

let checkAuthPromise: Promise<boolean> | null = null

const UserContextProvider = ({ checkOnInit = true, children }: PropsWithChildren<Props>) => {
  const [sanctumState, setSanctumState] = useUserStorageState()
  const user = sanctumState.user
  const authenticated = sanctumState.authenticated

  const login = async () => {
    await axiosInstance.get('sanctum/csrf-cookie')
    window.location.href = `${import.meta.env.VITE_DASHBOARD_API_URL}/login`
  }

  const logout = async () => {
    /**
     * For unknown reasons, possible related to how Sanctum handles CRSF tokens and build versioning,
     * we might end up with with a partly valid CRSF token. Symptoms include being able to fetch data, but logout endpoint
     * failing to validate. We therefore manually clear our CRSF token cache on logout. Failure to do so result in automatically logging in
     * on refresh.
     */
    try {
      let unsubPromise = unsubscribeFromPushNotifications()
      let logoutPromise = axiosInstance.post(`${import.meta.env.VITE_DASHBOARD_API_URL}/logout`)
      // Unsubscribe from notifications and log out must both be awaited, as later redirect to homepage interrupts
      // TODO strongly consider clearing SW cache here by sending a message
      await Promise.all([unsubPromise, logoutPromise])
    } catch (error) {
      axiosInstance.delete('sanctum/csrf-cookie')
    }

    setSanctumState({ user: null, authenticated: null })
    window.location.reload()
    window.location.href = '/'
  }

  const setLocale = async (language: string) => {
    await profileClient.setLocale(language)
  }

  const revalidate = (): Promise<boolean | { user: {} }> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await profileClient.getProfile({
          maxRedirects: 0,
        })

        //It seems like 419 status is possible as a result of CRSF token mismatch
        if (!response || response.status === 401 || response.status === 419) {
          return reject(new Error('Unauthorized'))
        }

        if (response.data) {
          const user = response.data.data

          if (user) {
            setSanctumState({ user, authenticated: true })
            resolve({ user })
          }
        }

        return reject(new Error('User does not exist'))
      } catch (error) {
        if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
          // If there's a 401 error the user is not signed in.
          unsubscribeFromPushNotifications()
          setSanctumState({ user: null, authenticated: false })
          return resolve(false)
        } else {
          // If there's any other error, something has gone wrong.
          return reject(error)
        }
      }
    })
  }

  /** Helper function for checking authentication */
  const checkAuthentication = (checkOnInit: boolean): Promise<boolean> => {
    if (checkAuthPromise) return checkAuthPromise

    checkAuthPromise = new Promise(async (resolve, reject) => {
      if (checkOnInit === true || authenticated === null) {
        // The status is null if we haven't checked it, so we have to make a request.
        try {
          await revalidate()
          return resolve(true)
        } catch (error) {
          if (axios.isAxiosError(error) && error.response && error.response.status === 401) {
            // If there's a 401 error the user is not signed in.
            setSanctumState({ user: null, authenticated: false })
            return resolve(false)
          } else {
            // If there's any other error, something has gone wrong or user is not logged in
            return reject(error)
          }
        }
      } else {
        // If it has been checked with the server before, we can just return the state.
        return resolve(authenticated)
      }
    })

    checkAuthPromise.finally(() => {
      checkAuthPromise = null
    })

    return checkAuthPromise
  }

  useEffect(() => {
    if (checkOnInit) {
      checkAuthentication(checkOnInit)
    }
  }, [])

  return (
    <UserContext.Provider
      children={children || null}
      value={{
        user,
        authenticated,
        login,
        logout,
        setLocale,
        revalidate,
      }}
    />
  )
}

export default UserContextProvider
