import useAuthenticatedUser from '@/contexts/user/useAuthenticatedUser'
import { Talkr } from 'talkr'

import { langOptions } from './languageOptions'
import en from './translationDictionaries/en.json'
import nb from './translationDictionaries/nb.json'
import nn from './translationDictionaries/nn.json'

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuthenticatedUser()

  const defaultLangFinder = () => {
    //Check if local storage has a setting.
    const storedLanguage = localStorage.getItem('uibTreetLanguageSettingKey')
    if (storedLanguage != null) {
      if (langOptions.some((option) => option.locale === storedLanguage)) {
        return storedLanguage
      }
    }
    if (user && user.language) {
      return user.language
    }
    //Find closes matching default language from browser
    return navigator.language === 'no' || navigator.language === 'nb' ? 'nb' : navigator.language === 'en' ? 'en' : 'nn'
  }

  return (
    <Talkr languages={{ nb, nn, en }} defaultLanguage={defaultLangFinder()} detectBrowserLanguage={false}>
      {children}
    </Talkr>
  )
}
