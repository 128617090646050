import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { defaultFocusStyleLight } from '../focusStyling'

interface MuiListItemButton {
  defaultProps?: ComponentsProps['MuiListItemButton']
  styleOverrides?: ComponentsOverrides['MuiListItemButton']
  variants?: ComponentsVariants['MuiListItemButton']
}

export const muiListItemButton: MuiListItemButton = {
  styleOverrides: {
    root: {
      '&:focus-visible': {
        '&::before': defaultFocusStyleLight,
      },
    },
  },
}
