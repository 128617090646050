import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { CssBaseline, ThemeProvider } from '@mui/material'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Settings as TimezoneSettings } from 'luxon'

import App from './App'
import MatomoContextProvider from './contexts/matomo'
import UserContextProvider from './contexts/user/UserContextProvider'
import { LanguageProvider } from './language'
import theme from './styles/theme'

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } })
const root = createRoot(document.getElementById('root') as HTMLElement)

//Configures the application to always display local time in Norway
TimezoneSettings.defaultZone = 'Europe/Oslo'

root.render(
  <>
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <UserContextProvider checkOnInit={true}>
          <LanguageProvider>
            <ThemeProvider theme={theme}>
              <MatomoContextProvider>
                <CssBaseline />
                <App />
                <ReactQueryDevtools />
              </MatomoContextProvider>
            </ThemeProvider>
          </LanguageProvider>
        </UserContextProvider>
      </QueryClientProvider>
    </StrictMode>
  </>
)
