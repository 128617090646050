import { useEffect, useState } from 'react'

import { UibCustomIcon } from '@/assets'
import { ComponentError, ComponentLoading } from '@/components/states'
import useAuthenticatedUser from '@/contexts/user/useAuthenticatedUser'
import { useCategorizedLinks } from '@/hooks'
import { useUibLanguage } from '@/language'
import { MaxWidthLayoutBox } from '@/modules/layout/maxWidthGuards'
import { Box, Collapse, ListItemButton, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'

import { ShortcutContent } from './components/ShortcutContent'

/**
 * Semi-deprecated:
 * - partially replaced by new shortcut collection
 * - still relevant for users who aren't logged in
 * - might be replaced by channels module
 */
export const Shortcuts = () => {
  const { organizedLinks, status, refetch } = useCategorizedLinks()
  const theme = useTheme()
  const { langDict } = useUibLanguage()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { authenticated } = useAuthenticatedUser()
  const [isOpen, setIsOpen] = useState<boolean | undefined>(true)

  useEffect(() => {
    if (authenticated === true) {
      setIsOpen(false)
    }
  }, [authenticated])

  let content
  if (status === 'pending' || organizedLinks === undefined) {
    content = <ComponentLoading loadingMessage={langDict('shortcuts.loading')} stackSx={{ gap: '1rem' }} />
  } else if (status === 'error') {
    content = (
      <ComponentError
        retry={refetch}
        errorMessage={organizedLinks.length === 0 ? langDict('shortcuts.empty') : langDict('shortcuts.error')}
      />
    )
  } else {
    content = (
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'column' },
          alignItems: 'left',
          margin: '0 auto',
        }}>
        <ListItemButton
          sx={{ display: { xs: 'flex' }, justifyContent: 'space-between', padding: '0.5rem 0' }}
          onClick={() => setIsOpen(!isOpen)}>
          <Typography variant='h2'>{langDict('shortcuts.header')} </Typography>
          <UibCustomIcon
            fontSize='small'
            icon='arrow_down'
            sx={{ transform: `rotate(${isOpen ? 180 : 0}deg)`, transition: '0.2s' }}
          />
        </ListItemButton>
        <Collapse in={isOpen} sx={{ width: '100%' }}>
          <ShortcutContent isMobile={isMobile} organizedLinks={organizedLinks} />
        </Collapse>
      </Box>
    )
  }

  return (
    <Paper variant='beige' sx={{ display: 'flex', justifyContent: 'center' }}>
      <MaxWidthLayoutBox py={{ xs: '1.5rem', md: '2rem' }}>{content}</MaxWidthLayoutBox>
    </Paper>
  )
}
