import { useState } from 'react'

import { useImpersonate } from '@/api/hooks'
import { UibCustomIcon } from '@/assets'
import useAuthenticatedUser from '@/contexts/user/useAuthenticatedUser'
import { LangKey, useUibLanguage } from '@/language'
import { Box, Button, CircularProgress, IconButton, OutlinedInput, Stack, Typography } from '@mui/material'
import { QueryStatus } from '@tanstack/react-query'

import { SlidingModal } from './SlidingModal'

type CustomQueryStatus = QueryStatus | ('errorForbidden' | 'errorNoUser' | 'errorUnknown' | 'idle')

export const ImpersonationModal = ({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) => {
  const [username, setUsername] = useState<string>('')
  const { revalidate } = useAuthenticatedUser()
  const [status, setStatus] = useState<CustomQueryStatus>('idle')
  const { initilizeImpersonation } = useImpersonate()
  const { langDict } = useUibLanguage()

  const impersonate = async () => {
    if (username) {
      setStatus('pending')
      const responseCode = await initilizeImpersonation(username)
      if (responseCode === 200) {
        setStatus('success')
        revalidate()
        onClose()
        return
      }
      if (responseCode === 403) {
        setStatus('errorForbidden')
        return
      }
      if (responseCode === 400) {
        setStatus('errorNoUser')
        return
      }
      setStatus('errorUnknown')
    }
  }

  return (
    <SlidingModal isOpen={isOpen} onClose={onClose}>
      <Box display='flex' justifyContent={'space-between'}>
        <Typography variant='h2'>{langDict('impersonation.header')}</Typography>
        <IconButton onClick={onClose} aria-label={langDict('generic.closeButton')}>
          <UibCustomIcon icon='close' />
        </IconButton>
      </Box>
      <Stack justifyContent={'center'} alignItems={'center'} gap={'2rem'}>
        <Typography variant='body1'>{langDict('impersonation.introText')}</Typography>
        <OutlinedInput
          value={username}
          onChange={(event) => setUsername(event.target.value)}
          sx={{ width: '300px' }}
          color='secondary'
          error={status.includes('error')}
          placeholder={langDict('impersonation.inputPlaceholder')}
        />
        {status.includes('error') && (
          <Typography color='error'>{langDict(`impersonation.${status}` as LangKey)}</Typography>
        )}
        {status === 'success' && (
          <Typography color='success'>{langDict('impersonation.impersonationSuccess')}</Typography>
        )}
        <Button variant='contained' onClick={impersonate} disabled={!username || status === 'pending'}>
          {status === 'pending' ? <CircularProgress /> : langDict('impersonation.buttonLabel')}
        </Button>
      </Stack>
    </SlidingModal>
  )
}
