import { UibIllustration } from '@/assets'
import { CircularProgress, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material'

export const ComponentLoading = ({
  loadingMessage,
  stackSx,
  contrast,
}: {
  loadingMessage: string
  stackSx?: SxProps<Theme>
  contrast?: boolean
}) => {
  const theme = useTheme()
  return (
    <Stack width='100%' alignItems={'center'} color={theme.palette.primary.dark} gap={'2rem'} sx={stackSx}>
      <UibIllustration
        width='159'
        height='159'
        illustration={contrast ? 'Illustration_waiting_contrast' : 'Illustration_waiting'}
      />
      <Typography>{loadingMessage}</Typography>
      <CircularProgress aria-label={loadingMessage} color='inherit' />
    </Stack>
  )
}
