import { useEffect } from 'react'

import { examClient } from '@/api/client'
import { examDummies } from '@/dummyData/newExamDummies'
// import { examDummies } from '@/dummyData/examDummies'
import { GetUserExams200ResponseData } from '@/generated/openapi'
import { mapScheduleEventsToCalendarData } from '@/pages/CalendarPage/CalendarUtils'
import { useQuery } from '@tanstack/react-query'

import useUserStorageState from './useUserStorageState'

const USE_DUMMY_DATA = import.meta.env.VITE_USE_DUMMY_DATA

export const QUERY_KEY_EXAMS = 'EXAM_QUERY'

export const fetchExamsQueryFn = async () => {
  if (USE_DUMMY_DATA) {
    return examDummies
  }
  const resp = await examClient.getUserExams()
  if (resp.status === 202) {
    throw new Error('Fetching exams took too long and timed out')
  }
  return resp.data.data
}

const selectExamData = (data: GetUserExams200ResponseData | undefined) => ({
  examsByCourse: data?.examsByCourse,
  examsByDate: data?.examsByDate,
  examsForCalendar: mapScheduleEventsToCalendarData(data?.examsForCalendar),
})

/**
 * Gets the users exam data in all formats
 * - for calendar
 * - by course
 * - by date
 *
 * NOTE: This is merged as 1 function due to exam backend being very slow,
 *    which lets us fetch  data once instead of multiple times on different pages for each format
 */
export function useExams() {
  const [{ authenticated, user }] = useUserStorageState()
  const { refetch, data, status } = useQuery({
    queryKey: [QUERY_KEY_EXAMS],
    queryFn: fetchExamsQueryFn,
    select: selectExamData,
    enabled: authenticated || false,
    retryDelay: 3000,
    retry: 5,
    staleTime: 1000 * 60 * 60, // 1 hour
  })

  useEffect(() => {
    refetch()
  }, [user?.language, user?.is_impersonating])

  return {
    status,
    refetch,
    ...data,
  }
}
