import { CalendarEventDetails } from '@/generated/openapi'
import { TREET_EVENT_TYPES } from '@/pages/CalendarPage/CalendarComponents/CalendarEvent/CalendarEvent'
import { EnrichedCalendarEvent } from '@/pages/CalendarPage/CalendarUtils'
import { DateTime } from 'luxon'

let courses = ['INF100', 'MAT111', 'DAT130', 'EXFAC', 'INFOSEC']
let type: TREET_EVENT_TYPES[] = ['assignment', 'event', 'exam', 'resource']

const getRandomCourse = () => courses[Math.floor(courses.length * Math.random())]
const getRandomTypeAndTitle = () => {
  let eventType = type[Math.floor(type.length * Math.random())]
  return {
    event_type: eventType,
    title: eventType as string,
  }
}

const generateEventTime = () => {
  const now = DateTime.now()
  const startOfMonthInMS = now.startOf('month').startOf('week').toMillis()
  const endOfMonthInMs = now.endOf('month').endOf('week').toMillis()

  // Generate a random start time in current month
  const eventStartInMs = startOfMonthInMS + Math.random() * (endOfMonthInMs - startOfMonthInMS)
  let eventdStartDT = DateTime.fromMillis(eventStartInMs)

  // Let events start in 5 minute intervals
  eventdStartDT = eventdStartDT.set({
    minute: Math.floor(eventdStartDT.minute / 5) * 5,
    second: 0,
  })
  // Events should last from 5m to 5 hours (in 5m intervals)
  let eventEndDT = eventdStartDT.plus({
    minutes: Math.floor(Math.random() * 60 + 1) * 5,
  })

  let all_day = false
  let randomSelection = Math.random() * 100
  // Let the event be multiday 5% of the time
  if (randomSelection < 5) {
    all_day = true
    eventEndDT = eventEndDT.plus({ days: Math.random() < 0.5 ? 2 : 1 })
  }
  // Let event be all day 5% times
  else if (randomSelection < 10) {
    all_day = true
    eventdStartDT = eventdStartDT.startOf('day')
    eventEndDT = eventdStartDT.endOf('day')
  }

  return {
    start: eventdStartDT.toISO(),
    end: eventEndDT.toISO(),
    all_day: all_day,
  }
}

const generateRandomEvent = (): CalendarEventDetails => {
  return Object.assign(
    {
      parent_code: getRandomCourse(),
    },
    generateEventTime(),
    getRandomTypeAndTitle()
  )
}

const generateRandomEventsWithinCurrentMonth = (numberOfEvents = 60) => {
  let events = []
  for (let i = 0; i < numberOfEvents; i++) {
    events.push(generateRandomEvent())
  }
  return events
}

export { generateRandomEventsWithinCurrentMonth }
