import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

interface MuiList {
  defaultProps?: ComponentsProps['MuiList']
  styleOverrides?: ComponentsOverrides['MuiList']
  variants?: ComponentsVariants['MuiList']
}

export const muiList: MuiList = {
  styleOverrides: {
    root: {
      padding: 0,
    },
  },
}
