import { UibCustomIcon } from '@/assets'
import { Link } from '@/generated/openapi'
import { LinkCategory } from '@/hooks'
import { uibRed50, uibRed600 } from '@/styles'
import { Box, Link as MuiLink, Typography, styled } from '@mui/material'

const StyledShortcutNavigation = styled(MuiLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
    '.linkIcon': {
      backgroundColor: 'transparent',
      svg: {
        path: {
          stroke: uibRed600,
        },
      },
    },
  },
}))

export const CategoryLinks = ({ categoryEntry }: { categoryEntry: LinkCategory }) => {
  return (
    <Box
      display={'flex'}
      alignItems={'start'}
      flexDirection={'column'}
      gap={'0.5rem'}
      marginTop={{ xs: '36px', md: '0px' }}
      gridRow={`span ${categoryEntry.links.length + 2}`}>
      <Typography variant='h3' height='36px'>
        {categoryEntry.categoryName}
      </Typography>
      {categoryEntry.links.map((link, idx) => (
        <ExternalLink key={idx} link={link} />
      ))}
    </Box>
  )
}

const ExternalLink = ({ link }: { link: Link }) => {
  return (
    <StyledShortcutNavigation href={link.url} sx={{ height: '36px' }}>
      <Box
        className='linkIcon'
        sx={{
          backgroundColor: uibRed50,
          borderRadius: '50px',
          height: '1.5rem',
          width: '1.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginRight: '12px',
        }}>
        <UibCustomIcon icon='arrow_right' colorOverride={uibRed600} sx={{ fontSize: '1rem' }} />
      </Box>
      <Typography variant='h4' whiteSpace={'nowrap'}>
        {link.title}
      </Typography>
    </StyledShortcutNavigation>
  )
}
