import { useEffect } from 'react'

import { notificationClient } from '@/api/client'
import { notificationDummies } from '@/dummyData/notificationDummies'
import { Notification } from '@/generated/openapi'
import { groupNotificationsByDate } from '@/utils/notificationUtils'
import { addPushMessageHandlerOnLoadWithCleanup } from '@/utils/serviceWorkerMessagingUtils/serviceWorkerMessaging'
import { QueryStatus, useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'

import useUserStorageState from './useUserStorageState'

export const QUERY_KEY_NOTIFICATIONS = 'notifications'

const USE_DUMMY_DATA = import.meta.env.VITE_USE_DUMMY_DATA
export type NotificationResponse = {
  status: QueryStatus
  refetch: () => void
  notificationsGroupedByDate: [string, Notification[]][]
  numberOfUnreadNotifications: number | null | undefined
}
const useDummyNotificationData: () => NotificationResponse = () => {
  return {
    status: 'success',
    refetch: () => {},
    notificationsGroupedByDate: groupNotificationsByDate(notificationDummies),
    numberOfUnreadNotifications: calculateNumberOfUnreadNotifications(notificationDummies),
  }
}

const calculateNumberOfUnreadNotifications = (notifications: Notification[]) =>
  notifications.reduce((p, n) => (n.unread ? p + 1 : p), 0)

export function useNotifications(setupMessageHandler?: boolean) {
  if (USE_DUMMY_DATA) {
    return useDummyNotificationData()
  }
  const [{ authenticated, user }] = useUserStorageState()
  const { status, isRefetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_NOTIFICATIONS],
    queryFn: async () => {
      const resp = await notificationClient.getUserNotifications()
      if (resp.status === 202) {
        throw new Error('Waiting for long running response to return')
      }
      return resp
    },
    enabled: false,
    retryDelay: 3000,
    retry: 3,
    refetchOnWindowFocus: 'always',
  })

  let notifications = data?.data.data || []

  useEffect(() => {
    if (authenticated && user !== null && !isRefetching) {
      refetch()
    }
  }, [user?.language, user?.is_impersonating, authenticated])

  // We might not need this due to the SW triggering a "focus" event which we've configured react-query to respond to by refetching notifications,
  //  but keep the code for robustness and future messaging needs
  useEffect(() => {
    if (!setupMessageHandler) return

    const notificationMessageHandler = (messageEvent: MessageEvent) => {
      const data = messageEvent.data
      const dataIsObject = typeof data === 'object' && !Array.isArray(data) && data !== null

      if (dataIsObject && data.messageType === 'NEW_NOTIFICATION_EVENT') {
        refetch()
      }
    }

    const cleanup = addPushMessageHandlerOnLoadWithCleanup(notificationMessageHandler)
    return cleanup
  }, [])

  return {
    refetch: refetch,
    status: isRefetching ? 'pending' : status,
    notificationsGroupedByDate: groupNotificationsByDate(notifications),
    numberOfUnreadNotifications: calculateNumberOfUnreadNotifications(notifications),
  }
}

export async function markNotificationAsRead(notificationId: string) {
  try {
    const result = await notificationClient.putNotificationMarkRead(notificationId)

    if (result.status == 200) {
      return 200
    }
    throw AxiosError
  } catch (error) {
    const extendedError = error as AxiosError
    return extendedError?.response?.status ?? 400
  }
}

export async function markNotificationAsUnread(notificationId: string) {
  try {
    const result = await notificationClient.putNotificationMarkUnread(notificationId)

    if (result.status == 200) {
      return 200
    }
    throw AxiosError
  } catch (error) {
    const extendedError = error as AxiosError
    return extendedError?.response?.status ?? 400
  }
}
