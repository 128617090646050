/*
  Color names are specified by the names used in Figma. 
  They are also prefaced with "uib" to ensure distinctivenes from MuI or other colors

  Avoid using colors directly if it can be helped. Rather, create variants in /styles where colours are interjected for easier managment.
  
  LIST OF DUPLICATE DECLARATIONS:
  - The calendar uses scss, where some of the colors had to be redeclared there 
*/
import { PaletteOptions } from '@mui/material'

export const uibRed900 = '#1D0606'
export const uibRed800 = '#300A09'
export const uibRed700 = '#761A19'
export const uibRed600 = '#AA1317'
export const uibRed500 = '#EC3D3C'
export const uibRed400 = '#FF7061'
export const uibRed300 = '#FF8C79'
export const uibRed200 = '#FFB1A3'
export const uibRed100 = '#FFDACC'
export const uibRed50 = '#FEF9F1'
export const uibFooterRed = '#621615'
export const uibLinkPlaceHolderDashRed = '#ba8a85'
export const uibLinkEditInUseSection = '#feeadf'
export const uibLinkEditDividerColor = '#ece4d9'

export const uibGreen900 = '#051B0F' // Duplicated in calendar scss
export const uibGreen800 = '#10301B'
export const uibGreen500 = '#28A465'
export const uibGreen400 = '#32CD8E'
export const uibGreen300 = '#8DF0B5'
export const uibGreen200 = '#B1F5CC'
export const uibGreen100 = '#D4FCE5'
export const uibGreen50 = '#E7FDF0' // Duplicated in calendar scss

export const uibBlue400 = '#65B4FF'

export const uibBeige = '#EAE2D5' // Duplicated in calendar scss
export const uibTextWhite = '#FEF9F1'
export const uibGrey = '#D9D9D9'

// Other colors
export const uibPeachyOrange = '#FAA978' // Used for calendar, duplicated there in scss file
export const uibLightBlue = '#9ACDFD' // Used for calendar, duplicated there in scss file
export const uibPink = '#FF80AD' // Used for calendar, duplicated there in scss file
export const uibLightYellow = '#FFF0A4' // Used for calendar, duplicated there in scss file
export const uibLavender = '#AAADFD' // Used for calendar, duplicated there in scss file

export const palette: PaletteOptions = {
  contrastThreshold: 4.5, //Wcag 2.1
  background: {
    default: uibRed800,
    paper: uibBeige,
  },
  text: {
    primary: uibRed900,
    secondary: uibTextWhite,
  },
  primary: {
    main: '#FFFFFF',
    // TODO consider changing to 400 - having uibRed50 makes part of many built in mui components "invisible"
    light: uibRed50,
    // light: uibRed400,
    dark: uibRed600,
  },
  secondary: {
    main: uibRed700,
    light: uibRed200,
    dark: uibRed900,
  },
  error: {
    main: uibRed600,
  },
}
