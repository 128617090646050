import { UibIllustration } from '@/assets'
import { useUibLanguage } from '@/language'
import { Button, Stack, SxProps, Theme, Typography, useTheme } from '@mui/material'

export const ComponentError = ({
  retry,
  errorMessage,
  stackSx,
  contrast,
}: {
  retry: () => void
  errorMessage: string
  stackSx?: SxProps<Theme>
  contrast?: boolean
}) => {
  const theme = useTheme()
  const { langDict } = useUibLanguage()
  return (
    <Stack alignItems={'center'} gap={'2rem'} sx={stackSx}>
      <UibIllustration
        width='158'
        height='158'
        illustration={contrast ? 'Illustration_error_contrast' : 'Illustration_error'}
      />
      <Typography color={theme.palette.primary.dark}>{errorMessage}</Typography>
      <Button variant='contained' onClick={retry}>
        {langDict('errorHandling.tryAgain')}
      </Button>
    </Stack>
  )
}
