import { impersonateClient } from '@/api/client'
import { AxiosError } from 'axios'

export const useImpersonate = () => {
  const initilizeImpersonation = async (username: string) => {
    try {
      const result = await impersonateClient.startImpersonate(username)
      if (result.status == 200) {
        return 200
      }
      throw AxiosError
    } catch (error) {
      const extendedError = error as AxiosError
      return extendedError?.response?.status ?? 400
    }
  }

  const endImpersonation = async () => {
    const result = await impersonateClient.stopImpersonate()
    return result
  }

  return {
    initilizeImpersonation,
    endImpersonation,
  }
}
