import { Breakpoints } from '@mui/material'
import { TypographyOptions } from '@mui/material/styles/createTypography'

export const getTypographyOverrides = (breakpoints: Breakpoints) => {
  const typographyOverrides: TypographyOptions = {
    h1: {
      fontSize: '48px',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '0.12px',
      [breakpoints.down('sm')]: {
        fontSize: '36px',
      },
    },
    h2: {
      fontSize: '36px',
      fontWeight: 700,
      lineHeight: 1.3,
      letterSpacing: '0.12px',
      [breakpoints.down('sm')]: {
        fontSize: '24px',
      },
    },
    h3: {
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '0.12px',
      [breakpoints.down('sm')]: {
        fontSize: '20px',
      },
    },
    h4: {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.12px',
      [breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    h4_bold: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: 1.5,
      letterSpacing: '0.12px',
      [breakpoints.down('sm')]: {
        fontSize: '18px',
      },
    },
    h5: {
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: '0.12px',
      [breakpoints.down('sm')]: {
        fontSize: '16px',
      },
    },
    // IMPORTANT NOTE: The empty objects need to be included for..
    //  .. general typography (font-family etc) to be applied
    h6: {},
    body1_bold: {
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: '24px',
      letterSpacing: '0.12px',
    },
    body1: {
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.12px',
    },
    body2: {},
    subtitle1: {},
    subtitle2: {
      fontSize: '13px',
      lineHeight: '13px',
      fontWeight: 400,
    },
    button: {},
    caption: {},
    overline: {},
  }
  return typographyOverrides
}
