import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

interface MuiListItemIcon {
  defaultProps?: ComponentsProps['MuiListItemIcon']
  styleOverrides?: ComponentsOverrides['MuiListItemIcon']
  variants?: ComponentsVariants['MuiListItemIcon']
}

export const muiListItemIcon: MuiListItemIcon = {
  styleOverrides: {
    root: {
      minWidth: '1rem',
      paddingRight: '1.25rem',
    },
  },
}
