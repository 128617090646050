import { Suspense, lazy } from 'react'

import { MaxWidthContentBox } from '@/modules/layout'
import { Paper, Typography } from '@mui/material'
import { Box } from '@mui/system'

import usePwaInstallationBanner from './usePwaInstallationBanner'
import { useTriggerPwaInstallation } from './useTriggerPwaInstallation'

const LazyManualInstallationBanner = lazy(() => import('./components/ManualPwaInstallationBanner'))
const LazyTriggeredInstallationBanner = lazy(() => import('./components/TriggeredPwaInstallationBanner'))

/** Developer flag which you can set to override which PWA banner is shown */
const OVERRIDE_SHOWN_BANNER = import.meta.env.VITE_PWA_BANNER_FORCE_VARIANT

/**
 * Shows a banner prompting the user to install the webpage as PWA
 * Users on different OS's get different banners, as support differs where
 * - iOS/MacOS cannot interact with the PWA outside browser settings
 * - Chromium on android/windows can trigger pwa installation with a button
 */
export const PwaInstallationBanner = () => {
  const triggerPwaInstallation = useTriggerPwaInstallation()
  const { bannerVariant, userDevice, dismissBanner } = usePwaInstallationBanner()

  // return (
  //   <Paper variant='white'>
  //     <MaxWidthContentBox sx={{ m: '0 auto', pt: '40px', pb: '80px', maxWidth: '90vw' }}>
  //       <Typography variant='h2'>Debug pwa banner</Typography>
  //       <Typography>{`User device: ${userDevice}`}</Typography>
  //       <Typography>{`Service-workers supported? ${'serviceWorker' in navigator}`}</Typography>
  //       <Typography>{`Rendering variant: ${bannerVariant}`}</Typography>
  //       <Typography>{`Supports triggered pwa installation: ${!!triggerPwaInstallation}`}</Typography>
  //     </MaxWidthContentBox>
  //   </Paper>
  // )

  if (bannerVariant === null) {
    return null
  }

  console.log('SHOWING BANNER ', OVERRIDE_SHOWN_BANNER, bannerVariant)
  // Developer utility
  if (OVERRIDE_SHOWN_BANNER) {
    return (
      <Suspense>
        <LazyManualInstallationBanner dismissBanner={dismissBanner} variant={OVERRIDE_SHOWN_BANNER} />
      </Suspense>
    )
  }

  // If triggering the pwa installation prompt is supported,
  //   show a banner with with "install" button and generic text
  if (triggerPwaInstallation) {
    return (
      <Suspense>
        <LazyTriggeredInstallationBanner dismissBanner={dismissBanner} {...triggerPwaInstallation} />
      </Suspense>
    )
  }

  // Otherwise show a banner with textual instructions
  // TODO if bannerVariant load banner, otherwise don't
  return (
    <Suspense>
      <LazyManualInstallationBanner dismissBanner={dismissBanner} variant={bannerVariant} />
    </Suspense>
  )
}
