import { CalendarEventDetails } from '@/generated/openapi'
import { DateTime } from 'luxon'

import { realisticCalendarScheduleData } from './realisticCalendarScheduleDummyData'

const scheduleData: CalendarEventDetails[] = [
  {
    title: 'Forelesning',
    parent_code: 'DATA160',
    start: '2023-11-14T13:15:00Z',
    end: '2023-11-14T15:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/243/301.04K1">Seminarrom D</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Parkveien 1,Bergen,Norway">Studentsenteret</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Faglærer</h3>\n            <ul>\n                                    <li>Richard Elling Moe</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      {
        name: 'Studentsenteret, Seminarrom D',
        url: 'http://rom.app.uib.no/romInfo/243/301.04K1',
      },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Seminargruppe 1',
    parent_code: 'DATA160',
    start: '2023-11-17T09:15:00Z',
    end: '2023-11-17T11:00:00Z',
    description:
      '<div>\n            <h4>Seminargruppe 1</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/213/406A01">Seminarrom 4A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Professor Keysers gate 1,Bergen,Norway">Ulrike Pihls hus</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      {
        name: 'Ulrike Pihls hus, Seminarrom 4A',
        url: 'http://rom.app.uib.no/romInfo/213/406A01',
      },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'DATA160',
    start: '2023-11-21T13:15:00Z',
    end: '2023-11-21T15:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/243/301.04K1">Seminarrom D</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Parkveien 1,Bergen,Norway">Studentsenteret</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Faglærer</h3>\n            <ul>\n                                    <li>Richard Elling Moe</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      {
        name: 'Studentsenteret, Seminarrom D',
        url: 'http://rom.app.uib.no/romInfo/243/301.04K1',
      },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Skriftleg eksamen',
    parent_code: 'DIGI111',
    start: '2023-11-14T15:15:00Z',
    end: '2023-11-14T17:15:00Z',
    description: '<div>\n                </div>',
    locations: [],
    event_type: 'tp.exam',
    source: 'tp',
    source_url: '',
  },
]

const offsetCalenderEventDate = (calendarEventDetail: CalendarEventDetails, dayOffset: number) => {
  const copiedEventData = { ...calendarEventDetail }
  copiedEventData.start = DateTime.fromISO(calendarEventDetail.start || '')
    .minus({ days: dayOffset })
    .toISO()
  copiedEventData.end = DateTime.fromISO(calendarEventDetail.end || '')
    .minus({ days: dayOffset })
    .toISO()
  return copiedEventData
}

const offsetCalenderEventRandomDateWithinMonth = (calendarEventDetail: CalendarEventDetails, dayOffset: number) => {
  const copiedEventData = { ...calendarEventDetail }
  const newHour = Math.floor(Math.random() * 14) + 6 // Random hours from 06.00 to 20.00
  const newMin = Math.floor(Math.random() * 4) * 15 // Random minutes of sequence 0, 15, 30, 45
  const newDurationMinutes = (Math.floor(Math.random() * 12) + 1) * 15 // Random event duration [0, 15, ..., 3h]
  const newDay = Math.floor(Math.random() * 28) + 1 // Random new day of the month

  const offset = (dateStr: string, duration = 0) =>
    DateTime.fromISO(dateStr)
      .minus({ days: dayOffset })
      .set({ hour: newHour, minute: newMin, day: newDay })
      .minus({ minutes: -duration })
      .toISO()

  copiedEventData.start = offset(calendarEventDetail.start || '')
  copiedEventData.end = offset(calendarEventDetail.end || '', newDurationMinutes)
  return copiedEventData
}

const shiftScheduleDataToNow = (data: CalendarEventDetails[], dataOrigin: DateTime) => {
  const dayDiff = Math.floor(dataOrigin.diffNow('days').days)
  const scheduleDummyDataShiftedToNow: CalendarEventDetails[] = []
  data.forEach((calendarEventDetail) => {
    scheduleDummyDataShiftedToNow.push(offsetCalenderEventDate(calendarEventDetail, dayDiff))
  })
  return scheduleDummyDataShiftedToNow
}

const shiftScheduleDataToRandomDateTimesThisMonth = (data: CalendarEventDetails[]) => {
  const now = DateTime.now()
  const startOfMonthInMS = now.startOf('month').startOf('week').toMillis()
  const endOfMonthInMs = now.endOf('month').endOf('week').toMillis()

  let randomlyShiftedData = data.map((calendarEvent) => {
    const eventStartInMs = startOfMonthInMS + Math.random() * (endOfMonthInMs - startOfMonthInMS)
    let shiftedStartDT = DateTime.fromMillis(eventStartInMs)

    // Let events start in every 5th minute
    shiftedStartDT = shiftedStartDT.set({
      minute: Math.floor(shiftedStartDT.minute / 5) * 5,
      second: 0,
    })

    // Event ends up to 5 hours after start (in 5 minute intervals)
    const shiftedEndDT = shiftedStartDT.plus({
      minutes: Math.floor(Math.random() * 60 + 1) * 5,
    })

    return Object.assign({}, calendarEvent, {
      start: shiftedStartDT.toISO(),
      end: shiftedEndDT.toISO(),
    })
  })

  return randomlyShiftedData as CalendarEventDetails[]
}

export const getScheduleDummyData = (daysToShowInSchedule = 7) => {
  // Dummy data is based on data from 2023.11.14
  const dummyDataOrigin = DateTime.fromISO('2023-11-14')
  const scheduleDummyDataShiftedToNow = shiftScheduleDataToNow(scheduleData, dummyDataOrigin)

  if (daysToShowInSchedule <= 7) {
    return scheduleDummyDataShiftedToNow
  }

  // If we want to show a month of data, create more data from the calenderEventDetails by duplication
  const dummyDataSize = scheduleData.length
  const dayDiff = Math.floor(dummyDataOrigin.diffNow('days').days)
  for (let i = 0; i < 50; i++) {
    const calendarEventDetail = scheduleData[i % dummyDataSize]
    scheduleDummyDataShiftedToNow.push(offsetCalenderEventRandomDateWithinMonth(calendarEventDetail, dayDiff))
  }

  return scheduleDummyDataShiftedToNow
}

/** Gets realistic schedule dummy data, where every datapoint starts from today */
export const getRealisticScheduleDummyData = () => {
  const dummyDataOrigin = DateTime.fromISO('2024-04-06')
  return shiftScheduleDataToNow(realisticCalendarScheduleData, dummyDataOrigin)
}

/** Gets realistic schedule dummy data, but every datapoint gets a random start and end time within the current month */
export const getRealisticScheduleDummiesWithRandomTimeThisMonth = () => {
  return shiftScheduleDataToRandomDateTimesThisMonth(realisticCalendarScheduleData)
}
