import { CalendarEventDetails } from '@/generated/openapi'
import { TREET_EVENT_TYPES } from '@/pages/CalendarPage/CalendarComponents/CalendarEvent/CalendarEvent'

let realisticCalendarScheduleData: CalendarEventDetails[] = [
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-06T12:15:00Z',
    end: '2024-04-06T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Oppgavegjennomgang',
    parent_code: 'INF102',
    start: '2024-04-07T12:15:00Z',
    end: '2024-04-07T14:00:00Z',
    description:
      '<div>\n            <h4>Oppgavegjennomgang</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/351/209M3">Stort auditorium</a>, <a href="https://www.google.com/maps/place/Thorm%C3%B8hlens+Gate+55,+5008+Bergen,+Norge/@60.3813246,5.3310013,18z/data=!3m1!4b1!4m5!3m4!1s0x463cf9533f58e79b:0xc30bc2fea664b49f!8m2!3d60.381467!4d5.3315391?hl=no">H\u00f8yteknologisenteret</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'H\u00f8yteknologisenteret, Stort auditorium', url: 'http://rom.app.uib.no/romInfo/351/209M3' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-08T08:15:00Z',
    end: '2024-04-08T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-09T12:15:00Z',
    end: '2024-04-09T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Oppgavegjennomgang',
    parent_code: 'INF102',
    start: '2024-04-10T12:15:00Z',
    end: '2024-04-10T14:00:00Z',
    description:
      '<div>\n            <h4>Oppgavegjennomgang</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/351/209M3">Stort auditorium</a>, <a href="https://www.google.com/maps/place/Thorm%C3%B8hlens+Gate+55,+5008+Bergen,+Norge/@60.3813246,5.3310013,18z/data=!3m1!4b1!4m5!3m4!1s0x463cf9533f58e79b:0xc30bc2fea664b49f!8m2!3d60.381467!4d5.3315391?hl=no">H\u00f8yteknologisenteret</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'H\u00f8yteknologisenteret, Stort auditorium', url: 'http://rom.app.uib.no/romInfo/351/209M3' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-11T08:15:00Z',
    end: '2024-04-11T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-12T12:15:00Z',
    end: '2024-04-12T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Oppgavegjennomgang',
    parent_code: 'INF102',
    start: '2024-04-13T12:15:00Z',
    end: '2024-04-13T14:00:00Z',
    description:
      '<div>\n            <h4>Oppgavegjennomgang</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/351/209M3">Stort auditorium</a>, <a href="https://www.google.com/maps/place/Thorm%C3%B8hlens+Gate+55,+5008+Bergen,+Norge/@60.3813246,5.3310013,18z/data=!3m1!4b1!4m5!3m4!1s0x463cf9533f58e79b:0xc30bc2fea664b49f!8m2!3d60.381467!4d5.3315391?hl=no">H\u00f8yteknologisenteret</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'H\u00f8yteknologisenteret, Stort auditorium', url: 'http://rom.app.uib.no/romInfo/351/209M3' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-14T08:15:00Z',
    end: '2024-04-14T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-15T12:15:00Z',
    end: '2024-04-15T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Oppgavegjennomgang',
    parent_code: 'INF102',
    start: '2024-04-16T12:15:00Z',
    end: '2024-04-16T14:00:00Z',
    description:
      '<div>\n            <h4>Oppgavegjennomgang</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/351/209M3">Stort auditorium</a>, <a href="https://www.google.com/maps/place/Thorm%C3%B8hlens+Gate+55,+5008+Bergen,+Norge/@60.3813246,5.3310013,18z/data=!3m1!4b1!4m5!3m4!1s0x463cf9533f58e79b:0xc30bc2fea664b49f!8m2!3d60.381467!4d5.3315391?hl=no">H\u00f8yteknologisenteret</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'H\u00f8yteknologisenteret, Stort auditorium', url: 'http://rom.app.uib.no/romInfo/351/209M3' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-17T08:15:00Z',
    end: '2024-04-17T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF102',
    start: '2024-04-18T13:15:00Z',
    end: '2024-04-18T15:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Martin Vatshelle</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-19T12:15:00Z',
    end: '2024-04-19T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-20T12:15:00Z',
    end: '2024-04-20T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-21T12:15:00Z',
    end: '2024-04-21T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-22T12:15:00Z',
    end: '2024-04-22T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-23T12:15:00Z',
    end: '2024-04-23T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-24T12:15:00Z',
    end: '2024-04-24T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-25T12:15:00Z',
    end: '2024-04-25T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'INF113',
    start: '2024-04-26T12:15:00Z',
    end: '2024-04-26T14:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Crystal Chang Din</li>\n                                    <li>David Grellscheid</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-04-27T08:15:00Z',
    end: '2024-04-27T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-04-28T08:15:00Z',
    end: '2024-04-28T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-04-29T08:15:00Z',
    end: '2024-04-29T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-04-30T08:15:00Z',
    end: '2024-04-30T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-05-01T08:15:00Z',
    end: '2024-05-01T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-05-02T08:15:00Z',
    end: '2024-05-02T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-05-03T08:15:00Z',
    end: '2024-05-03T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-05-04T08:15:00Z',
    end: '2024-05-04T10:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesninger',
    parent_code: 'INF122',
    start: '2024-05-05T09:15:00Z',
    end: '2024-05-05T11:00:00Z',
    description:
      '<div>\n            <h4>Forelesninger</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>H\u00e5kon Robbestad Gylterud</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [{ name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' }],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-06T06:15:00Z',
    end: '2024-05-06T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-07T06:15:00Z',
    end: '2024-05-07T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'MAT101 Seminar',
    parent_code: 'MAT101',
    start: '2024-05-08T10:15:00Z',
    end: '2024-05-08T12:00:00Z',
    description:
      '<div>\n            <h4>MAT101 Seminar</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-112">Auditorium B</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'All\u00e9gaten 66, Auditorium B', url: 'http://rom.app.uib.no/romInfo/315/A-112' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-09T06:15:00Z',
    end: '2024-05-09T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-10T06:15:00Z',
    end: '2024-05-10T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'MAT101 Seminar',
    parent_code: 'MAT101',
    start: '2024-05-11T10:15:00Z',
    end: '2024-05-11T12:00:00Z',
    description:
      '<div>\n            <h4>MAT101 Seminar</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-112">Auditorium B</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'All\u00e9gaten 66, Auditorium B', url: 'http://rom.app.uib.no/romInfo/315/A-112' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-12T06:15:00Z',
    end: '2024-05-12T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-13T06:15:00Z',
    end: '2024-05-13T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'MAT101 Seminar',
    parent_code: 'MAT101',
    start: '2024-05-14T10:15:00Z',
    end: '2024-05-14T12:00:00Z',
    description:
      '<div>\n            <h4>MAT101 Seminar</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-112">Auditorium B</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'All\u00e9gaten 66, Auditorium B', url: 'http://rom.app.uib.no/romInfo/315/A-112' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-15T06:15:00Z',
    end: '2024-05-15T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Forelesning',
    parent_code: 'MAT101',
    start: '2024-05-16T06:15:00Z',
    end: '2024-05-16T08:00:00Z',
    description:
      '<div>\n            <h4>Forelesning</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/308/UC7B">Auditorium 1</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 41,Bergen,Norway">Realfagbygget</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n                    <div>\n            <h3>Fagl\u00e6rer</h3>\n            <ul>\n                                    <li>Antonella Zanna Munthe-Kaas</li>\n                            </ul>\n        </div>\n    </div>',
    locations: [
      { name: 'Realfagbygget, Auditorium 1', url: 'http://rom.app.uib.no/romInfo/308/UC7B' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'MAT101 Seminar',
    parent_code: 'MAT101',
    start: '2024-05-17T10:15:00Z',
    end: '2024-05-17T12:00:00Z',
    description:
      '<div>\n            <h4>MAT101 Seminar</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-112">Auditorium B</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                                    <li><a href="http://rom.app.uib.no/romInfo/315/A-114">Auditorium A</a>, <a href="http://maps.google.com/maps?f=q&amp;hl=no&amp;q=Allegaten 66,Bergen,Norway">All\u00e9gaten 66</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'All\u00e9gaten 66, Auditorium B', url: 'http://rom.app.uib.no/romInfo/315/A-112' },
      { name: 'All\u00e9gaten 66, Auditorium A', url: 'http://rom.app.uib.no/romInfo/315/A-114' },
    ],
    event_type: 'tp.teaching',
    source: 'tp',
    source_url: '',
  },
  {
    title: '',
    parent_code: 'INF113',
    start: '2024-05-18',
    end: '2024-05-18',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42392/assignments/76949',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'INF102',
    start: '2024-05-19',
    end: '2024-05-19',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42523/assignments/79898',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'INF102',
    start: '2024-05-20',
    end: '2024-05-20',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42523/assignments/80234',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'INF102',
    start: '2024-05-21',
    end: '2024-05-21',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42523/assignments/80918',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'MAT101',
    start: '2024-05-22T06:45:00Z',
    end: '2024-05-22T06:45:00Z',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42526/assignments/78756',
  },
  {
    title: '',
    parent_code: 'MAT101',
    start: '2024-05-23',
    end: '2024-05-23',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42526/assignments/80114',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'MAT101',
    start: '2024-05-24',
    end: '2024-05-24',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42526/assignments/80115',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'MAT101',
    start: '2024-05-25T06:45:00Z',
    end: '2024-05-25T06:45:00Z',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42526/assignments/80357',
  },
  {
    title: '',
    parent_code: 'INF122',
    start: '2024-05-26',
    end: '2024-05-26',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42388/assignments/76952',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'INF122',
    start: '2024-05-27',
    end: '2024-05-27',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42388/assignments/76961',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'INF122',
    start: '2024-05-28',
    end: '2024-05-28',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42388/assignments/76962',
    all_day: true,
  },
  {
    title: '',
    parent_code: 'INF122',
    start: '2024-05-29',
    end: '2024-05-29',
    description: null,
    locations: [],
    event_type: 'canvas.assignment',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42388/assignments/76963',
    all_day: true,
  },
  {
    title: 'Skriftleg eksamen',
    parent_code: 'INF112',
    start: '2024-05-30T13:00:00Z',
    end: '2024-05-30T16:00:00Z',
    description:
      '<div>\n            <h4>Skriftlig skoleeksamen (INSPERA)</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="https://link.mazemap.com/l15lbVx8">SOL 2. etg.</a>, <a href="https://link.mazemap.com/53M48npS">Solheimsgt. 18 (Administrasjonsbygget)</a></li>\n                                    <li><a href="http://org.uib.no/sa/tp/Solheimsgaten_18-Exam-GoogleMaps.png">Eksamenslokale 3. etg.</a>, <a href="https://link.mazemap.com/53M48npS">Solheimsgt. 18 (Administrasjonsbygget)</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'Solheimsgt. 18 (Administrasjonsbygget), SOL 2. etg.', url: 'https://link.mazemap.com/l15lbVx8' },
      {
        name: 'Solheimsgt. 18 (Administrasjonsbygget), Eksamenslokale 3. etg.',
        url: 'http://org.uib.no/sa/tp/Solheimsgaten_18-Exam-GoogleMaps.png',
      },
    ],
    event_type: 'tp.exam',
    source: 'tp',
    source_url: '',
  },
  {
    title: 'Skriftleg eksamen',
    parent_code: 'INF142',
    start: '2024-05-31T13:00:00Z',
    end: '2024-05-31T16:00:00Z',
    description:
      '<div>\n            <h4>Skriftlig skoleeksamen (INSPERA)</h4>\n                <div>\n            <h3>Stad</h3>\n            <ul>\n                                    <li><a href="">DAM 1. etg.</a>, <a href="https://link.mazemap.com/f7zZ6vjy">Damsg\u00e5rdsveien 125</a></li>\n                                    <li><a href="https://www.google.no/maps/place/Damsg%C3%A5rdsveien+125,+5160+Laksev%C3%A5g/@60.3835724,5.3106136,17.46z/data=!4m5!3m4!1s0x463cfc00b331bad1:0x58c2550e45501760!8m2!3d60.3843441!4d5.3122713?hl=no">DAM 2. etg.</a>, <a href="https://link.mazemap.com/f7zZ6vjy">Damsg\u00e5rdsveien 125</a></li>\n                            </ul>\n        </div>\n            </div>',
    locations: [
      { name: 'Damsg\u00e5rdsveien 125, DAM 1. etg.', url: '' },
      {
        name: 'Damsg\u00e5rdsveien 125, DAM 2. etg.',
        url: 'https://www.google.no/maps/place/Damsg%C3%A5rdsveien+125,+5160+Laksev%C3%A5g/@60.3835724,5.3106136,17.46z/data=!4m5!3m4!1s0x463cfc00b331bad1:0x58c2550e45501760!8m2!3d60.3843441!4d5.3122713?hl=no',
      },
    ],
    event_type: 'tp.exam',
    source: 'tp',
    source_url: '',
  },
  {
    title: '[TYS635] \u00d8vingsgruppe',
    parent_code: 'TYS635',
    start: '2024-06-01T13:00:00Z',
    end: '2024-06-01T14:30:00Z',
    description: null,
    locations: [{ name: 'Zoom Online Meeting' }],
    event_type: 'canvas.event',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42353/calendar_events/1716321',
  },
  {
    title: '[TYS635] \u00d8vingsgruppe',
    parent_code: 'TYS635',
    start: '2024-06-02T16:30:00Z',
    end: '2024-06-02T18:00:00Z',
    description: null,
    locations: [],
    event_type: 'canvas.event',
    source: 'canvas',
    source_url: 'https://canvas.test.uib.no/courses/42353/calendar_events/1724658',
  },
]

const updateOldEventTypeToNew = (event_type: string): TREET_EVENT_TYPES => {
  switch (event_type) {
    case 'tp.teaching':
    case 'teaching':
    case 'canvas.event':
    case 'event':
      return 'event'
    case 'tp.exam':
    case 'exam':
      return 'exam'
    case 'canvas.assignment':
    case 'assignment':
      return 'assignment'
    default:
      return 'resource'
  }
}

// This schedule data is a bit outdated, having old event types so we transform it to the new format
realisticCalendarScheduleData = realisticCalendarScheduleData.map((event) =>
  Object.assign({}, event, {
    event_type: updateOldEventTypeToNew(event.event_type || ''),
    source_event_type: event.event_type,
  })
)

export { realisticCalendarScheduleData }
