import { CSSObject } from '@mui/material'

import { uibRed200, uibRed600 } from './palette'

export const defaultFocusStyleLight: CSSObject = {
  content: '""',
  position: 'absolute',
  borderRadius: '4px',
  top: '-5px',
  left: '-5px',
  right: '-5px',
  bottom: '-5px',
  border: '2px solid',
  borderColor: uibRed200,
}

export const defaultFocusStyleDark: CSSObject = {
  content: '""',
  position: 'absolute',
  borderRadius: '4px',
  top: '-5px',
  left: '-5px',
  right: '-5px',
  bottom: '-5px',
  border: '2px solid',
  borderColor: uibRed600,
}
