import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { uibRed50 } from '../palette'

interface MuiMenuItem {
  defaultProps?: ComponentsProps['MuiMenuItem']
  styleOverrides?: ComponentsOverrides['MuiMenuItem']
  variants?: ComponentsVariants['MuiMenuItem']
}

export const muiMenuItem: MuiMenuItem = {
  styleOverrides: {
    root: {
      backgroundColor: 'white',
      '&.Mui-selected': {
        backgroundColor: uibRed50,
      },
    },
  },
}
