import { useEffect, useState } from 'react'

import { pushClient } from '@/api/client'
import {
  getPushManager,
  getPushNotificationSupport,
  getSubscriptionAsAPIObject,
  requestNotificationPermissionAndSubscription,
} from '@/utils/pushSubscriptionUtils/pushUtils'
import { useQuery } from '@tanstack/react-query'

import useUserStorageState from './useUserStorageState'

/**
 * Attempts subscribing to PushNotifications
 * - Does nothing if you're already subscribed
 */
export const subscribeToPushNotifications = async () => {
  const { subscription, error, permissionState } = await requestNotificationPermissionAndSubscription()

  if (subscription === undefined && permissionState === 'denied') {
    // We interpret users declining a permission to not be an error
    return { permissionState, status: 'success' }
  }

  if (error !== undefined) {
    console.error('Attempted to subscribe to push notifications failed with error')
    return { status: 'error', permissionState }
  }

  if (subscription === undefined) {
    // Should never happen unless we introduce a bug, this is here for typesafety
    console.error("subscription is for some reason null, but we didn't get an error from PushManager")
    return { status: 'error', permissionState }
  }

  const apiSubscriptionObject = getSubscriptionAsAPIObject(subscription)
  if (apiSubscriptionObject === null) {
    console.error(
      'Attempted subscribing to push notifications with invalid subscription - this is likely a developer error',
      subscription.toJSON()
    )
    subscription.unsubscribe()
    return { status: 'error', permissionState }
  }

  // There were no issues with subscription, attempt registering it with the server
  let resp
  try {
    resp = await pushClient.apiV1SelfPushSubscribePost(apiSubscriptionObject)
  } catch (error) {
    // Remove the subscription if we fail subscribing to server
    console.debug('Failed subscribing subscription, so we unregister it: ', subscription)
    subscription.unsubscribe()
  }

  return {
    status: resp?.status,
    permissionState,
  }
}

type UnsubscribeStatus = 'UNAVAILABLE' | 'SUCCESS' | 'ERROR'

export const unsubscribeFromPushNotifications: () => Promise<UnsubscribeStatus> = async () => {
  const pushManager = await getPushManager()

  if (pushManager === undefined || pushManager === null) {
    console.debug(
      'Cannot unsubscribe as push manager currently is unavailable - likely due to lack of browser support or due to service worker being unavaible'
    )
    return 'UNAVAILABLE'
  }
  const subscription = await pushManager.getSubscription()
  if (subscription === null) {
    // No subscription means we do not have to unsubscribe, so we're done
    console.debug(
      'Cannot unsubscribe as there is no current subscription - this may be due to calling unsubscribe() twice?'
    )
    return 'SUCCESS'
  }

  const apiSubscriptionObject = getSubscriptionAsAPIObject(subscription)
  if (apiSubscriptionObject === null) {
    console.warn(
      'Attempted unsubscribing to push notifications with invalid subscription - this is likely a developer error',
      subscription.toJSON()
    )
    // If subscription is invalid then we don't worry about it
    // We just try to unsubscribe it, ignoring the backend and other errors since it cannot be made into JSON
    try {
      subscription.unsubscribe().catch(() => {})
      return 'SUCCESS'
    } catch (err) {
      console.error('Failed unsubscribing the client: ', err)
      return 'ERROR'
    }
  }

  const unsubscribeWasSuccess = await subscription.unsubscribe()
  if (!unsubscribeWasSuccess) {
    // Unsubscribe failing here means it was already unsubscribed - which can happen due to threading,
    return 'SUCCESS' //.. we treat it as succeess since we acheived our objective
  }
  try {
    // We've already unsubscribed locally, but not to the server yet
    const resp = await pushClient.apiV1SelfPushUnsubscribePost(apiSubscriptionObject)
    if (resp.status === 200) {
      return 'SUCCESS'
    } else {
      console.debug('Failed unsubscring backend which gave response', resp)
      return 'SUCCESS' // Since the client already has unsubscribed and will decline messages..
    }
  } catch (err) {
    console.debug('Unexpected backend error unsubscribing from PushNotifications', err, subscription.toJSON())
    return 'SUCCESS' // .. we consider it OK even if backend doesn't unsubscribe us,
  }
}

export const QUERY_KEY_PUSH_SUBSCRIPTION = 'QUERY_KEY_PUSH_SUBSCRIPTION'

export function usePushSubscription() {
  const [{ authenticated, user }] = useUserStorageState()

  const { isRefetching, refetch, data } = useQuery({
    queryKey: [QUERY_KEY_PUSH_SUBSCRIPTION],
    queryFn: async () => {
      if (user === null || user.is_push_enabled === false) {
        return {
          pushNotificationSupport: 'NOT_SUPPORTED',
        }
      }

      const pushNotificationSupport = getPushNotificationSupport()

      if (pushNotificationSupport !== 'SUPPORTED') {
        return {
          pushNotificationSupport,
        }
      }

      // Push notifications are supported, fetch subscription and permissions
      const reg = await navigator.serviceWorker.ready
      const subscription = await reg.pushManager.getSubscription()
      const permissionState = Notification.permission

      return {
        isSubscribed: subscription !== null && subscription !== undefined,
        permission: permissionState,
        pushNotificationSupport,
      }
    },
    enabled: false,
    retryDelay: 0,
    retry: 0,
  })

  useEffect(() => {
    if (authenticated !== null) {
      refetch()
    }
  }, [user?.is_impersonating, authenticated, user?.is_push_enabled])

  return {
    pushNotificationSupport: data?.pushNotificationSupport,
    isLoading: isRefetching || authenticated === null,
    permission: data?.permission,
    isSubscribed: data?.isSubscribed || false,
    updateUsePushSubscription: refetch,
  }
}
