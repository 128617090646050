import { useCallback, useEffect, useMemo } from 'react'

import { generateRandomEventsWithinCurrentMonth } from '@/dummyData/randomScheduleDummies'
import { getScheduleDummyData } from '@/dummyData/scheduleDummies'
import { CalendarEventDetails } from '@/generated/openapi'
import { useCalendarScheduleFilter } from '@/hooks/useCalendarFilter'
import { CalendarDataStatus } from '@/pages/CalendarPage/CalendarComponents/CalendarDataStatus'
import {
  EnrichedCalendarEvent,
  ReactBigCalendarDateRange,
  mapScheduleEventsToCalendarData,
} from '@/pages/CalendarPage/CalendarUtils'
import { processEventByDate } from '@/utils/scheduleUtils'
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { Views } from 'react-big-calendar'

import { scheduleClient } from '../client'
import { useExams } from './useExams'
import useUserStorageState from './useUserStorageState'

export const QUERY_KEY_SCHEDULE = 'schedule'
export const QUERY_KEY_CALENDAR_SCHEDULE = 'calendar_schedule'

const USE_DUMMY_DATA = import.meta.env.VITE_USE_DUMMY_DATA
const TEN_MIN_IN_MS = 1000 * 60 * 10

const useDummyScheduleData = (daysToShowInSchedule = 7) => {
  const scheduleDummyData = getScheduleDummyData(daysToShowInSchedule)
  const processedDummyData = processEventByDate(daysToShowInSchedule, scheduleDummyData)
  return {
    status: 'success',
    refetch: () => {},
    scheduleEvents: scheduleDummyData,
    processedEvents: processedDummyData,
    customErrorCode: undefined,
  }
}

/** Gets schedule data from today and {days} in the future */
export function useScheduleData(days: number) {
  if (USE_DUMMY_DATA) {
    return useDummyScheduleData(days)
  }

  const [{ authenticated, user }] = useUserStorageState()

  const response = useQuery({
    queryKey: [QUERY_KEY_SCHEDULE],
    queryFn: () => scheduleClient.getUserEvents(),
    enabled: authenticated || false,
  })

  const data = response.data?.data.data

  //Custom error codes are not specified in OpenApi, but defines a bit what kind of error we are encountering
  const customError = response.error as any
  const errorCode = customError?.response?.data?.error?.code as string | undefined
  const processedEvents = processEventByDate(days, data)

  useEffect(() => {
    response.refetch()
  }, [user?.is_impersonating, user?.language])

  return {
    ...response,
    customErrorCode: errorCode,
    scheduleEvents: data,
    processedEvents: processedEvents,
  }
}

/**
 * If some range is within a calendar month, returns the calendar month
 * - otherwise returns the range (which can be across 2 months)
 */
const getSurroundingCalendarMonthOrRange = (rangeStart: DateTime, rangeEnd: DateTime) => {
  // A month can show a bit of the week before and after the month,
  //  .. so we can usually fetch day-view and agenda-view from the data of a month
  const startOfMonth = rangeStart.startOf('month').startOf('week')
  const endOfMonth = rangeStart.endOf('month').endOf('week')

  // Note that there are edge cases where you can fall into 2 data-buckets,
  // -> Check that start & end of month encompasses the range
  const startOfMonthIsoDate = startOfMonth.toISODate() || ''
  const endOfMonthIsoDate = endOfMonth.toISODate() || ''
  const isWithinBucket = (isoDateStr: string) => startOfMonthIsoDate <= isoDateStr && isoDateStr <= endOfMonthIsoDate
  if (isWithinBucket(rangeStart.toISODate() || '') && isWithinBucket(rangeEnd.toISODate() || '')) {
    return {
      start: startOfMonth,
      end: endOfMonth,
    }
  }

  // If it doesn't we just create a new bucket for the problematic week,
  // .. which is a lot easier than merging cache entries for 2 months
  return {
    start: rangeStart.startOf('day'),
    end: rangeEnd.endOf('day'),
  }
}

/**
 * Calculates which data bucket for calendar data to use when fetching data from backend
 */
export const calculateDatabucketFromVisibleRange = (range: ReactBigCalendarDateRange) => {
  // For week views we use the surrounding month
  if (Array.isArray(range)) {
    const [_rangeStart, ...rest] = range
    const rangeStart = DateTime.fromJSDate(_rangeStart)
    const rangeEnd = DateTime.fromJSDate(rest.pop() || _rangeStart)
    return getSurroundingCalendarMonthOrRange(rangeStart, rangeEnd)
  }

  // Agenda or month view have date range as an object
  const from = DateTime.fromJSDate(range.start)
  const to = DateTime.fromJSDate(range.end)
  const view = from.startOf('day').equals(from) && to.endOf('day').equals(to) ? Views.MONTH : Views.AGENDA

  // For months we use data for the entire visible range,
  //  .. which is already from start of month to end of month
  if (view === Views.MONTH) {
    return {
      start: from,
      end: to,
    }
  }

  // For agenda view we also use the entire range,
  //  but we check if it is within a month we've already fetched
  // This is necessary if weeks don't start on monday,
  //  as that would make it possible for the week to be part of 2 calendar months
  return getSurroundingCalendarMonthOrRange(from, to)
}

const filterCalendarDataByDateRange = (data: EnrichedCalendarEvent[], range: ReactBigCalendarDateRange) => {
  let rangeStart: DateTime, rangeEnd: DateTime

  // Get the start/end of range (which has varying format depending on view)
  if (Array.isArray(range)) {
    let [first, ...rest] = range
    rangeStart = DateTime.fromJSDate(first)
    rangeEnd = DateTime.fromJSDate(rest.pop() || first)
  } else {
    rangeStart = DateTime.fromJSDate(range.start)
    rangeEnd = DateTime.fromJSDate(range.end)
  }

  // Create helper for checking if event is within range then filter
  const startIsoStr = rangeStart.toISODate() as string
  const endIsoStr = rangeEnd.toISODate() as string
  const isWithinRange = (dateIsoString: string) => startIsoStr <= dateIsoString && dateIsoString <= endIsoStr
  return data.filter((event) => {
    if (event.start === undefined || event.end === undefined) {
      // The line below is for safety - treet backend should always set start/end
      // .. if either is missing, which they often do from the exam system.
      console.error('Hiding event due to missing start or end', event)
      return false
    }
    const eventStartIsoDate = DateTime.fromJSDate(event.start).toISODate() as string
    const eventEndIsoDate = DateTime.fromJSDate(event.end).toISODate() as string

    return isWithinRange(eventStartIsoDate) || isWithinRange(eventEndIsoDate)
  })
}

export const fetchCalendarEvents = async (start: string, end: string) => {
  if (USE_DUMMY_DATA) {
    return generateRandomEventsWithinCurrentMonth(60)
    // return getRealisticScheduleDummiesWithRandomTimeThisMonth()
  }
  let res = await scheduleClient.getUserEventsPerPeriod(start, end)
  return res.data.data
}

/**
 * Fetches calendar schedule data for some timeperiod
 *  - INCLUDING -  exam data for the calendar
 */
export function useCalendarScheduleApi(range: ReactBigCalendarDateRange) {
  const { start, end } = useMemo(() => {
    let { start, end } = calculateDatabucketFromVisibleRange(range)
    return {
      start: start.toISODate({ format: 'basic' }) || '',
      end: end.toISODate({ format: 'basic' }) || '',
    }
  }, [range])

  const [{ authenticated, user }] = useUserStorageState()

  const {
    data: scheduleData,
    error: scheduleError,
    refetch: scheduleRefetch,
    status: scheduleStatus,
  } = useQuery({
    queryKey: [QUERY_KEY_CALENDAR_SCHEDULE, { start, end }],
    queryFn: () => fetchCalendarEvents(start, end),
    enabled: authenticated || USE_DUMMY_DATA === true || false,
    staleTime: TEN_MIN_IN_MS,
    select: mapScheduleEventsToCalendarData,
    placeholderData: keepPreviousData,
  })

  // Custom error codes are not specified in OpenApi, but defines a bit what kind of error we are encountering
  const customError = scheduleError as any
  const errorCode = customError?.response?.data?.error?.code as string | undefined
  if (customError || errorCode) {
    console.warn('[useScheduleData] Error fetching calendar schedule data: ', errorCode, customError)
  }

  // Also fetch exams for calendar
  const { examsForCalendar, refetch: examRefetch, status: examStatus } = useExams()

  // Get composite data, state, and refetch handlers
  const scheduleWithExams = useMemo(() => {
    if (scheduleData === undefined || examsForCalendar === undefined) {
      return undefined
    }
    return scheduleData.concat(examsForCalendar)
  }, [scheduleData, examsForCalendar])
  const compositeStatus = useMemo(() => {
    if (examStatus === 'error' || scheduleStatus === 'error') return 'error'
    if (examStatus === 'pending' || scheduleStatus === 'pending') return 'pending'
    return 'success'
  }, [examStatus, scheduleStatus])
  const compositeRefetch = useCallback(
    () => Promise.all([scheduleRefetch(), examRefetch()]),
    [scheduleRefetch, examRefetch]
  )

  // Filter the data
  const { filterCalendarEvent, ...filterFunctions } = useCalendarScheduleFilter(scheduleWithExams)
  let { filteredData, ...dataAndFilterState } = useMemo(() => {
    let dataInRange = scheduleWithExams !== undefined ? filterCalendarDataByDateRange(scheduleWithExams, range) : []
    let filteredData = dataInRange.filter(filterCalendarEvent)

    let dataStatus: CalendarDataStatus
    if (compositeStatus !== 'success') {
      dataStatus = 'loading'
    } else if (!dataInRange.length) {
      dataStatus = 'no-data'
    } else if (!filteredData.length) {
      dataStatus = 'no-unfiltered-data'
    } else {
      dataStatus = 'ready'
    }

    return {
      filteredData,
      originalDataLength: dataInRange.length,
      filteredDataLength: filteredData.length,
      dataStatus: dataStatus,
    }
  }, [scheduleWithExams, range, filterCalendarEvent])

  // Handle user changes
  useEffect(() => {
    scheduleRefetch()
    // examRefetch does not need to be called,
    //   as it is done inside its own hook when user changes
  }, [user?.is_impersonating, user?.language])

  return {
    customErrorCode: errorCode,
    scheduleError,
    status: compositeStatus,
    refetch: compositeRefetch,

    calendarEvents: filteredData,
    ...dataAndFilterState,
    ...filterFunctions,
  }
}
