import { Breakpoints, ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import {
  uibBeige,
  uibGreen50,
  uibGreen100,
  uibRed50,
  uibRed100,
  uibRed200,
  uibRed300,
  uibRed700,
  uibRed800,
} from '../palette'

interface MuiPaper {
  defaultProps?: ComponentsProps['MuiPaper']
  styleOverrides?: ComponentsOverrides['MuiPaper']
  variants?: ComponentsVariants['MuiPaper']
}

export const getMuiPaper = (breakpoints: Breakpoints) => {
  const muiPaper: MuiPaper = {
    styleOverrides: {
      root: {
        borderRadius: '0px',
      },
    },
    variants: [
      {
        props: { variant: 'salmon' },
        style: { backgroundColor: uibRed100 },
      },
      {
        props: { variant: 'compactedSchedule' },
        style: {
          backgroundColor: uibRed100,
          padding: '4rem',
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          [breakpoints.down('md')]: {
            padding: '1.5rem',
          },
        },
      },
      {
        props: { variant: 'softRedPaper' },
        style: { backgroundColor: uibRed50 },
      },
      {
        props: { variant: 'borderedRedBox' },
        style: {
          backgroundColor: 'white',
          border: '2px solid',
          borderColor: uibRed300,
        },
      },
      {
        props: { variant: 'modalPaper' },
        style: {
          padding: '2rem',
          paddingBottom: '2.5rem',
          backgroundColor: uibRed50,
          height: 'auto',
          minWidth: '400px',
          maxWidth: '900px',
          maxHeight: '70%',
          overflowY: 'auto',
          borderRadius: '0.5rem',

          [breakpoints.down('md')]: {
            minWidth: '0',
            minHeight: '0',
            maxHeight: '100%',
            height: '100%',
            width: '100%',
            marginTop: '5rem',
            borderRadius: '0.5rem 0.5rem 0rem 0rem',
          },
        },
      },
      {
        props: { variant: 'mint' },
        style: { backgroundColor: uibGreen50 },
      },
      {
        props: { variant: 'compactChecklistMint' },
        style: {
          backgroundColor: uibGreen100,
          padding: '3rem',
          display: 'flex',
          [breakpoints.down('md')]: {
            padding: '2rem',
          },
        },
      },
      {
        props: { variant: 'progressbarTransparent' },
        style: { backgroundColor: 'transparent' },
      },
      {
        props: { variant: 'progressbarWhite' },
        style: {
          backgroundColor: 'white',
          width: '100%',
          borderRadius: '4px',
          padding: '2rem',
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          [breakpoints.down('md')]: {
            padding: '1rem',
          },
        },
      },
      {
        props: { variant: 'white' },
        style: { backgroundColor: 'white', width: '100%' },
      },
      {
        props: { variant: 'beige' },
        style: { backgroundColor: uibBeige },
      },
      {
        props: { variant: 'middleRed' },
        style: { backgroundColor: uibRed200 },
      },
      {
        props: { variant: 'red' },
        style: { backgroundColor: uibRed700, color: 'white' },
      },
      {
        props: { variant: 'darkRed' },
        style: {
          backgroundColor: uibRed800,
          color: 'white',
        },
      },
      {
        props: { variant: 'contentBoxMint' },
        style: {
          backgroundColor: uibGreen50,
          padding: '1rem 1.5rem',
        },
      },
      {
        props: { variant: 'contentBoxBeige' },
        style: {
          backgroundColor: uibBeige,
          padding: '1rem 1.5rem',
        },
      },
      {
        props: { variant: 'contentBoxSoftRed' },
        style: {
          backgroundColor: uibRed50,
          padding: '1rem 1.5rem',
        },
      },
    ],
  }
  return muiPaper
}
