import { FC, SVGProps, useEffect, useRef, useState } from 'react'

import { IconVariants, IllustrationVariants } from '@/assets'

type SvgType = 'icons' | 'Illustration'

export function useDynamicSvgImport(svgName: IconVariants | IllustrationVariants, svgType: SvgType) {
  const importedIconRef = useRef<FC<SVGProps<SVGElement>>>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<unknown>()

  useEffect(() => {
    setLoading(true)
    // dynamically import the mentioned svg icon name in props
    const importSvgIcon = async (): Promise<void> => {
      // please make sure all your svg icons are placed in the same directory
      // if we want that part to be configurable then instead of iconName we will send iconPath as prop
      try {
        if (svgType == 'icons') {
          // svgr provides ReactComponent for given svg path
          importedIconRef.current = (await import(`./icons/${svgName}.svg?react`)).ReactComponent
        } else {
          importedIconRef.current = (await import(`./svg/${svgName}.svg?react`)).ReactComponent
        }
      } catch (err) {
        setError(err)
        console.error(err)
      } finally {
        setLoading(false)
      }
    }

    importSvgIcon()
  }, [svgName])

  return { error, loading, SvgComponent: importedIconRef.current }
}
