import { PropsWithChildren } from 'react'

import { DummyMatomoContextProvider } from './DummyMatomoContextProvider'
import MatomoContext, { MatomoContextProps } from './MatomoContext'
import { MatomoEventTrackerData, TRACKED_MATOMO_EVENTS, getEventTrackerString } from './MatomoEvents'
import { MatomoVariableTrackerString, getPwaUsageMatomoString } from './MatomoVariables'

interface Props {}

/**
 * Command names and arguments that can be sent using the _paq variable.
 * If we want to implement more Matomo calls, this type will need to be extended.
 */
type Command =
  // Setup commands
  | ['enableLinkTracking', 'true' | 'false']
  | ['setSiteId', string]
  | ['setTrackerUrl', string]
  | ['trackPageView', string]
  | ['trackPageView']
  | ['appendToTrackingUrl', string]
  // Events have category & action
  | ['trackEvent', ...MatomoEventTrackerData]
  // User handling
  | ['setUserId', string]
  | ['resetUserId']
  // Custom variables
  | ['setCustomVariable', ...MatomoVariableTrackerString]

interface Paq {
  push: (command: Command) => void
}
declare const window: {
  _paq: Paq
} & Window
/**
 * Initialize and return a _paq instance.
 * This function needs to be run once before starting to track.
 * The content is Matomo's own template code, and will download some JS to handle API calls
 */
function initializeMatomo() {
  if (!import.meta.env.VITE_MATOMO_URL_BASE) {
    console.warn('Matomo url base not set => cannot initialize')
    return
  }

  if (window._paq !== undefined) {
    // Already initialized, no need to do it again
    return
  }

  // WARNING: Do NOT push trackPageView here,
  //   as it would track login/auth callbacks which we do not want
  var _paq = []
  _paq.push(['enableLinkTracking', 'true'])
  ;(function () {
    var u = import.meta.env.VITE_MATOMO_URL_BASE

    _paq.push(['setTrackerUrl', u + 'matomo.php'])
    _paq.push(['setSiteId', import.meta.env.VITE_MATOMO_SITE_ID])

    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0]
    g.async = true
    g.src = u + 'matomo.js'
    s.parentNode!.insertBefore(g, s)
  })()

  window._paq = _paq
}

const LOGGING_BLACKLIST = [
  'sanctum', // used for storing CSRF cookies which are requested before login/revalidation
  'callback', // used when logged inn user is returned to application
]
const shouldUrlBeLogged = (path: string) => {
  LOGGING_BLACKLIST.forEach((blacklisted_path) => {
    if (path.includes(blacklisted_path)) return false
  })
  return true
}

const MatomoContextProvider = ({ children }: PropsWithChildren<Props>) => {
  if (!import.meta.env.VITE_MATOMO_URL_BASE) {
    console.warn('[Matomo] Configuration is missing and can therefore not be initialized - creating dummy context')
    return <DummyMatomoContextProvider shouldUrlBeLogged={shouldUrlBeLogged} children={children} />
  }

  initializeMatomo()

  const trackPageView = (path: string) => {
    if (shouldUrlBeLogged(path)) {
      window._paq.push(['trackPageView', path])
    }
  }
  const trackEvent = (event: TRACKED_MATOMO_EVENTS) => {
    const eventTrackerString = getEventTrackerString(event)
    console.log('[Matomo] Tracking event', eventTrackerString)
    window._paq.push(['trackEvent', ...eventTrackerString])
  }

  const defaultContext: MatomoContextProps = {
    trackPageView,
    trackUser: {
      setUserId: (userId: string) => {
        window._paq.push(['setUserId', userId])
      },
      resetUserId: () => {
        window._paq.push(['resetUserId'])
        // we also force a new visit to be created for the pageviews after logout
        window._paq.push(['appendToTrackingUrl', 'new_visit=1'])
        window._paq.push(['trackPageView'])
        // we finally make sure to not again create a new visit afterwards (important for Single Page Applications)
        window._paq.push(['appendToTrackingUrl', ''])
      },
    },
    trackEvent,
    trackPwaUsage: () => {
      const pwaUsageTrackerString = getPwaUsageMatomoString()
      console.log('[Matomo] Tracking pwa usage: ', ...pwaUsageTrackerString)
      window._paq.push(['setCustomVariable', ...getPwaUsageMatomoString()])
    },
  }

  return <MatomoContext.Provider value={defaultContext} children={children} />
}

export default MatomoContextProvider
