const macosPlatforms = ['macos', 'macintosh', 'macintel', 'macppc', 'mac68k'],
  windowsPlatforms = ['win32', 'win64', 'windows', 'wince']

export type DEVICE_TYPES = 'android' | 'ios' | 'ipad' | 'mac' | 'windows' | 'linux' | 'other'
type GET_USER_DEVICE_FN = () => DEVICE_TYPES

/**
 * Attempt to figure out which os/device type the user has
 *
 * @warning THIS CHECK IS NOT 100% RELIABLE,
 *  as browsers and privacy-focused addons often obfuscate
 *  or randomize user information to avoid fingerprinting.
 *
 * You should therefore only use this in conjunction with feature detection
 *
 * There are more reliable ways to check this,
 *  but good frameworks are ~600kb and should therefore be run server-side.
 * See https://www.npmjs.com/package/device-detector-js
 **/
const unreliablyGetUserPlatform: GET_USER_DEVICE_FN = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  const platform = window.navigator.platform.toLowerCase()

  // WARNING - do not change the order of if-sentences in this method,
  //  as that will lead to incorrect detection!
  // e.g. linux is often present on android userAgent/platform)
  if (/(ipad|iphone|ipod)/g.test(platform) || /(ipad|iphone|ipod)/g.test(userAgent)) {
    // We treat iPads before v13 as ios instead of bothering with more checks,
    //  since we use this detection to display different messages for the PWA
    //  which isn't supported for older versions of ios/macos
    return 'ios'
  }
  if (platform === 'macintel' && navigator.maxTouchPoints > 1) {
    // iPads v13+ has platform 'MacIntel' => differentate by multi-touch support
    return 'ipad'
  }
  if (macosPlatforms.includes(platform)) return 'mac'

  if (/android/.test(userAgent)) return 'android'
  if (windowsPlatforms.includes(platform)) return 'windows'
  if (/linux/.test(platform)) return 'linux'

  return 'other'
}

export { unreliablyGetUserPlatform }
