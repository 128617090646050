// NOTE: only 5 variables can be tracked, from index 1-5
// _paq.push(['setCustomVariable', 1, 'Category', 'Sports', 'page']);
//
// For details, see
//   https://developer.matomo.org/guides/tracking-javascript-guide#manually-trigger-events
import { isUsingPwa, serviceWorkerSupported } from '@/utils/pwaUtils'

export type MatomoVariableTrackerString = [
  /** Index from 1-5 of tracked value (only 5 variables may be tracked for each scope) */
  index: number,
  /** Name of the tracked variable */
  name: string,
  /** Value of the tracked value */
  value: string,
  /** Scope of the tracked value */
  scope: 'visit' | 'page'
]

export type PwaUsageStatus = 'unsupported' | 'using_pwa' | 'not_using_pwa'

export const getPwaUsageMatomoString = (): MatomoVariableTrackerString => {
  let status: PwaUsageStatus
  if (!serviceWorkerSupported()) {
    status = 'unsupported'
  } else if (!isUsingPwa()) {
    status = 'not_using_pwa'
  } else {
    status = 'using_pwa'
  }
  return [1, 'PwaUsage', status, 'visit']
}
