import { useState } from 'react'

import { UibCustomIcon } from '@/assets'
import { ReactComponent as UibTreetLogo } from '@/assets/svg/uib_treet_logo.svg'
import useAuthenticatedUser from '@/contexts/user/useAuthenticatedUser'
import { useUibLanguage } from '@/language'
import { MaxWidthLayoutBox } from '@/modules/layout/maxWidthGuards'
import { prefetchAllCalendarDataForMonth } from '@/pages/CalendarPage/CalendarUtils'
import { uibBeige, uibRed200 } from '@/styles'
import { Box, Typography, styled, useTheme } from '@mui/material'
import { Link } from '@tanstack/react-location'
import { useQueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'

import { MenuDrawer } from '../menu'
import { NotificationNavbarItem } from './NotificationNavbarItem'
import { StyledNavbarIconButton } from './StyledNavbarIconButton'

const MaxWidthLayoutBoxWithLogoStylesOverrides = styled(MaxWidthLayoutBox)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  a: {
    all: 'unset',
    cursor: 'pointer',

    '&:focus-visible': {
      div: {
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          borderRadius: '4px',
          top: '-5px',
          left: '-5px',
          right: '-5px',
          bottom: '-5px',
          border: '2px solid',
          borderColor: uibRed200,
        },
      },
    },
  },
})

const ImpersonationInformer = styled(Box)({
  position: 'fixed',
  bottom: '0',
  backgroundColor: uibBeige,
  zIndex: 10,
  borderRadius: '0.5rem',
  padding: '0.25rem',
})

export const Header = () => {
  const { user, authenticated } = useAuthenticatedUser()

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
  const theme = useTheme()
  const { langDict } = useUibLanguage()
  const queryClient = useQueryClient()

  const openMenuAndPrefetchCalendarData = () => {
    setIsMenuOpen(true)
    prefetchAllCalendarDataForMonth(queryClient, DateTime.now())
  }

  return (
    <Box padding={'1rem'} width='100%' alignItems={'center'} display='flex' flexDirection={'column'}>
      <MaxWidthLayoutBoxWithLogoStylesOverrides>
        <Link to='/' aria-label={langDict('menu.homeLinkLabel')}>
          <Box>{UibTreetLogo && <UibTreetLogo />}</Box>
        </Link>

        <Box display='flex' flexDirection={'row'} gap='1rem'>
          {authenticated && <NotificationNavbarItem />}
          <StyledNavbarIconButton onClick={openMenuAndPrefetchCalendarData}>
            <UibCustomIcon icon='menu' colorOverride={theme.palette.text.secondary} />
            <Typography variant='subtitle2'>{langDict('menu.buttonLabel')}</Typography>
          </StyledNavbarIconButton>
        </Box>
        <MenuDrawer isMenuOpen={isMenuOpen} onCloseToggle={() => setIsMenuOpen(false)} />
      </MaxWidthLayoutBoxWithLogoStylesOverrides>
      {user?.is_impersonating && (
        <ImpersonationInformer>
          <Typography variant='h2' color='error'>
            {langDict('impersonation.currentlyImpersonating') + user.is_impersonating}
          </Typography>
        </ImpersonationInformer>
      )}
    </Box>
  )
}
