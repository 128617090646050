import {
  CalendarApi,
  Configuration,
  ConfirmationsApi,
  ExamApi,
  ImpersonateApi,
  LinksApi,
  NotificationsApi,
  ProfileApi,
  PushApi,
  ShortcutsApi,
  StudiesApi,
  TodosApi,
} from '@/generated/openapi'
import axios from 'axios'

export const axiosInstance = axios.create({
  // Sends session cookie along with requests
  withCredentials: true,
  // Set baseURL for when we use this instance directly
  baseURL: import.meta.env.VITE_DASHBOARD_API_URL,
})

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const status = error.response?.status
    if (status === 401) {
      localStorage.setItem('sanctum_state', JSON.stringify({ user: null, authenticated: null }))
    } else {
      return Promise.reject(error)
    }
  }
)

export const scheduleClient = new CalendarApi(
  new Configuration(),
  import.meta.env.VITE_DASHBOARD_API_URL,
  axiosInstance
)

export const todoClient = new TodosApi(new Configuration(), import.meta.env.VITE_DASHBOARD_API_URL, axiosInstance)

export const profileClient = new ProfileApi(new Configuration(), import.meta.env.VITE_DASHBOARD_API_URL, axiosInstance)

export const linkClient = new LinksApi(new Configuration(), import.meta.env.VITE_DASHBOARD_API_URL, axiosInstance)

export const examClient = new ExamApi(new Configuration(), import.meta.env.VITE_DASHBOARD_API_URL, axiosInstance)

export const notificationClient = new NotificationsApi(
  new Configuration(),
  import.meta.env.VITE_DASHBOARD_API_URL,
  axiosInstance
)

export const pushClient = new PushApi(new Configuration(), import.meta.env.VITE_DASHBOARD_API_URL, axiosInstance)

export const confirmationClient = new ConfirmationsApi(
  new Configuration(),
  import.meta.env.VITE_DASHBOARD_API_URL,
  axiosInstance
)

export const impersonateClient = new ImpersonateApi(
  new Configuration(),
  import.meta.env.VITE_DASHBOARD_API_URL,
  axiosInstance
)

export const shortcutsClient = new ShortcutsApi(
  new Configuration(),
  import.meta.env.VITE_DASHBOARD_API_URL,
  axiosInstance
)

export const studiesClient = new StudiesApi(new Configuration(), import.meta.env.VITE_DASHBOARD_API_URL, axiosInstance)
