import { Theme, createTheme } from '@mui/material'

import {
  MuiCssBaseline,
  getMuiBadge,
  getMuiButton,
  getMuiPaper,
  getMuiSwitch,
  muiAccordion,
  muiAccordionDetails,
  muiAccordionSummary,
  muiDivider,
  muiFilledInput,
  muiIconButton,
  muiInput,
  muiInputLabel,
  muiLink,
  muiList,
  muiListItemButton,
  muiMenuItem,
  muiOutlinedInput,
  muiTextField,
  muiTooltip,
} from './overrides'
import { muiListItemIcon } from './overrides/listItemIcon'
import { palette } from './palette'
import { getTypographyOverrides } from './typography'

/** We first innit a default theme with the pallete.
 *  This is so we can easily expose the created constants (such as breakpoints) to be utilized by sub components that
 *  go into further declaration of theme properties.
 *
 * Each override has been split into the /overrides folder and is inserted here.
 */
const defaultTheme = createTheme({ palette: palette })
const { breakpoints: defaultBreakpoints } = defaultTheme
const uibFont = ['myriad-pro', 'sans-serif'].join(',')

const theme: Theme = {
  ...defaultTheme,
  typography: {
    ...defaultTheme.typography,
    ...getTypographyOverrides(defaultBreakpoints),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          //Font and MUI can be a bit janky - in this project, font also had to be inserted here
          fontFamily: uibFont,
        },
      },
    },
    MuiLink: muiLink,
    MuiCssBaseline: MuiCssBaseline,
    MuiDivider: muiDivider,
    MuiMenuItem: muiMenuItem,
    MuiTooltip: muiTooltip,
    MuiButton: getMuiButton(defaultBreakpoints),
    MuiPaper: getMuiPaper(defaultBreakpoints),
    MuiList: muiList,
    MuiIconButton: muiIconButton,
    MuiListItemButton: muiListItemButton,
    MuiListItemIcon: muiListItemIcon,
    MuiAccordion: muiAccordion,
    MuiAccordionDetails: muiAccordionDetails,
    MuiAccordionSummary: muiAccordionSummary,
    MuiSwitch: getMuiSwitch(defaultBreakpoints),
    MuiBadge: getMuiBadge(defaultBreakpoints, uibFont),
    MuiInputLabel: muiInputLabel,
    MuiOutlinedInput: muiOutlinedInput,
    MuiFilledInput: muiFilledInput,
    MuiInput: muiInput,
    MuiTextField: muiTextField,
  },
}

export default theme
