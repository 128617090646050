import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { uibRed100, uibRed300 } from '../palette'

interface MuiCssBaseline {
  defaultProps?: ComponentsProps['MuiCssBaseline']
  styleOverrides?: ComponentsOverrides['MuiCssBaseline']
  variants?: ComponentsVariants['MuiCssBaseline']
}

export const MuiCssBaseline: MuiCssBaseline = {
  styleOverrides: {
    '*': {
      fontFamily: ['myriad-pro', 'sans-serif'].join(','),
      scrollbarWidth: 'thin',
      scrollbarColor: 'red',
    },
    html: {
      minHeight: '100vh',
    },
    body: {
      minHeight: '100vh',
    },
    '#root': {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
    },

    //Not supported by Mozilla
    '::-webkit-scrollbar': {
      width: '8px',
      backgroundColor: uibRed100,
    },
    '::-webkit-scrollbar-thumb': {
      margin: '1px',
      borderRadius: '20px',
      backgroundColor: uibRed300,
    },
  },
}
