import { useMemo } from 'react'

import { useLinks } from '@/api/hooks'
import { Link } from '@/generated/openapi'

export interface LinkCategory {
  categoryName: string
  categoryKey: string
  links: Link[]
}

/** Uses the useLinks hook fetcher and transforms the data into
 *  appropriate link categories.
 */
export const useCategorizedLinks = () => {
  const { linksData, status, refetch } = useLinks()


  const organizedLinks = useMemo(() => {
    if (linksData === undefined) {
      return undefined
    }
    const uniqueCategoryList: string[] = []

    linksData.forEach((linkEntry) => {
      if (!!linkEntry.category_id && !uniqueCategoryList.includes(linkEntry.category_id)) {
        uniqueCategoryList.push(linkEntry.category_id)
      }
    })

    const newOrganizedLinks: LinkCategory[] = []

    for (const uniqueCategory of uniqueCategoryList) {
      const uniqueLinks = linksData.filter((link) => link.category_id === uniqueCategory)

      //We are never supposed to have a categoryId without a category title, but basing on typescript here.
      const headerName = uniqueLinks.find((link) => !!link.category_title)?.category_title ?? ''
      newOrganizedLinks.push({
        categoryName: headerName,
        categoryKey: uniqueCategory,
        links: uniqueLinks,
      })
    }
    return newOrganizedLinks
  }, [linksData])

  return {
    status,
    organizedLinks,
    refetch
  }
}
