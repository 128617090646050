import { Breakpoints, ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { defaultFocusStyleDark, defaultFocusStyleLight } from '../focusStyling'
import { uibGreen300, uibGreen800, uibRed100, uibRed200, uibRed600, uibRed700, uibRed900 } from '../palette'

interface MuiButton {
  defaultProps?: ComponentsProps['MuiButton']
  styleOverrides?: ComponentsOverrides['MuiButton']
  variants?: ComponentsVariants['MuiButton']
}

/** @todo when updating to new uib design give all buttons this radius */
const buttonRadius = '4px' // 0.5rem?

export const getMuiButton = (breakpoints: Breakpoints) => {
  const muiButton: MuiButton = {
    styleOverrides: {
      root: {
        borderRadius: '0px',
        width: 'fit-content',
        textTransform: 'none',
        minHeight: '40px',
        padding: '0.5rem 1rem',
        transition: '0.2s',
        '&:focus-visible': {
          '&::before': defaultFocusStyleLight,
        },
      },
    },
    variants: [
      {
        props: { size: 'small' },
        style: {
          minHeight: '28px',
          padding: '0.5rem 0.75rem',
          fontSize: '14px',
          [breakpoints.down('md')]: {
            padding: '12px',
          },
        },
      },
      {
        props: { variant: 'contained' },
        style: {
          borderRadius: buttonRadius,
          color: 'white',
          backgroundColor: uibRed700,
          '&:hover': {
            backgroundColor: uibRed600,
          },
          '&:focus-visible': {
            '&::before': {
              borderColor: uibRed200,
            },
          },
        },
      },
      {
        props: { variant: 'secondary' },
        style: {
          backgroundColor: uibGreen300,
          color: uibRed900,
          border: '0.25rem solid transparent',
          padding: '0.25rem 0.75rem',
          '&:hover': {
            backgroundColor: '#fff',
            borderColor: uibGreen300,
          },
          '&:focus-visible': {
            backgroundColor: '#fff',
            borderColor: uibGreen300,
          },
          '&:focus': {
            backgroundColor: '#fff',
            borderColor: 'uibGreen300',
          },
        },
      },
      {
        props: { variant: 'scheduleBoxButton' },
        style: {
          color: uibRed900,

          boxSizing: 'border-box',
          backgroundColor: 'white',
          width: '5.5rem',
          height: '6.25rem',
          padding: '12px',

          [breakpoints.down('md')]: {
            width: '4.75rem',
            height: '5.5rem',
            padding: '9px',
          },
          [breakpoints.down('sm')]: {
            width: '4rem',
            height: '5rem',
            padding: '6px',
          },
          '&:hover': {
            color: 'white',
            backgroundColor: uibRed600,
          },
          '&:focus-visible': {
            '&::before': defaultFocusStyleDark,
          },
          '&.scheduleBoxButtonActive': {
            backgroundColor: uibRed600,
            boxShadow: ' 0px 4px 4px rgba(0, 0, 0, 0.25)',
            color: 'white',

            '&:hover': {
              backgroundColor: uibRed600,
            },
          },
        },
      },
      {
        props: { variant: 'text' },
        style: {
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
      {
        props: { variant: 'group' },
        style: {
          color: 'black',
          backgroundColor: 'white',
          border: '1px solid black',

          minHeight: '24px',
          fontSize: '16px',
          padding: '8px 12px',
          [breakpoints.down('md')]: {
            fontSize: '14px',
            padding: '6px 8px',
          },
          [breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: '5px 6px',
          },

          '&:first-of-type': {
            borderTopLeftRadius: buttonRadius,
            borderBottomLeftRadius: buttonRadius,
          },
          '&:last-of-type': {
            borderTopRightRadius: buttonRadius,
            borderBottomRightRadius: buttonRadius,
          },
          '& + &': {
            borderLeft: 0,
          },
          '&.group-button--first': {
            borderTopLeftRadius: buttonRadius,
            borderBottomLeftRadius: buttonRadius,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          },
          '&.group-button--middle': {
            borderLeft: 0,
            borderRadius: 0,
          },
          '&.group-button--last': {
            borderLeft: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderTopRightRadius: buttonRadius,
            borderBottomRightRadius: buttonRadius,
          },
          '&.group-button--active': {
            backgroundColor: uibGreen800,
            color: 'white',
          },
        },
      },
      {
        props: { variant: 'shortcut' },
        style: {
          // TODO rename button class and impl style
        },
      },
    ],
  }
  return muiButton
}
