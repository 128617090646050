/**
 * Checks if the current users device most likely is iOS
 * Inaccuracies may happen due to...
 * - Browsers setting their own user agent string (so chrome has 'Mozilla' in its user agent..)
 * - Privacy tools obfuscating or randomizing the user agent (to avoid tracking)
 */
export const isDeviceProbablyIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

/** Detects if device is browsing the webpage through the pwa (e.g. standalone mode) */
export const isUsingPwa = () => 'standalone' in window.navigator && window.navigator.standalone

/** @deprecated WIP DO NOT USE - doesn't work in its current version, unsure why */
export const isPwaInstalled = async () => {
  if ('getInstalledRelatedApps' in navigator) {
    // @ts-ignore
    // Related apps always seems to be empty array in chrome even if you've installed the PWA
    const relatedApps = await navigator.getInstalledRelatedApps()
    if (relatedApps !== null && relatedApps !== undefined && Array.isArray(relatedApps)) {
      return relatedApps.length >= 0 //
    }
  }
}

export const serviceWorkerSupported = () => 'serviceWorker' in navigator
export const notificationApiSupported = () => 'Notification' in window
export const pushManagerSupported = () => 'PushManager' in window
