import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { uibBeige } from '../palette'

interface MuiDivider {
  defaultProps?: ComponentsProps['MuiDivider']
  styleOverrides?: ComponentsOverrides['MuiDivider']
  variants?: ComponentsVariants['MuiDivider']
}

export const muiDivider: MuiDivider = {
  styleOverrides: {
    root: {
      borderColor: uibBeige,
    },
  },
}
