import { PropsWithChildren } from 'react'

import { UibCustomIcon } from '@/assets'
import { useUibLanguage } from '@/language'
import { IconButton, Modal, Paper, Slide } from '@mui/material'

type SlidingModalProps = {
  isOpen: boolean
  onClose: () => void
  /**
   * Should an 'X' be shown in the top right which allows closing of the modal?
   * - defaults to false to avoid breaking existing views
   * - @todo refactor views which use the sliding modal with their own X
   */
  showCloseButton?: boolean
  /** Set to true if you wish to remove all padding from the modal */
  noPadding?: boolean
}

const CloseModalButton = ({ onClose, noPadding, showCloseButton = false }: Omit<SlidingModalProps, 'isOpen'>) => {
  const { langDict } = useUibLanguage()

  if (!showCloseButton) {
    return null
  }

  return (
    <IconButton
      onClick={onClose}
      aria-label={langDict('generic.closeButton')}
      sx={{
        position: 'absolute',
        right: noPadding ? '1rem' : '2rem',
        top: noPadding ? '1rem' : '2rem',
      }}>
      <UibCustomIcon icon='close' fontSize='small' />
    </IconButton>
  )
}

export const SlidingModal = ({
  isOpen,
  onClose,
  showCloseButton = false,
  noPadding = false,
  children,
}: PropsWithChildren<SlidingModalProps>) => {
  const sx = {
    ...(noPadding ? { padding: '0' } : {}),
    ...(showCloseButton ? { position: 'relative' } : {}),
  }

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeAfterTransition
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}>
      <Slide in={isOpen} direction='up'>
        <Paper variant='modalPaper' sx={sx}>
          {showCloseButton && (
            <CloseModalButton onClose={onClose} showCloseButton={showCloseButton} noPadding={noPadding} />
          )}
          {children}
        </Paper>
      </Slide>
    </Modal>
  )
}
