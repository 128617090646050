import { Profile } from '@/generated/openapi'
import useLocalStorageState from 'use-local-storage-state'

export const LOCAL_STORAGE_KEY_SANCTUM_STATE = 'sanctum_state'

export default function useUserStorageState() {
  return useLocalStorageState<{
    user: null | Profile
    authenticated: null | boolean
  }>(LOCAL_STORAGE_KEY_SANCTUM_STATE, {
    defaultValue: { user: null, authenticated: null },
  })
}
