import { PwaInstallationBannerVariant } from '@/modules/widgets/pwaInstallationBanner/PwaInstallation'

////
//#region PwaBannerEvents
////
// PWA Installation Banner events
type PWA_INSTALLATION_BANNER_SHOWN_EVENT = {
  action: 'ShownToUser'
  name: PwaInstallationBannerVariant | 'BannerWithInstallButton'
}
type PWA_INSTALLATION_BANNER_DISMISSED_EVENT = {
  action: 'DismissedByUser'
  name: PwaInstallationBannerVariant | 'BannerWithInstallButton'
}
type PWA_INSTALLATION_BANNER_INSTALL_BUTTON_CLICK_EVENT = {
  action: 'InstallButtonClicked'
  name: 'BannerWithInstallButton'
}
// Note: The browsers installation prompt is ONLY tracked when triggered from the users install btn
type PWA_INSTALLATION_PROMPT_DISMISSED_EVENT = {
  action: 'BrowserInstallPrompt_DismissedByUser'
  name: 'BrowserInstallationPrompt'
}
type PWA_INSTALLATION_PROMPT_ACCEPTED_EVENT = {
  action: 'BrowserInstallPrompt_AcceptedByUser'
  name: 'BrowserInstallationPrompt'
}

type PWA_INSTALLATION_BANNER_EVENTS = {
  category: 'PwaBanner'
} & (
  | PWA_INSTALLATION_BANNER_SHOWN_EVENT
  | PWA_INSTALLATION_BANNER_DISMISSED_EVENT
  | PWA_INSTALLATION_BANNER_INSTALL_BUTTON_CLICK_EVENT
  | PWA_INSTALLATION_PROMPT_ACCEPTED_EVENT
  | PWA_INSTALLATION_PROMPT_DISMISSED_EVENT
)
//#endregion

//////
// #region TrackingFn
// - types and functions to simplify safe usage of tracking
/////
/**
 * Events we wish to track in matomo
 * @note 'value' field will only be used if name is present
 */
export type TRACKED_MATOMO_EVENTS = {
  name?: string
  value?: number
} & PWA_INSTALLATION_BANNER_EVENTS

/**
 * Matomo events need a category and action,
 * - optionally add a name (representing the element of the action)
 * - optionally add a NUMERIC value (requires name to exist)
 */
export type MatomoEventTrackerData =
  | [category: string, action: string]
  | [category: string, action: string, name: string]
  | [category: string, action: string, name: string, value: number]

/** Parses an event to create the tracker-string which matomo requires */
export const getEventTrackerString = ({
  name,
  category,
  action,
  value,
}: TRACKED_MATOMO_EVENTS): MatomoEventTrackerData => {
  if (name === undefined) {
    return [category, action]
  }
  if (value === undefined) {
    return [category, action, name]
  }
  return [category, action, name, value]
}
//#endregion
