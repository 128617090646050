import { useDynamicSvgImport } from '@/assets/useDynamicSvgImport'

import { ReactComponent as Illustration_Waiting } from './svg/Illustration_waiting.svg'
import { ReactComponent as Illustration_Waiting_Contrast } from './svg/Illustration_waiting_contrast.svg'
import { IllustrationVariants } from './types'

interface UibIllustrationProps extends React.SVGProps<SVGSVGElement> {
  illustration: IllustrationVariants
  title?: string | undefined
}

const LazyUibIllustration = ({ illustration, ...rest }: UibIllustrationProps) => {
  const { loading, SvgComponent } = useDynamicSvgImport(illustration, 'Illustration')

  if (loading || !SvgComponent) return null
  if (illustration === 'uib_treet_logo') return <SvgComponent />
  if (illustration === 'Illustration_calendar_page')
    return (
      <SvgComponent
        {...rest}
        style={{
          minWidth: '150px',
          width: '35%',
          maxWidth: '200px',
        }}
      />
    )
  return <SvgComponent aria-hidden {...rest} />
}

export const UibIllustration = ({ illustration, ...rest }: UibIllustrationProps) => {
  switch (illustration) {
    case 'Illustration_waiting': {
      return <Illustration_Waiting aria-hidden {...rest} />
    }
    case 'Illustration_waiting_contrast': {
      return <Illustration_Waiting_Contrast aria-hidden {...rest} />
    }
    default: {
      return <LazyUibIllustration illustration={illustration} {...rest} />
    }
  }
}
