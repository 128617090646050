import { Breakpoints, ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { uibGreen300, uibRed200, uibRed800 } from '../palette'

interface MuiSwitch {
  defaultProps?: ComponentsProps['MuiSwitch']
  styleOverrides?: ComponentsOverrides['MuiSwitch']
  variants?: ComponentsVariants['MuiSwitch']
}

const SWITCH_WRAPPER_HEIGHT = '31px'
const SWITCH_THUMB_HEIGHT = '27px' // Slightly less then wrapper to account for border
const SWITCH_TRACK_HEIGHT = '27px'

export const getMuiSwitch = (breakpoints: Breakpoints) => {
  const muiSwitch: MuiSwitch = {
    styleOverrides: {
      root: {
        padding: 1,
        height: SWITCH_WRAPPER_HEIGHT,
        marginRight: '0.5rem',
      },

      // Wrapper for the "thumb", e.g. the button
      switchBase: {
        top: 2,
        padding: 0,
        '&.Mui-checked': {
          right: '13px',
          '& + .MuiSwitch-track': {
            backgroundColor: uibGreen300,
          },
        },
      },

      thumb: {
        padding: 0,
        boxSizing: 'border-box',
        height: SWITCH_THUMB_HEIGHT,
        width: SWITCH_THUMB_HEIGHT,
        borderWidth: '1px',
        borderColor: uibRed800,
        borderStyle: 'solid',
      },

      // "track" or "bar" styling
      track: {
        top: 1,
        backgroundColor: uibRed200,
        opacity: 1,
        borderRadius: SWITCH_TRACK_HEIGHT,
        height: SWITCH_TRACK_HEIGHT,
        position: 'relative',
        width: 'calc(100% - 2px)',
        left: 1,
      },
    },
    variants: [],
  }
  return muiSwitch
}
