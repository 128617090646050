import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { defaultFocusStyleLight } from '../focusStyling'

interface MuiIconButton {
  defaultProps?: ComponentsProps['MuiIconButton']
  styleOverrides?: ComponentsOverrides['MuiIconButton']
  variants?: ComponentsVariants['MuiIconButton']
}

export const muiIconButton: MuiIconButton = {
  styleOverrides: {
    root: {
      '&:focus-visible': {
        '&::before': defaultFocusStyleLight,
      },
    },
  },
}
