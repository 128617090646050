import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { uibRed800 } from '../palette'

interface MuiTooltip {
  defaultProps?: ComponentsProps['MuiTooltip']
  styleOverrides?: ComponentsOverrides['MuiTooltip']
  variants?: ComponentsVariants['MuiTooltip']
}

export const muiTooltip: MuiTooltip = {
  styleOverrides: {
    tooltip: {
      backgroundColor: uibRed800,
      color: 'white',
      padding: '1.5rem',
      borderRadius: '0px',
      filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
    },
    arrow: {
      color: uibRed800,
    },
  },
}
