import { FC, useState } from 'react'

import { useImpersonate, useNotifications } from '@/api/hooks'
import { UibCustomIcon } from '@/assets'
import { LanguageSelector } from '@/components/languageSelector/LanguageSelector'
import { ImpersonationModal } from '@/components/modal'
import useAuthenticatedUser from '@/contexts/user/useAuthenticatedUser'
import { useUibLanguage } from '@/language'
import { Box, Divider, Drawer, IconButton, List, styled, useTheme } from '@mui/material'

import { NavigationListItem } from './components/NavigationListItem'
import { UserConfigListItem } from './components/UserConfigListItem'

const StyledMenuContainer = styled(Box)(({ theme }) => ({
  width: '352px',
  height: '100%',
  maxWidth: '80vw',
  backgroundColor: theme.palette.secondary.main,
  overflowX: 'hidden',
}))

interface IMenuDrawer {
  isMenuOpen: boolean
  onCloseToggle: () => void
}

export const MenuDrawer: FC<IMenuDrawer> = ({ isMenuOpen, onCloseToggle }) => {
  const [loadingState, setLoadingState] = useState<boolean>(false)
  const { authenticated, login, logout, user, revalidate } = useAuthenticatedUser()
  const { langDict } = useUibLanguage()
  const theme = useTheme()
  const { endImpersonation } = useImpersonate()
  const [impersonationModalOpen, setImpersonationModalOpen] = useState<boolean>(false)
  const { numberOfUnreadNotifications } = useNotifications()

  const onEndImpersonation = async () => {
    await endImpersonation()
    revalidate()
  }

  const onLogin = async () => {
    setLoadingState(true)
    await login()
    setLoadingState(false)
  }

  const onLogout = async () => {
    setLoadingState(true)
    await logout()
    // @todo matomo track user logout
    setLoadingState(false)
  }

  /**
   * Define navigation buttons here
   */
  const navigationItems = [
    {
      label: langDict('menu.home'),
      icon: <UibCustomIcon icon='home' colorOverride={theme.palette.secondary.light} />,
      onlyForAuthenticated: false,
      path: '/',
    },
    {
      label: langDict('menu.semesterStart'),
      icon: <UibCustomIcon icon='arrow_right' colorOverride={theme.palette.secondary.light} />,
      onlyForAuthenticated: true,
      path: '/mySemesterStart',
    },
    {
      label: langDict('menu.exams'),
      icon: <UibCustomIcon icon='arrow_right' colorOverride={theme.palette.secondary.light} />,
      onlyForAuthenticated: true,
      path: '/myExamPage',
    },
    {
      label: langDict('menu.notification'),
      icon: <UibCustomIcon icon='arrow_right' colorOverride={theme.palette.secondary.light} />,
      onlyForAuthenticated: true,
      path: '/notifications',
      badge: {
        label: numberOfUnreadNotifications || 0,
        ariaLabel: `${numberOfUnreadNotifications || 0} ${langDict('menu.newNotifications')}`,
      },
    },
    {
      label: langDict('menu.calendar'),
      icon: <UibCustomIcon icon='arrow_right' colorOverride={theme.palette.secondary.light} />,
      onlyForAuthenticated: true,
      path: '/mySchedule',
    },
    {
      label: langDict('menu.shortcuts'),
      icon: <UibCustomIcon icon='arrow_right' colorOverride={theme.palette.secondary.light} />,
      onlyForAuthenticated: true,
      path: '/shortcutCollection',
    },
  ]

  return (
    <Drawer open={isMenuOpen} onClose={onCloseToggle} anchor='right'>
      <StyledMenuContainer>
        <Box display='flex' justifyContent={'flex-end'} padding='1rem' paddingBottom={'3rem'}>
          <IconButton onClick={onCloseToggle} aria-label={langDict('generic.closeButton')}>
            <UibCustomIcon icon='close' colorOverride={theme.palette.text.secondary} />
          </IconButton>
        </Box>
        <List>
          {navigationItems
            .filter((navigationItem) => authenticated ?? !navigationItem.onlyForAuthenticated)
            .map((navigationItem, index) => (
              <NavigationListItem
                label={navigationItem.label}
                icon={navigationItem.icon}
                path={navigationItem.path}
                closeMenu={onCloseToggle}
                key={index}
                badge={navigationItem.badge}
              />
            ))}
          <Divider sx={{ borderColor: theme.palette.text.primary, margin: '3rem' }} />
          {user && user.is_dash_admin && (
            <UserConfigListItem
              label={langDict('menu.adminInterface')}
              icon={<UibCustomIcon icon='lock' colorOverride={theme.palette.secondary.light} />}
              onClick={() => {
                window.location.href = import.meta.env.VITE_DASHBOARD_API_URL + '/dash/admin'
              }}
            />
          )}
          {user && user.can_impersonate && !user.is_impersonating && (
            <UserConfigListItem
              label={langDict('menu.impersonate')}
              icon={<UibCustomIcon icon='mask' sx={{ svg: { path: { stroke: theme.palette.secondary.light } } }} />}
              onClick={() => setImpersonationModalOpen(true)}
            />
          )}
          {user?.is_impersonating && (
            <UserConfigListItem
              label={langDict('menu.endImpersonation')}
              icon={<UibCustomIcon icon='mask' sx={{ svg: { path: { stroke: theme.palette.secondary.light } } }} />}
              onClick={onEndImpersonation}
            />
          )}
          {authenticated ? (
            <UserConfigListItem
              label={langDict('menu.logout')}
              icon={<UibCustomIcon icon='logout' colorOverride={theme.palette.secondary.light} />}
              onClick={() => onLogout()}
              loading={loadingState}
            />
          ) : (
            <UserConfigListItem
              label={langDict('menu.login')}
              icon={<UibCustomIcon icon='login' colorOverride={theme.palette.secondary.light} />}
              onClick={() => onLogin()}
              loading={loadingState}
            />
          )}
          <LanguageSelector />
        </List>
      </StyledMenuContainer>
      <ImpersonationModal isOpen={impersonationModalOpen} onClose={() => setImpersonationModalOpen(false)} />
    </Drawer>
  )
}
