import { useEffect } from 'react'

import { linkClient } from '@/api/client'
import useUserStorageState from '@/api/hooks/useUserStorageState'
import { useUibLanguage } from '@/language'
import { useQuery } from '@tanstack/react-query'

export const QUERY_KEY_LINKS = 'links'

export function useLinks() {
  const [{ authenticated, user }] = useUserStorageState()
  const { locale } = useUibLanguage()

  // Let the query key change when user is logged in or language changes
  //  - this ensures requests are handled and cached seperately
  let queryKey = [
    QUERY_KEY_LINKS,
    {
      isAuthenticated: authenticated,
      language: user?.language ?? locale,
    },
  ]

  const response = useQuery({
    queryKey,
    queryFn: () => (authenticated ? linkClient.getUserLinks() : linkClient.getLinks((user?.language ?? locale) || '')),
  })

  const data = response.data?.data.data
  useEffect(() => {
    response.refetch()
  }, [user?.language, user?.is_impersonating])

  return {
    ...response,
    linksData: data,
  }
}
