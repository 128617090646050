import { Notification } from '@/generated/openapi'

export const notificationDummies: Notification[] = [
  {
    created_at: '2023-11-09T14:39:44.000000Z',
    id: 'asdwq2123',
    unread: false,
    data: {
      title: 'EXPHIL Timeplan endring',
      body: 'Det er gjort en endring i timeplan eller booking av rom',
      url: '/',
    },
  },
  {
    created_at: '2023-11-06T14:37:44.000000Z',
    id: 'qewwqdaq',
    unread: true,
    data: {
      title: 'INF100 Timeplan endring',
      body: 'Det er gjort en endring i timeplan eller booking av rom',
      url: '/',
    },
  },
  {
    created_at: '2023-11-04T14:39:44.000000Z',
    id: 'xcvdsdf',
    unread: false,
    data: {
      title: 'Element uten lenke/knapp',
      body: 'Lorem ipsum dolor sit amet',
      url: '',
    },
  },
  {
    created_at: '2023-11-06T14:39:44.000000Z',
    id: 'hrthr',
    unread: true,
    data: {
      title: 'INF100 Timeplan endring',
      body: 'Det er gjort en endring i timeplan eller booking av rom',
      url: '/',
    },
  },
]
