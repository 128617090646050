import { useMemo, useState } from 'react'

import { BeforeInstallPromptEvent } from './PwaInstallation'

/**
 * Returns a function to trigger pwa installation if this is supported,
 *  which is generally only the case for chrome(ium) users on android/windows
 */
export const useTriggerPwaInstallation = () => {
  const [installPrompt, setInstallPrompt] = useState<BeforeInstallPromptEvent | null>(null)

  // Hijack app installation event so that we can manually trigger installation
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault()
    setInstallPrompt(event)
  })

  // On app installation remove app installation possibility
  window.addEventListener('appinstalled', () => {
    setInstallPrompt(null)
  })

  const triggerPwaInstallation = useMemo(() => {
    if (!installPrompt) return null

    return async () => {
      const result = await installPrompt.prompt()
      console.log(`[PwaInstallationBanner] Install prompt was: ${result.outcome}`)
      setInstallPrompt(null)
      return result
    }
  }, [installPrompt, setInstallPrompt])

  return { triggerPwaInstallation }
}
