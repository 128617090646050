import { CalendarEventDetails } from '@/generated/openapi'
import { DateTime } from 'luxon'

export const processEventByDate = (dateBuckets: number, events?: CalendarEventDetails[]) => {
  const currentDate = DateTime.now()

  const workingRecord: Record<number, CalendarEventDetails[]> = {}
  const availableDates: DateTime[] = []

  if (!events) {
    return undefined
  }
  for (let i = 0; i < dateBuckets; i++) {
    availableDates.push(currentDate.plus({ days: i }))
  }

  for (const event of events) {
    if (event.start == undefined) {
      continue
    }
    const parsedEventTime = DateTime.fromISO(event.start)

    for (let i = 0; i < availableDates.length; i++) {
      if (parsedEventTime.hasSame(availableDates[i], 'day')) {
        const storedEntries = workingRecord[i] ?? []
        storedEntries.push(event)
        workingRecord[i] = storedEntries
        break
      }
    }
  }

  for (let i = 0; i < availableDates.length; i++) {
    const unsortedEvents = workingRecord[i] ?? []
    if (unsortedEvents.length !== 0) {
      const sortedEvents = unsortedEvents.sort((a, b) =>
        //We should have filtered away entries without start, so ?? '' is just for type safety
        DateTime.fromISO(a.start ?? '') < DateTime.fromISO(b.end ?? '') ? -1 : 1
      )
      workingRecord[i] = sortedEvents
    }
  }
  return workingRecord
}
