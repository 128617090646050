/* tslint:disable */
/* eslint-disable */
/**
 * Frontend API
 * This is the API that is provided for the student dashboard frontend.
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


  import { Configuration } from './configuration';
  // @ts-ignore
  import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
  globalAxios.defaults.withCredentials = true;
  // Some imports not used depending on template conditions
  // @ts-ignore
  import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
  // @ts-ignore
  import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

    /**
 * 
 * @export
 * @interface ApiV1SelfPushSubscriptionsGet200Response
 */
export interface ApiV1SelfPushSubscriptionsGet200Response {
    /**
     * 
     * @type {Array<ApiV1SelfPushSubscriptionsGet200ResponseDataInner>}
     * @memberof ApiV1SelfPushSubscriptionsGet200Response
     */
    'data'?: Array<ApiV1SelfPushSubscriptionsGet200ResponseDataInner>;
    /**
     * 
     * @type {number}
     * @memberof ApiV1SelfPushSubscriptionsGet200Response
     */
    'count'?: number;
}
    /**
 * 
 * @export
 * @interface ApiV1SelfPushSubscriptionsGet200ResponseDataInner
 */
export interface ApiV1SelfPushSubscriptionsGet200ResponseDataInner {
    /**
     * The URL endpoint for the subscription
     * @type {string}
     * @memberof ApiV1SelfPushSubscriptionsGet200ResponseDataInner
     */
    'endpoint'?: string;
}
    /**
 * 
 * @export
 * @interface CalendarEventDetails
 */
export interface CalendarEventDetails {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'parent_code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CalendarEventDetails
     */
    'all_day'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<CalendarEventDetailsLocationsInner>}
     * @memberof CalendarEventDetails
     */
    'locations'?: Array<CalendarEventDetailsLocationsInner>;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'event_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'source_event_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetails
     */
    'source_url'?: string | null;
}
    /**
 * 
 * @export
 * @interface CalendarEventDetailsLocationsInner
 */
export interface CalendarEventDetailsLocationsInner {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsLocationsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsLocationsInner
     */
    'url'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventDetailsLocationsInner
     */
    'address'?: string | null;
}
    /**
 * 
 * @export
 * @interface CalendarGroup
 */
export interface CalendarGroup {
    /**
     * 
     * @type {number}
     * @memberof CalendarGroup
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CalendarGroup
     */
    'code'?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarGroup
     */
    'color'?: string | null;
}
    /**
 * 
 * @export
 * @interface Channel
 */
export interface Channel {
    /**
     * 
     * @type {number}
     * @memberof Channel
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'short_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    'icon'?: string;
}
    /**
 * 
 * @export
 * @interface Confirmation
 */
export interface Confirmation {
    /**
     * 
     * @type {number}
     * @memberof Confirmation
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Confirmation
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Confirmation
     */
    'subtitle'?: string;
    /**
     * 
     * @type {string}
     * @memberof Confirmation
     */
    'description'?: string;
    /**
     * The url to the image
     * @type {string}
     * @memberof Confirmation
     */
    'icon'?: string | null;
    /**
     * Identifier for the status
     * @type {string}
     * @memberof Confirmation
     */
    'status'?: string | null;
}
    /**
 * 
 * @export
 * @interface ExamDescriptionObject
 */
export interface ExamDescriptionObject {
    /**
     * Whether the exam has been sent to protocol (means that the results are final in practice if it has)
     * @type {boolean}
     * @memberof ExamDescriptionObject
     */
    'protocol'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof ExamDescriptionObject
     */
    'candidate_number'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ExamDescriptionObject
     */
    'duration_in_hours'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'building_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'room_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'assignment_available'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'submission_deadline'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'period_start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'period_end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDescriptionObject
     */
    'start_date'?: string | null;
    /**
     * 
     * @type {Array<ExamDescriptionObject>}
     * @memberof ExamDescriptionObject
     */
    'exam_by_course_items'?: Array<ExamDescriptionObject>;
}
    /**
 * 
 * @export
 * @interface ExamDetailsByCourse
 */
export interface ExamDetailsByCourse {
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByCourse
     */
    'description'?: string;
    /**
     * 
     * @type {Array<ExamDescriptionObject>}
     * @memberof ExamDetailsByCourse
     */
    'description_object'?: Array<ExamDescriptionObject>;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByCourse
     */
    'course_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByCourse
     */
    'course_name'?: string;
}
    /**
 * 
 * @export
 * @interface ExamDetailsByDate
 */
export interface ExamDetailsByDate {
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByDate
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByDate
     */
    'date_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByDate
     */
    'course_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByDate
     */
    'course_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByDate
     */
    'start'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByDate
     */
    'end'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ExamDetailsByDate
     */
    'date'?: string | null;
    /**
     * 
     * @type {ExamDescriptionObject}
     * @memberof ExamDetailsByDate
     */
    'description_object'?: ExamDescriptionObject;
}
    /**
 * 
 * @export
 * @interface FavoritesOrderInner
 */
export interface FavoritesOrderInner {
    /**
     * 
     * @type {number}
     * @memberof FavoritesOrderInner
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof FavoritesOrderInner
     */
    'index'?: number;
}
    /**
 * 
 * @export
 * @interface GetCalendarGroups200Response
 */
export interface GetCalendarGroups200Response {
    /**
     * 
     * @type {Array<CalendarGroup>}
     * @memberof GetCalendarGroups200Response
     */
    'data'?: Array<CalendarGroup>;
}
    /**
 * 
 * @export
 * @interface GetChannels200Response
 */
export interface GetChannels200Response {
    /**
     * 
     * @type {Array<Channel>}
     * @memberof GetChannels200Response
     */
    'data'?: Array<Channel>;
}
    /**
 * 
 * @export
 * @interface GetCountUnreadUserNotifications200Response
 */
export interface GetCountUnreadUserNotifications200Response {
    /**
     * 
     * @type {number}
     * @memberof GetCountUnreadUserNotifications200Response
     */
    'count'?: number;
}
    /**
 * 
 * @export
 * @interface GetPredefinedShortcuts200Response
 */
export interface GetPredefinedShortcuts200Response {
    /**
     * 
     * @type {Array<PredefinedShortcut>}
     * @memberof GetPredefinedShortcuts200Response
     */
    'data'?: Array<PredefinedShortcut>;
}
    /**
 * 
 * @export
 * @interface GetProfile200Response
 */
export interface GetProfile200Response {
    /**
     * 
     * @type {Profile}
     * @memberof GetProfile200Response
     */
    'data'?: Profile;
}
    /**
 * 
 * @export
 * @interface GetUserConfirmations200Response
 */
export interface GetUserConfirmations200Response {
    /**
     * 
     * @type {Array<Confirmation>}
     * @memberof GetUserConfirmations200Response
     */
    'data'?: Array<Confirmation>;
}
    /**
 * 
 * @export
 * @interface GetUserEvents200Response
 */
export interface GetUserEvents200Response {
    /**
     * 
     * @type {Array<CalendarEventDetails>}
     * @memberof GetUserEvents200Response
     */
    'data'?: Array<CalendarEventDetails>;
}
    /**
 * 
 * @export
 * @interface GetUserExams200Response
 */
export interface GetUserExams200Response {
    /**
     * 
     * @type {GetUserExams200ResponseData}
     * @memberof GetUserExams200Response
     */
    'data'?: GetUserExams200ResponseData;
}
    /**
 * 
 * @export
 * @interface GetUserExams200ResponseData
 */
export interface GetUserExams200ResponseData {
    /**
     * 
     * @type {Array<ExamDetailsByCourse>}
     * @memberof GetUserExams200ResponseData
     */
    'examsByCourse'?: Array<ExamDetailsByCourse>;
    /**
     * 
     * @type {Array<ExamDetailsByDate>}
     * @memberof GetUserExams200ResponseData
     */
    'examsByDate'?: Array<ExamDetailsByDate>;
    /**
     * 
     * @type {Array<CalendarEventDetails>}
     * @memberof GetUserExams200ResponseData
     */
    'examsForCalendar'?: Array<CalendarEventDetails>;
    /**
     * 
     * @type {string}
     * @memberof GetUserExams200ResponseData
     */
    'dataLoaded'?: string | null;
}
    /**
 * 
 * @export
 * @interface GetUserLinks200Response
 */
export interface GetUserLinks200Response {
    /**
     * 
     * @type {Array<Link>}
     * @memberof GetUserLinks200Response
     */
    'data'?: Array<Link>;
}
    /**
 * 
 * @export
 * @interface GetUserNotifications200Response
 */
export interface GetUserNotifications200Response {
    /**
     * 
     * @type {Array<Notification>}
     * @memberof GetUserNotifications200Response
     */
    'data'?: Array<Notification>;
    /**
     * 
     * @type {number}
     * @memberof GetUserNotifications200Response
     */
    'count'?: number;
}
    /**
 * 
 * @export
 * @interface GetUserShortcuts200Response
 */
export interface GetUserShortcuts200Response {
    /**
     * 
     * @type {Array<Shortcut>}
     * @memberof GetUserShortcuts200Response
     */
    'data'?: Array<Shortcut>;
}
    /**
 * 
 * @export
 * @interface GetUserStudies200Response
 */
export interface GetUserStudies200Response {
    /**
     * 
     * @type {MyStudies}
     * @memberof GetUserStudies200Response
     */
    'data'?: MyStudies;
}
    /**
 * 
 * @export
 * @interface GetUserTodos200Response
 */
export interface GetUserTodos200Response {
    /**
     * 
     * @type {Array<Todo>}
     * @memberof GetUserTodos200Response
     */
    'data'?: Array<Todo>;
}
    /**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    'url'?: string;
    /**
     * The url to the image.
     * @type {string}
     * @memberof Link
     * @deprecated
     */
    'image'?: string;
    /**
     * Heading for the category based in the language set for the user
     * @type {string}
     * @memberof Link
     */
    'category_title'?: string | null;
    /**
     * Key to use for grouping links
     * @type {string}
     * @memberof Link
     */
    'category_id'?: string | null;
}
    /**
 * 
 * @export
 * @interface MyStudies
 */
export interface MyStudies {
    /**
     * 
     * @type {Array<MyStudiesStudyProgramsInner>}
     * @memberof MyStudies
     */
    'study_programs'?: Array<MyStudiesStudyProgramsInner>;
    /**
     * 
     * @type {Array<MyStudiesSubjectDataInner>}
     * @memberof MyStudies
     */
    'subject_data'?: Array<MyStudiesSubjectDataInner>;
}
    /**
 * 
 * @export
 * @interface MyStudiesStudyProgramsInner
 */
export interface MyStudiesStudyProgramsInner {
    /**
     * 
     * @type {string}
     * @memberof MyStudiesStudyProgramsInner
     */
    'program_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MyStudiesStudyProgramsInner
     */
    'program_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MyStudiesStudyProgramsInner
     */
    'faculty_name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MyStudiesStudyProgramsInner
     */
    'study_program_link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MyStudiesStudyProgramsInner
     */
    'faculty_link'?: string | null;
}
    /**
 * 
 * @export
 * @interface MyStudiesSubjectDataInner
 */
export interface MyStudiesSubjectDataInner {
    /**
     * 
     * @type {string}
     * @memberof MyStudiesSubjectDataInner
     */
    'subject_code'?: string;
    /**
     * 
     * @type {string}
     * @memberof MyStudiesSubjectDataInner
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof MyStudiesSubjectDataInner
     */
    'uib_link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MyStudiesSubjectDataInner
     */
    'mitt_uib_link'?: string | null;
}
    /**
 * 
 * @export
 * @interface NewPredefinedShortcut
 */
export interface NewPredefinedShortcut {
    /**
     * ID of the predefined link
     * @type {number}
     * @memberof NewPredefinedShortcut
     */
    'id'?: number;
}
    /**
 * 
 * @export
 * @interface NewShortcut
 */
export interface NewShortcut {
    /**
     * An URL that the user has entered
     * @type {string}
     * @memberof NewShortcut
     */
    'url'?: string;
    /**
     * Title the user has given to the link
     * @type {string}
     * @memberof NewShortcut
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NewShortcut
     */
    'color'?: string;
}
    /**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id'?: string;
    /**
     * 
     * @type {NotificationData}
     * @memberof Notification
     */
    'data'?: NotificationData;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'created_at'?: string;
    /**
     * Whether the notification has been
     * @type {boolean}
     * @memberof Notification
     */
    'unread'?: boolean;
}
    /**
 * 
 * @export
 * @interface NotificationData
 */
export interface NotificationData {
    /**
     * 
     * @type {string}
     * @memberof NotificationData
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationData
     */
    'body'?: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationData
     */
    'url'?: string;
}
    /**
 * @type PostUserShortcutsRequest
 * @export
 */
export type PostUserShortcutsRequest = NewPredefinedShortcut | NewShortcut;

    /**
 * 
 * @export
 * @interface PredefinedShortcut
 */
export interface PredefinedShortcut {
    /**
     * 
     * @type {number}
     * @memberof PredefinedShortcut
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof PredefinedShortcut
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof PredefinedShortcut
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PredefinedShortcut
     */
    'description'?: string | null;
    /**
     * Optional icon to be displayed for the shortcut
     * @type {string}
     * @memberof PredefinedShortcut
     */
    'icon'?: string | null;
    /**
     * The category this predefined shortcut belongs to
     * @type {string}
     * @memberof PredefinedShortcut
     */
    'category'?: string | null;
    /**
     * Key to use for grouping predefined shortcuts
     * @type {string}
     * @memberof PredefinedShortcut
     */
    'category_id'?: string | null;
}
    /**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'language'?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    'studies'?: string;
    /**
     * If true the user is an admin that can access the admin interface
     * @type {boolean}
     * @memberof Profile
     */
    'is_dash_admin'?: boolean | null;
    /**
     * If true the user is allowed to impersonate other users
     * @type {boolean}
     * @memberof Profile
     */
    'can_impersonate'?: boolean | null;
    /**
     * Username of user that is being impersonated is impersonating is being done. If impersonating is not active this field is not included in the response
     * @type {string}
     * @memberof Profile
     */
    'is_impersonating'?: string | null;
    /**
     * Whether the frontend should show the push notification functionality. A null value should be interpreted to mean that the push notification functionality should be turned on
     * @type {boolean}
     * @memberof Profile
     */
    'is_push_enabled'?: boolean | null;
    /**
     * If the user is a KMD user a list of their supervisors will be delivered here.
     * @type {Array<ProfileSupervisorsInner>}
     * @memberof Profile
     */
    'supervisors'?: Array<ProfileSupervisorsInner>;
}
    /**
 * 
 * @export
 * @interface ProfileSupervisorsInner
 */
export interface ProfileSupervisorsInner {
    /**
     * 
     * @type {string}
     * @memberof ProfileSupervisorsInner
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileSupervisorsInner
     */
    'type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileSupervisorsInner
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileSupervisorsInner
     */
    'mobile_number'?: string | null;
}
    /**
 * 
 * @export
 * @interface PushSubscription
 */
export interface PushSubscription {
    /**
     * The URL endpoint for the subscription
     * @type {string}
     * @memberof PushSubscription
     */
    'endpoint': string;
    /**
     * Date when the subscription expires (not required).
     * @type {string}
     * @memberof PushSubscription
     */
    'expirationTime'?: string | null;
    /**
     * 
     * @type {PushSubscriptionKeys}
     * @memberof PushSubscription
     */
    'keys': PushSubscriptionKeys;
}
    /**
 * 
 * @export
 * @interface PushSubscriptionKeys
 */
export interface PushSubscriptionKeys {
    /**
     * Public key for encryption
     * @type {string}
     * @memberof PushSubscriptionKeys
     */
    'p256dh'?: string;
    /**
     * Authentication secret
     * @type {string}
     * @memberof PushSubscriptionKeys
     */
    'auth'?: string;
}
    /**
 * 
 * @export
 * @interface PutCalendarGroupRequest
 */
export interface PutCalendarGroupRequest {
    /**
     * 
     * @type {string}
     * @memberof PutCalendarGroupRequest
     */
    'color'?: string;
}
    /**
 * 
 * @export
 * @interface Shortcut
 */
export interface Shortcut {
    /**
     * 
     * @type {number}
     * @memberof Shortcut
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Shortcut
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Shortcut
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Shortcut
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Shortcut
     */
    'color'?: string | null;
    /**
     * Optional icon to be displayed for the shortcut
     * @type {string}
     * @memberof Shortcut
     */
    'icon'?: string | null;
    /**
     * Index where shortcut should be placed in the layout
     * @type {number}
     * @memberof Shortcut
     */
    'shortcut_index'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Shortcut
     */
    'predefined_id'?: number | null;
}
    /**
 * 
 * @export
 * @interface Todo
 */
export interface Todo {
    /**
     * 
     * @type {number}
     * @memberof Todo
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    'completed_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    'deadline'?: string | null;
    /**
     * Whether the todo is mandatory.
     * @type {boolean}
     * @memberof Todo
     */
    'obligatory'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof Todo
     */
    'important'?: string | null;
    /**
     * In which position in the list it should be shown.
     * @type {number}
     * @memberof Todo
     */
    'position'?: number;
    /**
     * List of categories this item belongs to. The list can be empty. The title will be in the language the user has specified in the application.
     * @type {Array<TodoCategoriesInner>}
     * @memberof Todo
     */
    'categories'?: Array<TodoCategoriesInner>;
    /**
     * If present and set this means that the user cannot change the status of the item, it is being updated by the backend
     * @type {boolean}
     * @memberof Todo
     */
    'automatic'?: boolean | null;
    /**
     * This property is used for determining where the checklist should be displayed in the frontend
     * @type {Array<string>}
     * @memberof Todo
     */
    'areas'?: Array<string> | null;
}
    /**
 * 
 * @export
 * @interface TodoCategoriesInner
 */
export interface TodoCategoriesInner {
    /**
     * 
     * @type {number}
     * @memberof TodoCategoriesInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof TodoCategoriesInner
     */
    'title'?: string;
}
    /**
 * 
 * @export
 * @interface UpdateUserShortcutRequest
 */
export interface UpdateUserShortcutRequest {
    /**
     * 
     * @type {NewShortcut}
     * @memberof UpdateUserShortcutRequest
     */
    'data'?: NewShortcut;
}
  
    /**
 * CalendarApi - axios parameter creator
 * @export
 */
export const CalendarApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Returns the calendar groups and colors for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/calendar/groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Schedule Calendar events examples of different processed types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleExample: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dash/schedule/example`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Schedule Calendar events for the logged in user (Timeplan)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEvents: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/calendar/schedule`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Schedule Calendar events for the logged in user between two dates (Timeplan)
         * @param {string} startDate The start date for searching in format YYYYmmdd.
         * @param {string} endDate The end date for searching in format YYYYmmdd.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEventsPerPeriod: async (startDate: string, endDate: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getUserEventsPerPeriod', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getUserEventsPerPeriod', 'endDate', endDate)
            const localVarPath = `/api/v1/self/calendar/schedule/{start_date}/{end_date}`
                .replace(`{${"start_date"}}`, encodeURIComponent(String(startDate)))
                .replace(`{${"end_date"}}`, encodeURIComponent(String(endDate)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Change the color of a group.
         * @param {number} id The id of the group
         * @param {PutCalendarGroupRequest} putCalendarGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCalendarGroup: async (id: number, putCalendarGroupRequest: PutCalendarGroupRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCalendarGroup', 'id', id)
            // verify required parameter 'putCalendarGroupRequest' is not null or undefined
            assertParamExists('putCalendarGroup', 'putCalendarGroupRequest', putCalendarGroupRequest)
            const localVarPath = `/api/v1/self/calendar/groups/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putCalendarGroupRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalendarApi - functional programming interface
 * @export
 */
export const CalendarApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalendarApiAxiosParamCreator(configuration)
    return {
        /**
         * Returns the calendar groups and colors for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCalendarGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCalendarGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCalendarGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Schedule Calendar events examples of different processed types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleExample(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserEvents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleExample(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Schedule Calendar events for the logged in user (Timeplan)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserEvents(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserEvents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserEvents(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Schedule Calendar events for the logged in user between two dates (Timeplan)
         * @param {string} startDate The start date for searching in format YYYYmmdd.
         * @param {string} endDate The end date for searching in format YYYYmmdd.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserEventsPerPeriod(startDate: string, endDate: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserEvents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserEventsPerPeriod(startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Change the color of a group.
         * @param {number} id The id of the group
         * @param {PutCalendarGroupRequest} putCalendarGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCalendarGroup(id: number, putCalendarGroupRequest: PutCalendarGroupRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCalendarGroups200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCalendarGroup(id, putCalendarGroupRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalendarApi - factory interface
 * @export
 */
export const CalendarApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalendarApiFp(configuration)
    return {
        /**
         * Returns the calendar groups and colors for the current user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCalendarGroups(options?: any): AxiosPromise<GetCalendarGroups200Response> {
            return localVarFp.getCalendarGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * Schedule Calendar events examples of different processed types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleExample(options?: any): AxiosPromise<GetUserEvents200Response> {
            return localVarFp.getScheduleExample(options).then((request) => request(axios, basePath));
        },
        /**
         * Schedule Calendar events for the logged in user (Timeplan)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEvents(options?: any): AxiosPromise<GetUserEvents200Response> {
            return localVarFp.getUserEvents(options).then((request) => request(axios, basePath));
        },
        /**
         * Schedule Calendar events for the logged in user between two dates (Timeplan)
         * @param {string} startDate The start date for searching in format YYYYmmdd.
         * @param {string} endDate The end date for searching in format YYYYmmdd.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserEventsPerPeriod(startDate: string, endDate: string, options?: any): AxiosPromise<GetUserEvents200Response> {
            return localVarFp.getUserEventsPerPeriod(startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Change the color of a group.
         * @param {number} id The id of the group
         * @param {PutCalendarGroupRequest} putCalendarGroupRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCalendarGroup(id: number, putCalendarGroupRequest: PutCalendarGroupRequest, options?: any): AxiosPromise<GetCalendarGroups200Response> {
            return localVarFp.putCalendarGroup(id, putCalendarGroupRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CalendarApi - object-oriented interface
 * @export
 * @class CalendarApi
 * @extends {BaseAPI}
 */
export class CalendarApi extends BaseAPI {
    /**
     * Returns the calendar groups and colors for the current user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getCalendarGroups(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getCalendarGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Schedule Calendar events examples of different processed types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getScheduleExample(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getScheduleExample(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Schedule Calendar events for the logged in user (Timeplan)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUserEvents(options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUserEvents(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Schedule Calendar events for the logged in user between two dates (Timeplan)
     * @param {string} startDate The start date for searching in format YYYYmmdd.
     * @param {string} endDate The end date for searching in format YYYYmmdd.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public getUserEventsPerPeriod(startDate: string, endDate: string, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).getUserEventsPerPeriod(startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Change the color of a group.
     * @param {number} id The id of the group
     * @param {PutCalendarGroupRequest} putCalendarGroupRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalendarApi
     */
    public putCalendarGroup(id: number, putCalendarGroupRequest: PutCalendarGroupRequest, options?: AxiosRequestConfig) {
        return CalendarApiFp(this.configuration).putCalendarGroup(id, putCalendarGroupRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * ConfirmationsApi - axios parameter creator
 * @export
 */
export const ConfirmationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get confirmations for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConfirmations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/confirmations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfirmationsApi - functional programming interface
 * @export
 */
export const ConfirmationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfirmationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get confirmations for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserConfirmations(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserConfirmations200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserConfirmations(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfirmationsApi - factory interface
 * @export
 */
export const ConfirmationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfirmationsApiFp(configuration)
    return {
        /**
         * Get confirmations for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConfirmations(options?: any): AxiosPromise<GetUserConfirmations200Response> {
            return localVarFp.getUserConfirmations(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfirmationsApi - object-oriented interface
 * @export
 * @class ConfirmationsApi
 * @extends {BaseAPI}
 */
export class ConfirmationsApi extends BaseAPI {
    /**
     * Get confirmations for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfirmationsApi
     */
    public getUserConfirmations(options?: AxiosRequestConfig) {
        return ConfirmationsApiFp(this.configuration).getUserConfirmations(options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * ExamApi - axios parameter creator
 * @export
 */
export const ExamApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Exams for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserExams: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/exams`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExamApi - functional programming interface
 * @export
 */
export const ExamApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExamApiAxiosParamCreator(configuration)
    return {
        /**
         * Exams for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserExams(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserExams200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserExams(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExamApi - factory interface
 * @export
 */
export const ExamApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExamApiFp(configuration)
    return {
        /**
         * Exams for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserExams(options?: any): AxiosPromise<GetUserExams200Response> {
            return localVarFp.getUserExams(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExamApi - object-oriented interface
 * @export
 * @class ExamApi
 * @extends {BaseAPI}
 */
export class ExamApi extends BaseAPI {
    /**
     * Exams for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamApi
     */
    public getUserExams(options?: AxiosRequestConfig) {
        return ExamApiFp(this.configuration).getUserExams(options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * ExamplesApi - axios parameter creator
 * @export
 */
export const ExamplesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Schedule Calendar events examples of different processed types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleExample: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dash/schedule/example`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExamplesApi - functional programming interface
 * @export
 */
export const ExamplesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExamplesApiAxiosParamCreator(configuration)
    return {
        /**
         * Schedule Calendar events examples of different processed types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getScheduleExample(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserEvents200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getScheduleExample(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExamplesApi - factory interface
 * @export
 */
export const ExamplesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExamplesApiFp(configuration)
    return {
        /**
         * Schedule Calendar events examples of different processed types.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getScheduleExample(options?: any): AxiosPromise<GetUserEvents200Response> {
            return localVarFp.getScheduleExample(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ExamplesApi - object-oriented interface
 * @export
 * @class ExamplesApi
 * @extends {BaseAPI}
 */
export class ExamplesApi extends BaseAPI {
    /**
     * Schedule Calendar events examples of different processed types.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamplesApi
     */
    public getScheduleExample(options?: AxiosRequestConfig) {
        return ExamplesApiFp(this.configuration).getScheduleExample(options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * ImpersonateApi - axios parameter creator
 * @export
 */
export const ImpersonateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Impersonate a user
         * @param {string} username Username of user to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startImpersonate: async (username: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('startImpersonate', 'username', username)
            const localVarPath = `/api/v1/admin/impersonate/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stop impersonating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopImpersonate: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/admin/impersonate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImpersonateApi - functional programming interface
 * @export
 */
export const ImpersonateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImpersonateApiAxiosParamCreator(configuration)
    return {
        /**
         * Impersonate a user
         * @param {string} username Username of user to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startImpersonate(username: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startImpersonate(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stop impersonating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stopImpersonate(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stopImpersonate(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ImpersonateApi - factory interface
 * @export
 */
export const ImpersonateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImpersonateApiFp(configuration)
    return {
        /**
         * Impersonate a user
         * @param {string} username Username of user to impersonate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startImpersonate(username: string, options?: any): AxiosPromise<void> {
            return localVarFp.startImpersonate(username, options).then((request) => request(axios, basePath));
        },
        /**
         * Stop impersonating
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stopImpersonate(options?: any): AxiosPromise<void> {
            return localVarFp.stopImpersonate(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImpersonateApi - object-oriented interface
 * @export
 * @class ImpersonateApi
 * @extends {BaseAPI}
 */
export class ImpersonateApi extends BaseAPI {
    /**
     * Impersonate a user
     * @param {string} username Username of user to impersonate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonateApi
     */
    public startImpersonate(username: string, options?: AxiosRequestConfig) {
        return ImpersonateApiFp(this.configuration).startImpersonate(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Stop impersonating
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImpersonateApi
     */
    public stopImpersonate(options?: AxiosRequestConfig) {
        return ImpersonateApiFp(this.configuration).stopImpersonate(options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * LinksApi - axios parameter creator
 * @export
 */
export const LinksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the list of common communication channels
         * @param {string} [lang] Language to retrieve the channels for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels: async (lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/channels`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of external links
         * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks: async (lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of external links
         * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLinks: async (lang?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/links`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required

            if (lang !== undefined) {
                localVarQueryParameter['lang'] = lang;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LinksApi - functional programming interface
 * @export
 */
export const LinksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LinksApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the list of common communication channels
         * @param {string} [lang] Language to retrieve the channels for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getChannels(lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetChannels200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getChannels(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of external links
         * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLinks(lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserLinks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLinks(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of external links
         * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserLinks(lang?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserLinks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserLinks(lang, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LinksApi - factory interface
 * @export
 */
export const LinksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LinksApiFp(configuration)
    return {
        /**
         * Get the list of common communication channels
         * @param {string} [lang] Language to retrieve the channels for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChannels(lang?: string, options?: any): AxiosPromise<GetChannels200Response> {
            return localVarFp.getChannels(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of external links
         * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLinks(lang?: string, options?: any): AxiosPromise<GetUserLinks200Response> {
            return localVarFp.getLinks(lang, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of external links
         * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserLinks(lang?: string, options?: any): AxiosPromise<GetUserLinks200Response> {
            return localVarFp.getUserLinks(lang, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LinksApi - object-oriented interface
 * @export
 * @class LinksApi
 * @extends {BaseAPI}
 */
export class LinksApi extends BaseAPI {
    /**
     * Get the list of common communication channels
     * @param {string} [lang] Language to retrieve the channels for. By default the language is what the user has specified in his profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public getChannels(lang?: string, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).getChannels(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of external links
     * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public getLinks(lang?: string, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).getLinks(lang, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of external links
     * @param {string} [lang] Language to retrieve the links for. By default the language is what the user has specified in his profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LinksApi
     */
    public getUserLinks(lang?: string, options?: AxiosRequestConfig) {
        return LinksApiFp(this.configuration).getUserLinks(lang, options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get the count of unread notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountUnreadUserNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/notifications/unread/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the all the unread notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadUserNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/notifications/unread`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the notification if the logged in user is the owner
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotification: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserNotification', 'id', id)
            const localVarPath = `/api/v1/self/notifications/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the all the notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/notifications`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks the notification as read
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationMarkRead: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putNotificationMarkRead', 'id', id)
            const localVarPath = `/api/v1/self/notifications/{id}/mark_read`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Marks the notification as unread
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationMarkUnread: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putNotificationMarkUnread', 'id', id)
            const localVarPath = `/api/v1/self/notifications/{id}/mark_unread`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark all notifications as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationsAllRead: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/notifications/mark_all_read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * Get the count of unread notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCountUnreadUserNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCountUnreadUserNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCountUnreadUserNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the all the unread notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUnreadUserNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUnreadUserNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the notification if the logged in user is the owner
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotification(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotification(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the all the notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserNotifications(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserNotifications(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Marks the notification as read
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotificationMarkRead(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotificationMarkRead(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Marks the notification as unread
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotificationMarkUnread(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Notification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotificationMarkUnread(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark all notifications as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putNotificationsAllRead(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserNotifications200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putNotificationsAllRead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * Get the count of unread notifications.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCountUnreadUserNotifications(options?: any): AxiosPromise<GetCountUnreadUserNotifications200Response> {
            return localVarFp.getCountUnreadUserNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the all the unread notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUnreadUserNotifications(options?: any): AxiosPromise<GetUserNotifications200Response> {
            return localVarFp.getUnreadUserNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * Returns the notification if the logged in user is the owner
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotification(id: string, options?: any): AxiosPromise<Notification> {
            return localVarFp.getUserNotification(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the all the notifications for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserNotifications(options?: any): AxiosPromise<GetUserNotifications200Response> {
            return localVarFp.getUserNotifications(options).then((request) => request(axios, basePath));
        },
        /**
         * Marks the notification as read
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationMarkRead(id: string, options?: any): AxiosPromise<Notification> {
            return localVarFp.putNotificationMarkRead(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Marks the notification as unread
         * @param {string} id The id of the notification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationMarkUnread(id: string, options?: any): AxiosPromise<Notification> {
            return localVarFp.putNotificationMarkUnread(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark all notifications as read
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putNotificationsAllRead(options?: any): AxiosPromise<GetUserNotifications200Response> {
            return localVarFp.putNotificationsAllRead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * Get the count of unread notifications.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getCountUnreadUserNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getCountUnreadUserNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the all the unread notifications for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUnreadUserNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getUnreadUserNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns the notification if the logged in user is the owner
     * @param {string} id The id of the notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUserNotification(id: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getUserNotification(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the all the notifications for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getUserNotifications(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).getUserNotifications(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks the notification as read
     * @param {string} id The id of the notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public putNotificationMarkRead(id: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).putNotificationMarkRead(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Marks the notification as unread
     * @param {string} id The id of the notification.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public putNotificationMarkUnread(id: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).putNotificationMarkUnread(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark all notifications as read
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public putNotificationsAllRead(options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).putNotificationsAllRead(options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return the data of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Sets the locale for the logged in user
         * @param {string} locale The locale to set for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLocale: async (locale: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locale' is not null or undefined
            assertParamExists('setLocale', 'locale', locale)
            const localVarPath = `/api/v1/self/profile/locale/{locale}`
                .replace(`{${"locale"}}`, encodeURIComponent(String(locale)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Return the data of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProfile200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Sets the locale for the logged in user
         * @param {string} locale The locale to set for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setLocale(locale: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setLocale(locale, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * Return the data of the user.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<GetProfile200Response> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Sets the locale for the logged in user
         * @param {string} locale The locale to set for the user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setLocale(locale: string, options?: any): AxiosPromise<void> {
            return localVarFp.setLocale(locale, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI {
    /**
     * Return the data of the user.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Sets the locale for the logged in user
     * @param {string} locale The locale to set for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public setLocale(locale: string, options?: AxiosRequestConfig) {
        return ProfileApiFp(this.configuration).setLocale(locale, options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * PushApi - axios parameter creator
 * @export
 */
export const PushApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Subscribe from push notifications services
         * @param {PushSubscription} pushSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SelfPushSubscribePost: async (pushSubscription: PushSubscription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pushSubscription' is not null or undefined
            assertParamExists('apiV1SelfPushSubscribePost', 'pushSubscription', pushSubscription)
            const localVarPath = `/api/v1/self/push/subscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushSubscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all the subscriptions for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SelfPushSubscriptionsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/push/subscriptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unsubscribe from push notifications services
         * @param {PushSubscription} pushSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SelfPushUnsubscribePost: async (pushSubscription: PushSubscription, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pushSubscription' is not null or undefined
            assertParamExists('apiV1SelfPushUnsubscribePost', 'pushSubscription', pushSubscription)
            const localVarPath = `/api/v1/self/push/unsubscribe`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pushSubscription, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PushApi - functional programming interface
 * @export
 */
export const PushApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PushApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Subscribe from push notifications services
         * @param {PushSubscription} pushSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SelfPushSubscribePost(pushSubscription: PushSubscription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SelfPushSubscribePost(pushSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List all the subscriptions for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SelfPushSubscriptionsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1SelfPushSubscriptionsGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SelfPushSubscriptionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Unsubscribe from push notifications services
         * @param {PushSubscription} pushSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1SelfPushUnsubscribePost(pushSubscription: PushSubscription, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1SelfPushUnsubscribePost(pushSubscription, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PushApi - factory interface
 * @export
 */
export const PushApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PushApiFp(configuration)
    return {
        /**
         * 
         * @summary Subscribe from push notifications services
         * @param {PushSubscription} pushSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SelfPushSubscribePost(pushSubscription: PushSubscription, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SelfPushSubscribePost(pushSubscription, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all the subscriptions for a user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SelfPushSubscriptionsGet(options?: any): AxiosPromise<ApiV1SelfPushSubscriptionsGet200Response> {
            return localVarFp.apiV1SelfPushSubscriptionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unsubscribe from push notifications services
         * @param {PushSubscription} pushSubscription 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1SelfPushUnsubscribePost(pushSubscription: PushSubscription, options?: any): AxiosPromise<void> {
            return localVarFp.apiV1SelfPushUnsubscribePost(pushSubscription, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PushApi - object-oriented interface
 * @export
 * @class PushApi
 * @extends {BaseAPI}
 */
export class PushApi extends BaseAPI {
    /**
     * 
     * @summary Subscribe from push notifications services
     * @param {PushSubscription} pushSubscription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApi
     */
    public apiV1SelfPushSubscribePost(pushSubscription: PushSubscription, options?: AxiosRequestConfig) {
        return PushApiFp(this.configuration).apiV1SelfPushSubscribePost(pushSubscription, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all the subscriptions for a user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApi
     */
    public apiV1SelfPushSubscriptionsGet(options?: AxiosRequestConfig) {
        return PushApiFp(this.configuration).apiV1SelfPushSubscriptionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unsubscribe from push notifications services
     * @param {PushSubscription} pushSubscription 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PushApi
     */
    public apiV1SelfPushUnsubscribePost(pushSubscription: PushSubscription, options?: AxiosRequestConfig) {
        return PushApiFp(this.configuration).apiV1SelfPushUnsubscribePost(pushSubscription, options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * ShortcutsApi - axios parameter creator
 * @export
 */
export const ShortcutsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id The ID of the link to delete from the user\&#39;s list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortcut: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteShortcut', 'id', id)
            const localVarPath = `/api/v1/self/shortcuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of predefined shortcuts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredefinedShortcuts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/predefinedshortcuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the list of shortcuts to be displayed in the user\'s list. Where applicable the texts will be in the language the user has selected in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserShortcuts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/shortcuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a user defined or a predefined shortcut.
         * @param {PostUserShortcutsRequest} [postUserShortcutsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserShortcuts: async (postUserShortcutsRequest?: PostUserShortcutsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/shortcuts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postUserShortcutsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates the index used for ordering the shortcuts. Only the items given in the input will have their indices updated
         * @param {Array<FavoritesOrderInner>} favoritesOrderInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrder: async (favoritesOrderInner: Array<FavoritesOrderInner>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'favoritesOrderInner' is not null or undefined
            assertParamExists('setOrder', 'favoritesOrderInner', favoritesOrderInner)
            const localVarPath = `/api/v1/self/shortcuts/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(favoritesOrderInner, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updated a user defined shortcut
         * @param {number} id The ID of the shortcut to update
         * @param {UpdateUserShortcutRequest} [updateUserShortcutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserShortcut: async (id: number, updateUserShortcutRequest?: UpdateUserShortcutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateUserShortcut', 'id', id)
            const localVarPath = `/api/v1/self/shortcuts/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserShortcutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShortcutsApi - functional programming interface
 * @export
 */
export const ShortcutsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShortcutsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id The ID of the link to delete from the user\&#39;s list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShortcut(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShortcut(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of predefined shortcuts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPredefinedShortcuts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPredefinedShortcuts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPredefinedShortcuts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the list of shortcuts to be displayed in the user\'s list. Where applicable the texts will be in the language the user has selected in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserShortcuts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserShortcuts200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserShortcuts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a user defined or a predefined shortcut.
         * @param {PostUserShortcutsRequest} [postUserShortcutsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserShortcuts(postUserShortcutsRequest?: PostUserShortcutsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserShortcuts(postUserShortcutsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates the index used for ordering the shortcuts. Only the items given in the input will have their indices updated
         * @param {Array<FavoritesOrderInner>} favoritesOrderInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setOrder(favoritesOrderInner: Array<FavoritesOrderInner>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setOrder(favoritesOrderInner, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updated a user defined shortcut
         * @param {number} id The ID of the shortcut to update
         * @param {UpdateUserShortcutRequest} [updateUserShortcutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserShortcut(id: number, updateUserShortcutRequest?: UpdateUserShortcutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserShortcut(id, updateUserShortcutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ShortcutsApi - factory interface
 * @export
 */
export const ShortcutsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShortcutsApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id The ID of the link to delete from the user\&#39;s list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShortcut(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteShortcut(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of predefined shortcuts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPredefinedShortcuts(options?: any): AxiosPromise<GetPredefinedShortcuts200Response> {
            return localVarFp.getPredefinedShortcuts(options).then((request) => request(axios, basePath));
        },
        /**
         * Get the list of shortcuts to be displayed in the user\'s list. Where applicable the texts will be in the language the user has selected in his profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserShortcuts(options?: any): AxiosPromise<GetUserShortcuts200Response> {
            return localVarFp.getUserShortcuts(options).then((request) => request(axios, basePath));
        },
        /**
         * Add a user defined or a predefined shortcut.
         * @param {PostUserShortcutsRequest} [postUserShortcutsRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserShortcuts(postUserShortcutsRequest?: PostUserShortcutsRequest, options?: any): AxiosPromise<number> {
            return localVarFp.postUserShortcuts(postUserShortcutsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates the index used for ordering the shortcuts. Only the items given in the input will have their indices updated
         * @param {Array<FavoritesOrderInner>} favoritesOrderInner 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setOrder(favoritesOrderInner: Array<FavoritesOrderInner>, options?: any): AxiosPromise<void> {
            return localVarFp.setOrder(favoritesOrderInner, options).then((request) => request(axios, basePath));
        },
        /**
         * Updated a user defined shortcut
         * @param {number} id The ID of the shortcut to update
         * @param {UpdateUserShortcutRequest} [updateUserShortcutRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateUserShortcut(id: number, updateUserShortcutRequest?: UpdateUserShortcutRequest, options?: any): AxiosPromise<number> {
            return localVarFp.updateUserShortcut(id, updateUserShortcutRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShortcutsApi - object-oriented interface
 * @export
 * @class ShortcutsApi
 * @extends {BaseAPI}
 */
export class ShortcutsApi extends BaseAPI {
    /**
     * 
     * @param {number} id The ID of the link to delete from the user\&#39;s list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortcutsApi
     */
    public deleteShortcut(id: number, options?: AxiosRequestConfig) {
        return ShortcutsApiFp(this.configuration).deleteShortcut(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of predefined shortcuts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortcutsApi
     */
    public getPredefinedShortcuts(options?: AxiosRequestConfig) {
        return ShortcutsApiFp(this.configuration).getPredefinedShortcuts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the list of shortcuts to be displayed in the user\'s list. Where applicable the texts will be in the language the user has selected in his profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortcutsApi
     */
    public getUserShortcuts(options?: AxiosRequestConfig) {
        return ShortcutsApiFp(this.configuration).getUserShortcuts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a user defined or a predefined shortcut.
     * @param {PostUserShortcutsRequest} [postUserShortcutsRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortcutsApi
     */
    public postUserShortcuts(postUserShortcutsRequest?: PostUserShortcutsRequest, options?: AxiosRequestConfig) {
        return ShortcutsApiFp(this.configuration).postUserShortcuts(postUserShortcutsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates the index used for ordering the shortcuts. Only the items given in the input will have their indices updated
     * @param {Array<FavoritesOrderInner>} favoritesOrderInner 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortcutsApi
     */
    public setOrder(favoritesOrderInner: Array<FavoritesOrderInner>, options?: AxiosRequestConfig) {
        return ShortcutsApiFp(this.configuration).setOrder(favoritesOrderInner, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updated a user defined shortcut
     * @param {number} id The ID of the shortcut to update
     * @param {UpdateUserShortcutRequest} [updateUserShortcutRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShortcutsApi
     */
    public updateUserShortcut(id: number, updateUserShortcutRequest?: UpdateUserShortcutRequest, options?: AxiosRequestConfig) {
        return ShortcutsApiFp(this.configuration).updateUserShortcut(id, updateUserShortcutRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * StudiesApi - axios parameter creator
 * @export
 */
export const StudiesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Gets the contents to display on the \"Min studiehverdag\" page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStudies: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/mystudies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudiesApi - functional programming interface
 * @export
 */
export const StudiesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StudiesApiAxiosParamCreator(configuration)
    return {
        /**
         * Gets the contents to display on the \"Min studiehverdag\" page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserStudies(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserStudies200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserStudies(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StudiesApi - factory interface
 * @export
 */
export const StudiesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StudiesApiFp(configuration)
    return {
        /**
         * Gets the contents to display on the \"Min studiehverdag\" page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserStudies(options?: any): AxiosPromise<GetUserStudies200Response> {
            return localVarFp.getUserStudies(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StudiesApi - object-oriented interface
 * @export
 * @class StudiesApi
 * @extends {BaseAPI}
 */
export class StudiesApi extends BaseAPI {
    /**
     * Gets the contents to display on the \"Min studiehverdag\" page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public getUserStudies(options?: AxiosRequestConfig) {
        return StudiesApiFp(this.configuration).getUserStudies(options).then((request) => request(this.axios, this.basePath));
    }
}

  
    /**
 * TodosApi - axios parameter creator
 * @export
 */
export const TodosApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * TODO items for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTodos: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/self/todos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark a todo item as completed or incomplete.
         * @param {number} id The status of the todo item.
         * @param {string} state The id of the todo item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTodoState: async (id: number, state: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('setTodoState', 'id', id)
            // verify required parameter 'state' is not null or undefined
            assertParamExists('setTodoState', 'state', state)
            const localVarPath = `/api/v1/self/todos/{id}/completed/{state}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"state"}}`, encodeURIComponent(String(state)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication cookieAuth required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TodosApi - functional programming interface
 * @export
 */
export const TodosApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TodosApiAxiosParamCreator(configuration)
    return {
        /**
         * TODO items for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserTodos(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetUserTodos200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserTodos(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark a todo item as completed or incomplete.
         * @param {number} id The status of the todo item.
         * @param {string} state The id of the todo item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setTodoState(id: number, state: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setTodoState(id, state, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TodosApi - factory interface
 * @export
 */
export const TodosApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TodosApiFp(configuration)
    return {
        /**
         * TODO items for the logged in user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserTodos(options?: any): AxiosPromise<GetUserTodos200Response> {
            return localVarFp.getUserTodos(options).then((request) => request(axios, basePath));
        },
        /**
         * Mark a todo item as completed or incomplete.
         * @param {number} id The status of the todo item.
         * @param {string} state The id of the todo item.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTodoState(id: number, state: string, options?: any): AxiosPromise<void> {
            return localVarFp.setTodoState(id, state, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TodosApi - object-oriented interface
 * @export
 * @class TodosApi
 * @extends {BaseAPI}
 */
export class TodosApi extends BaseAPI {
    /**
     * TODO items for the logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public getUserTodos(options?: AxiosRequestConfig) {
        return TodosApiFp(this.configuration).getUserTodos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark a todo item as completed or incomplete.
     * @param {number} id The status of the todo item.
     * @param {string} state The id of the todo item.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TodosApi
     */
    public setTodoState(id: number, state: string, options?: AxiosRequestConfig) {
        return TodosApiFp(this.configuration).setTodoState(id, state, options).then((request) => request(this.axios, this.basePath));
    }
}

  
