import { SvgIcon, SvgIconProps } from '@mui/material'

import {
  Arrow_Down_Icon,
  Arrow_Right_Icon,
  Calendar_Icon,
  Checkbox_Checked_Icon,
  Checkbox_UnChecked_Icon,
  Chevron_Down_Icon,
  Close_Icon,
  External_Link_Icon,
  Facebook_Icon,
  Grid_Icon,
  Help_Icon,
  Home_Icon,
  Info_Icon,
  Instagram_Icon,
  Lock_Icon,
  LogIn_Icon,
  LogOut_Icon,
  Mail_Icon,
  Mask_Icon,
  Menu_Icon,
  Monitor_Icon,
  Notepad_Icon,
  Notification_Bell,
  Twitter_Icon,
  Youtube_Icon,
} from './icons'
import { IconVariants } from './types'

const getUibIcon = (icon: IconVariants) => {
  switch (icon) {
    case 'arrow_down': {
      return <Arrow_Down_Icon />
    }
    case 'arrow_right': {
      return <Arrow_Right_Icon />
    }
    case 'calendar': {
      return <Calendar_Icon />
    }
    case 'checkbox_checked': {
      return <Checkbox_Checked_Icon />
    }
    case 'checkbox_unchecked': {
      return <Checkbox_UnChecked_Icon />
    }
    case 'chevron_down': {
      return <Chevron_Down_Icon />
    }
    case 'close': {
      return <Close_Icon />
    }
    case 'external_link': {
      return <External_Link_Icon />
    }
    case 'facebook': {
      return <Facebook_Icon />
    }
    case 'grid': {
      return <Grid_Icon />
    }
    case 'help': {
      return <Help_Icon />
    }
    case 'home': {
      return <Home_Icon />
    }
    case 'info': {
      return <Info_Icon />
    }
    case 'instagram': {
      return <Instagram_Icon />
    }
    case 'lock': {
      return <Lock_Icon />
    }
    case 'login': {
      return <LogIn_Icon />
    }
    case 'logout': {
      return <LogOut_Icon />
    }
    case 'notepad': {
      return <Notepad_Icon />
    }
    case 'notification_bell': {
      return <Notification_Bell />
    }
    case 'mail': {
      return <Mail_Icon />
    }
    case 'mask': {
      return <Mask_Icon />
    }
    case 'menu': {
      return <Menu_Icon />
    }
    case 'monitor': {
      return <Monitor_Icon />
    }
    case 'twitter': {
      return <Twitter_Icon />
    }
    case 'youtube': {
      return <Youtube_Icon />
    }
  }
}

interface UibCustomIconProps extends SvgIconProps {
  icon: IconVariants
  colorOverride?: string
}

export const UibCustomIcon = ({ icon, colorOverride, sx, ...rest }: UibCustomIconProps) => {
  return (
    <SvgIcon {...rest} sx={colorOverride ? { ...sx, svg: { path: { fill: colorOverride } } } : sx}>
      {getUibIcon(icon)}
    </SvgIcon>
  )
}
