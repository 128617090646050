import { uibRed200, uibTextWhite } from '@/styles'
import { IconButton, styled } from '@mui/material'

export const StyledNavbarIconButton = styled(IconButton)({
  display: 'flex',
  flexDirection: 'column',
  padding: '0',
  color: uibTextWhite,
  '&:hover': {
    color: uibRed200,
    svg: {
      path: {
        fill: uibRed200,
      },
    },
  },
})
