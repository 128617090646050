import { createContext } from 'react'

import { TRACKED_MATOMO_EVENTS } from './MatomoEvents'

export interface MatomoContextProps {
  trackPageView: (path: string) => void
  trackUser: {
    setUserId: (userId: string) => void
    resetUserId: () => void
  }
  trackEvent: (event: TRACKED_MATOMO_EVENTS) => void
  trackPwaUsage: () => void
}

const matomoContext = createContext<MatomoContextProps | undefined>(undefined)

export default matomoContext
