import { UibCustomIcon } from '@/assets'
import { IconVariants } from '@/assets'
import { useUibLanguage } from '@/language'
import { MaxWidthLayoutBox } from '@/modules/layout/maxWidthGuards'
import { defaultFocusStyleLight, uibRed100, uibRed500, uibRed600 } from '@/styles'
import { Box, Grid, IconButton, Link, Stack, Typography, styled, useTheme } from '@mui/material'

import { SubFooter } from './SubFooter'

const FooterLink = styled(Link)({
  color: uibRed100,
  padding: '2px 0px',
  '&:hover': {
    color: 'white',
  },
  '&:focus-visible': {
    '&:before': defaultFocusStyleLight,
  },
})

export const Footer = () => {
  const theme = useTheme()
  const { langDict } = useUibLanguage()

  interface SocialMediaIconType {
    title: string
    url: string
    icon: IconVariants
  }

  const socialMediaLinks: SocialMediaIconType[] = [
    {
      title: langDict('footer.someTwitter') || '',
      url: 'https://twitter.com/uib/',
      icon: 'twitter',
    },
    {
      title: langDict('footer.someFacebook') || '',
      url: 'https://facebook.com/unibergen/',
      icon: 'facebook',
    },
    {
      title: langDict('footer.someYouTube') || '',
      url: 'https://www.youtube.com/user/UniBergen/',
      icon: 'youtube',
    },
    {
      title: langDict('footer.someInstagram') || '',
      url: 'https://www.instagram.com/unibergen/',
      icon: 'instagram',
    },
  ]

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.main,
          paddingTop: '3rem',
          paddingBottom: '4rem',
        }}>
        <MaxWidthLayoutBox>
          <Grid
            container
            justifyContent={{ xs: 'normal', md: 'space-between' }}
            rowSpacing={{ xs: '2.5rem', sm: '3rem' }}>
            <Grid item xs={12} md={'auto'}>
              <Stack alignItems={'start'}>
                <Typography variant='h3' paddingBottom={0.5}>
                  {langDict('footer.contactTitle')}
                </Typography>
                <FooterLink href='https://www.uib.no/utdanning/50092/kontaktinformasjon'>
                  {langDict('footer.contactLink')}
                </FooterLink>
                <FooterLink href='https://hjelp.uib.no/tas/public/ssp/content/detail/service?unid=5bff3b94a23d4a5bb71a6c11a1fecdab'>
                  {langDict('footer.helpLink')}
                </FooterLink>
              </Stack>
            </Grid>
            <Grid item xs={12} md={'auto'}>
              <Stack alignItems={'start'}>
                <Typography variant='h3' paddingBottom={0.5}>
                  {langDict('footer.visitTitle')}
                </Typography>
                <FooterLink href='https://www.uib.no/utdanning/48613/studieveiledning'>
                  {langDict('footer.visitLink')}
                </FooterLink>
              </Stack>
            </Grid>

            <Grid item xs={12} md={'auto'}>
              <Stack>
                <Typography variant='h3' paddingBottom={1}>
                  {langDict('footer.someTitle')}
                </Typography>
                <Box>
                  {socialMediaLinks.map((item, index) => (
                    <IconButton
                      key={index}
                      disableFocusRipple
                      href={item.url}
                      target='_blank'
                      title={item.title}
                      sx={{
                        backgroundColor: uibRed600,
                        '&:hover': { backgroundColor: uibRed500 },
                        marginRight: '8px',
                      }}>
                      <UibCustomIcon icon={item.icon} colorOverride={theme.palette.text.secondary} fontSize='small' />
                    </IconButton>
                  ))}
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </MaxWidthLayoutBox>
      </Box>
      <SubFooter />
    </>
  )
}
