import { useContext } from 'react'

import UserContext from './UserContext'

const useAuthenticatedUser = () => {
  const context = useContext(UserContext)
  if (!context) throw new Error('useSanctum should only be used inside <UserContextProvider />')
  return context
}

export default useAuthenticatedUser
