////////////
// Utility class for handling subscription and notifications
// READ THIS: https://developer.mozilla.org/en-US/docs/Web/API/PushManager
//////////
import { notificationApiSupported, pushManagerSupported, serviceWorkerSupported } from '../pwaUtils'

type PushSubscriptionResponse = {
  permissionState?: NotificationPermission
  error?: string
  subscription?: PushSubscription
}

/**
 * Check if the API's needed for push notifications are supported.
 * Note that Safari users before macOS v13 and safari v16 cannot get push
 **/
export const pushNotificationSupported = () =>
  serviceWorkerSupported() && pushManagerSupported() && notificationApiSupported()

type PushNotificationState = 'NOT_SUPPORTED' | 'REQUIRES_INSTALLATION' | 'SUPPORTED'

export const getPushNotificationSupport: () => PushNotificationState = () => {
  if (!serviceWorkerSupported() || !notificationApiSupported()) {
    return 'NOT_SUPPORTED'
  }
  if (!pushManagerSupported()) {
    // TODO Task #142 figure out how to differentiate between old/new macOS and ios
    const installationIsSupported = true
    if (!installationIsSupported) {
      return 'NOT_SUPPORTED'
    }
    return 'REQUIRES_INSTALLATION'
  }
  return 'SUPPORTED'
}

export const getPushManager = async () => {
  if (!serviceWorkerSupported() || !pushManagerSupported()) return null

  const registration = await navigator.serviceWorker.getRegistration()
  return registration?.pushManager
}

export const requestNotificationPermissionAndSubscription = async (): Promise<PushSubscriptionResponse> => {
  if (!pushNotificationSupported()) {
    console.debug('[PushUtils] Push Notifications not supported by browser')
    return { permissionState: 'denied' }
  }

  const registration = await navigator.serviceWorker.getRegistration()
  if (registration === undefined) {
    console.error("[PushUtils] Cannot subscribe to push notifications as there isn't an active service-worker")
    return { error: 'FAILED_SUBSCRIBING' }
  }

  // Create new subscription
  try {
    const subscription = await registration.pushManager.subscribe({
      // We will only send notifications which are visible to the user
      userVisibleOnly: true, // IMPORTANT: MUST BE TRUE, otherwise notifications get blocked by chrome)
      applicationServerKey: import.meta.env.VITE_PUSH_NOTIFICATION_APPLICATION_KEY,
    })
    return { permissionState: 'granted', subscription: subscription }
  } catch (err) {
    // There are about 8 different errors that can happen here, but most should be handled
    // https://w3c.github.io/push-api/#dom-pushmanager-subscribe
    console.error('[PushUtils] Failed creating push subscription due to', err)
    const permissionState = Notification.permission
    console.debug('[PushUtils] The users current permissions is', permissionState)
    return { error: 'FAILED_SUBSCRIBING', permissionState: permissionState || 'default' }
  }
}

export const getSubscriptionAsAPIObject = (subscription: PushSubscription) => {
  let subscriptionAsJson = subscription.toJSON()

  if (subscriptionAsJson.keys?.p256dh === undefined) {
    console.error('[PushUtils] Subscription object does not have an p256dh key: ', subscriptionAsJson)
    return null
  }
  if (subscriptionAsJson.keys?.auth === undefined) {
    console.error('[PushUtils] Subscription object does not have auth key', subscriptionAsJson)
    return null
  }
  if (subscriptionAsJson.endpoint === undefined) {
    console.error('[PushUtils] Subscription object lacks an endpoint', subscriptionAsJson)
    return null
  }

  return {
    endpoint: subscriptionAsJson.endpoint,
    keys: {
      auth: subscriptionAsJson.keys.auth,
      p256dh: subscriptionAsJson.keys.p256dh,
    },
    // TODO consider adding expiration time:
    // expirationTime: subscriptionAsJson.expirationTime || ''
  }
}
