import { ReactNode } from 'react'

import { Box, CircularProgress, ListItemIcon, ListItemText, Typography } from '@mui/material'

import { StyledMenuListButton } from './MenuListButton'

export const UserConfigListItem = ({
  label,
  icon,
  onClick,
  loading,
}: {
  label: string
  icon: ReactNode
  onClick: () => void
  loading?: boolean
}) => {
  return (
    <StyledMenuListButton onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText>
        {loading ? (
          <Box display='flex' justifyContent='center'>
            <CircularProgress size='18px' />
          </Box>
        ) : (
          <Typography color='text.secondary' variant='body1'>
            {label}
          </Typography>
        )}
      </ListItemText>
    </StyledMenuListButton>
  )
}
