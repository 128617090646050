import { Breakpoints, ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'
import { inputLabelClasses } from '@mui/material/InputLabel'
import { outlinedInputClasses } from '@mui/material/OutlinedInput'

import { defaultFocusStyleDark, defaultFocusStyleLight } from '../focusStyling'
import { palette, uibRed600, uibRed700, uibRed800, uibRed900 } from '../palette'
import theme from '../theme'

/**
 * This file contains overrides for input elements and their labels.
 *
 * IMPORTANT NOTE: **Some Mui components are composed of several other components**.
 * And example of this is "MuiTextField", which is composed of..
 * - Label using "MuiInputLabel",
 * - Input using "MuiOutlinedInput", "MuiFilledInput", or "MuiInput".
 * - Help text using "FormHelperText"
 *
 * Style changes made in one form component can and will therefore affect many others.
 * When (or if) implementing forms in the future make sure to style the base components first,
 *    since it will be a lot less code then styling them as subcomponents of some other component such as textfield
 *
 * I've left some commented out code in this file to show examples of styling overrides
 **/

interface MuiInputLabel {
  defaultProps?: ComponentsProps['MuiInputLabel']
  styleOverrides?: ComponentsOverrides['MuiInputLabel']
  variants?: ComponentsVariants['MuiInputLabel']
}

const muiInputLabel: MuiInputLabel = {
  styleOverrides: {
    root: {
      color: uibRed700,

      [`&.${inputLabelClasses.shrink}`]: {
        color: uibRed900,
        fontWeight: 'bold',
        // Changing this also requires editing "notchedOutline.fontSize"
        //  .. as the area given to the label also is defined in fontSize there,
        transform: 'translate(14px, -11px) scale(0.9)',
        // transform: 'translate(14px, -9px) scale(0.75)',
      },
      [`&.${inputLabelClasses.error}`]: {
        color: uibRed700,
      },
    },
  },
  // variants: [
  //   {
  //     props: {},
  //     style: {}
  //   }
  // ]
}

interface MuiOutlinedInput {
  defaultProps?: ComponentsProps['MuiOutlinedInput']
  styleOverrides?: ComponentsOverrides['MuiOutlinedInput']
  variants?: ComponentsVariants['MuiOutlinedInput']
}

const muiOutlinedInput: MuiOutlinedInput = {
  styleOverrides: {
    notchedOutline: {
      // The floating label moves into the "notch" which is sized using the fontSize below
      fontSize: '20px', // Changing label font size might require increasing this
    },
    root: {
      // [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
      //   borderColor: 'var(--TextField-brandBorderHoverColor)',
      // },
      // [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
      //   borderColor: 'var(--TextField-brandBorderFocusedColor)',
      // },
    },
  },
}

interface MuiFilledInput {
  defaultProps?: ComponentsProps['MuiFilledInput']
  styleOverrides?: ComponentsOverrides['MuiFilledInput']
  variants?: ComponentsVariants['MuiFilledInput']
}

const muiFilledInput: MuiFilledInput = {
  styleOverrides: {
    root: {
      // '&::before, &::after': {
      //   borderBottom: '2px solid var(--TextField-brandBorderColor)',
      // },
      // '&:hover:not(.Mui-disabled, .Mui-error):before': {
      //   borderBottom: '2px solid var(--TextField-brandBorderHoverColor)',
      // },
      // '&.Mui-focused:after': {
      //   borderBottom: '2px solid var(--TextField-brandBorderFocusedColor)',
      // },
    },
  },
}

interface MuiInput {
  defaultProps?: ComponentsProps['MuiInput']
  styleOverrides?: ComponentsOverrides['MuiInput']
  variants?: ComponentsVariants['MuiInput']
}
// NOTE: muiInput is deprecated - the default input component is "outlinedInput"
const muiInput: MuiInput = {
  styleOverrides: {},
}

interface MuiTextField {
  defaultProps?: ComponentsProps['MuiTextField']
  styleOverrides?: ComponentsOverrides['MuiTextField']
  variants?: ComponentsVariants['MuiTextField']
}

// NOTE: read note in top of this file before editing this component
const muiTextField: MuiTextField = {
  styleOverrides: {
    root: {
      // '--TextField-brandBorderColor': '#E0E3E7',
      // '--TextField-brandBorderHoverColor': '#B2BAC2',
      // '--TextField-brandBorderFocusedColor': '#6F7E8C',
      // '& label.Mui-focused': {
      //   color: 'var(--TextField-brandBorderFocusedColor)',
      // },
      // [`& .${outlinedInputClasses.root}:hover`]
    },
  },
}

export { muiInput, muiFilledInput, muiTextField, muiOutlinedInput, muiInputLabel }
