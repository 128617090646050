import { useCallback, useEffect, useMemo, useState } from 'react'

import { TREET_EVENT_TYPES } from '@/pages/CalendarPage/CalendarComponents/CalendarEvent/CalendarEvent'
import { EnrichedCalendarEvent } from '@/pages/CalendarPage/CalendarUtils'

export type FilterableProperties = {
  courses: Record<string, boolean>
  eventTypes: Record<TREET_EVENT_TYPES, boolean>
}

/**
 * Returns a record of the courses which the current user has in their calendar
 *
 * TODO: get this list from backend instead to avoid confusion
 *        from the filter potentially missing some courses if no entries exist for a given week
 */
const getCoursesFromEvents = (calendarEvents: EnrichedCalendarEvent[] | undefined) => {
  let courseCodes: Record<string, boolean> = {}
  if (!calendarEvents) return courseCodes
  calendarEvents.forEach(({ parent_code }) => {
    if (!parent_code) return
    courseCodes[parent_code] = true
  })
  return courseCodes
}

const createFilterByProperties = (filter: FilterableProperties) => (event: EnrichedCalendarEvent) => {
  const { courses, eventTypes } = filter
  const { parent_code, event_type } = event

  // If event has course but the course is toggled off remove it
  if (parent_code && !courses[parent_code]) {
    // TODO perhaps add "undefined" option?
    return false
  }
  // If event has a type (tp.teaching, canvas.event, etc) check if it is filtered away
  if (event_type && !eventTypes[event_type as TREET_EVENT_TYPES]) {
    // TODO perhaps add "undefined" option?
    return false
  }

  // TODO how should we handle events without course or event type?
  //  - Always show?
  //  - Add a filter "no course" and "no event type"?
  return true
}

export const useCalendarScheduleFilter = (calendarEvents: EnrichedCalendarEvent[] | undefined) => {
  const parent_codes = useMemo(() => getCoursesFromEvents(calendarEvents), [calendarEvents])
  const [filter, setFilter] = useState<FilterableProperties>({
    courses: parent_codes,
    eventTypes: {
      assignment: true,
      event: true,
      exam: true,
      resource: true,
    },
  })

  // Update filtered data with users courses
  useEffect(() => {
    const updatedCourses = Object.assign({}, parent_codes, filter.courses)
    console.log('Updating courses from', filter.courses, updatedCourses)
    setFilter({ courses: updatedCourses, eventTypes: filter.eventTypes })
  }, [parent_codes])

  // Create filter togglers
  const toggleCourseFilter = useCallback(
    (courseCode: string) => {
      const filterCopy = Object.assign({}, filter)
      filterCopy.courses[courseCode] = !filterCopy.courses[courseCode]
      setFilter(filterCopy)
    },
    [filter, setFilter]
  )

  const toggleEventTypeFilter = useCallback(
    (eventType: TREET_EVENT_TYPES | string) => {
      const filterCopy = Object.assign({}, filter)
      filterCopy.eventTypes[eventType as TREET_EVENT_TYPES] = !filterCopy.eventTypes[eventType as TREET_EVENT_TYPES]
      setFilter(filterCopy)
    },
    [filter, setFilter]
  )

  // Create function for filtering events
  const filterCalendarEvent = useCallback(createFilterByProperties(filter), [filter])

  return { filter, toggleCourseFilter, toggleEventTypeFilter, filterCalendarEvent }
}
