import { createContext } from 'react'

import { Profile } from '@/generated/openapi'

export interface UserContextProps {
  user: Profile | null
  authenticated: boolean | null
  login: () => Promise<void>
  logout: () => Promise<void>
  setLocale: (language: string) => Promise<void>
  revalidate: () => void
}

const UserContext = createContext<UserContextProps | undefined>(undefined)

export default UserContext
