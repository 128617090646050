import { ComponentsOverrides, ComponentsProps, ComponentsVariants } from '@mui/material'

import { defaultFocusStyleDark, defaultFocusStyleLight } from '../focusStyling'
import { uibRed600, uibRed800 } from '../palette'

interface Muilink {
  defaultProps?: ComponentsProps['MuiLink']
  styleOverrides?: ComponentsOverrides['MuiLink']
  variants?: ComponentsVariants['MuiLink']
}

export const muiLink: Muilink = {
  styleOverrides: {
    root: {
      all: 'unset',
      color: uibRed600,
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: uibRed800,
        svg: {
          path: {
            fill: uibRed800,
          },
        },
      },
      '&:focus-visible': {
        '&::before': defaultFocusStyleDark,
        div: {
          '&::before': defaultFocusStyleDark,
        },
      },
    },
  },
  variants: [
    {
      props: { underline: 'hover' },
      style: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    {
      props: { underline: 'none' },
      style: {
        textDecoration: 'none',
      },
    },
    {
      props: { justifyContent: 'left' },
      style: {
        // Corrects overriden root styles which would be a hassle
        textAlign: 'left',
        justifyContent: 'left',
      },
    },
    {
      props: { display: 'inline-block' },
      style: {
        display: 'inline-block',
      },
    },
  ],
}
