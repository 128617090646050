import { Suspense, lazy, useEffect } from 'react'

import { MakeGenerics, Outlet, ReactLocation, Router } from '@tanstack/react-location'
import { Settings } from 'luxon'

import { useMatomo } from './contexts/matomo'
import useAuthenticatedUser from './contexts/user/useAuthenticatedUser'
import { useUibLanguage } from './language'
import { Footer, Header } from './modules/layout'
import { PwaInstallationBanner } from './modules/widgets/pwaInstallationBanner'
import { Shortcuts } from './modules/widgets/shortcuts'
import { serviceWorkerSupported } from './utils/pwaUtils'

export type LocationGenerics = MakeGenerics<{
  Params: { id: string }
}>

const location = new ReactLocation<LocationGenerics>()

const LazyHomePage = lazy(async () => import('./pages/HomePage'))
const LazyExamPage = lazy(() => import('./pages/ExamPage'))
const LazyPage404 = lazy(() => import('./pages/Page404'))
const LazySemesterStartPage = lazy(() => import('./pages/SemesterStartPage'))
const LazyTodoPage = lazy(() => import('./pages/TodoPage'))
const LazyNotificationPage = lazy(() => import('./pages/NotificationPage'))
const LazyCalendarPage = lazy(() => import('./pages/CalendarPage'))
const LazyShortcutCollectionPage = lazy(() => import('./pages/LinkCollectionPage'))

function App() {
  const { user, authenticated } = useAuthenticatedUser()
  const { locale, setLocale } = useUibLanguage()
  const {
    trackPageView,
    trackPwaUsage,
    // trackUser: { setUserId },
  } = useMatomo()

  // Sets locale for month as text rendering,
  //  using no instead of nn due to lack of nn support
  Settings.defaultLocale = locale === 'nn' ? 'no' : locale

  if (user === null) {
    // This is a hacky way to be show the English pages instead of the default Norwegian ones if the user follows a link which has a
    if (location.current.searchStr == '?lang=en') {
      // Got here from a link specifying that English is the language
      // and no user is logged in. Set the chosen language to English
      localStorage.setItem('uibTreetLanguageSettingKey', 'en')
      setLocale('en')
      Settings.defaultLocale = 'en'
    }

    location.current.pathname = '/'
  }

  useEffect(() => {
    // @todo matomo - if we wish to track users then we need some unique id
    //  such as their student-id (e.g. "pmo022") or student-mail
    // setUserId("...")

    if (authenticated) {
      trackPwaUsage()
    }
  }, [authenticated])

  return (
    <Router
      location={location}
      routes={[
        {
          path: '/shortcutCollection',
          element: <LazyShortcutCollectionPage />,
        },
        {
          path: '/',
          element: <LazyHomePage />,
        },
        {
          path: 'mySemesterStart',
          element: <LazySemesterStartPage />,
        },
        {
          path: 'myExamPage',
          element: <LazyExamPage />,
        },
        {
          path: 'todo/:id/:originPage',
          element: <LazyTodoPage />,
        },
        {
          path: 'todo/:id',
          element: <LazyTodoPage />,
        },
        {
          path: 'notifications',
          element: <LazyNotificationPage />,
        },
        {
          path: 'mySchedule',
          element: <LazyCalendarPage />,
        },
        {
          path: '*',
          element: <LazyPage404 />,
        },
      ].map((route) => ({
        onMatch: () => {
          trackPageView(route.path)
        },
        ...route,
      }))}>
      <Header />
      <Suspense>
        <main>
          <Outlet />
          <PwaInstallationBanner />
        </main>
        <footer>
          <Shortcuts />
          <Footer />
        </footer>
      </Suspense>
    </Router>
  )
}

export default App
