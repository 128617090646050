/**
 * These exam dummies have nested levels of exams and a lot of different types of exams
 * @deprecated - location field doesn't have an (s) at the end.
 * @todo update with more correct dummy data
 */
export const examDummies = {
  examsByCourse: [
    {
      course_code: 'INFO319',
      course_name: 'Research Topics in Big Data ',
      description_object: [
        {
          candidate_number: 115,
          assessment_status_value: 'Ny eksamen',
          protocol: true,
          name: '2024 Vår - Mappe, munnleg og skuleeksamen',
          exam_by_course_items: [
            {
              candidate_number: 115,
              protocol: true,
              name: '2024 Vår - Mappevurdering',
              exam_by_course_items: [
                {
                  candidate_number: 115,
                  protocol: true,
                  name: '2024 Vår - Essay',
                },
              ],
            },
            {
              candidate_number: 115,
              protocol: true,
              name: '2024 Vår - Skuleeksamen',
            },
          ],
        },
      ],
    },
    {
      course_code: 'INFO320',
      course_name: 'Research Topics in Semantic Information Systems',
      description_object: [
        {
          candidate_number: 113,
          protocol: true,
          name: '2024 Vår - Eksamen i tre deler',
          exam_by_course_items: [
            {
              start: '05.06.2024 09:00',
              duration_in_hours: 3,
              candidate_number: 113,
              protocol: true,
              name: '2024 Vår - Skuleeksamen',
            },
            {
              submission_deadline: '11.04.2024 14:00',
              candidate_number: 113,
              protocol: true,
              name: '2024 Vår - Oppgåve',
            },
            {
              submission_deadline: '10.05.2024 14:00',
              candidate_number: 113,
              protocol: true,
              name: '2024 Vår - Oppgåve i gruppe',
            },
          ],
        },
      ],
    },
    {
      course_code: 'INFO371',
      course_name: 'Research Topics in Networks and Text Analysis',
      description_object: [
        {
          candidate_number: 101,
          protocol: true,
          name: '2024 Vår - Oral exam and group based semester project',
          exam_by_course_items: [
            {
              submission_deadline: '15.05.2024 14:00',
              candidate_number: 101,
              protocol: true,
              name: '2024 Vår - Group based semester project',
            },
            {
              period_start: '27.05.24',
              period_end: '29.05.24',
              candidate_number: 101,
              protocol: true,
              name: '2024 Vår - Oral exam',
            },
          ],
        },
      ],
    },
    {
      course_code: 'INFO382',
      course_name: 'Logic for Multi-agent Systems',
      description_object: [
        {
          candidate_number: 109,
          protocol: true,
          name: '2024 Vår - Semesteroppgåve og ein munnleg prøve',
          exam_by_course_items: [
            {
              submission_deadline: '14.05.2024 14:00',
              candidate_number: 109,
              protocol: true,
              name: '2024 Vår - Semesteroppgåver',
            },
            {
              period_start: '22.05.24',
              period_end: '22.05.24',
              candidate_number: 109,
              protocol: true,
              name: '2024 Vår - Munnleg prøve',
            },
          ],
        },
      ],
    },
  ],
  examsByDate: [],
  examsForCalendar: [
    {
      end: '2024-06-05T21:59:00.000000Z',
      all_day: false,
      description: '<b>Skuleeksamen<b /><p>Varigheit: 3 timar</p><p> - Dato: 05.06.2024 12:00 </p>',
      location: [],
      url: '',
      parent_code: 'INFO320',
      title: 'Skuleeksamen (Dato)',
      event_type: 'exam',
      start: '2024-06-04T22:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: null,
      all_day: false,
      description: '<b>Oppgåve<b /><p> - Innleveringsfrist: 11.04.2024 14:00 </p>',
      location: [],
      url: '',
      parent_code: 'INFO320',
      title: 'Oppgåve (Innleveringsfrist)',
      event_type: 'exam',
      start: '2024-04-11T14:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: null,
      all_day: false,
      description: '<b>Oppgåve i gruppe<b /><p> - Innleveringsfrist: 10.05.2024 14:00 </p>',
      location: [],
      url: '',
      parent_code: 'INFO320',
      title: 'Oppgåve i gruppe (Innleveringsfrist)',
      event_type: 'exam',
      start: '2024-05-10T14:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: null,
      all_day: false,
      description: '<b>Group based semester project<b /><p> - Innleveringsfrist: 15.05.2024 14:00 </p>',
      location: [],
      url: '',
      parent_code: 'INFO371',
      title: 'Group based semester project (Innleveringsfrist)',
      event_type: 'exam',
      start: '2024-05-15T14:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: null,
      all_day: true,
      description: '<b>Oral exam<b /><p> - Periode start: 27.05.24 </p>',
      location: [],
      url: '',
      parent_code: 'INFO371',
      title: 'Oral exam (Periode start)',
      event_type: 'exam',
      start: '2024-05-27T12:34:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: '2024-05-27T12:34:00.000000Z',
      all_day: true,
      description: '<b>Oral exam<b /><p> - Periode start: 27.05.24 </p><p> - Periode slutt: 29.05.24 </p>',
      location: [],
      url: '',
      parent_code: 'INFO371',
      title: 'Oral exam (Periode slutt)',
      event_type: 'exam',
      start: '2024-05-29T12:34:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      //Flere dager hendelser
      end: '2024-08-04T12:00:00.000000Z',
      all_day: false,
      description: '<b>Oral exam<b />',
      location: [],
      url: '',
      parent_code: 'INFO371',
      title: 'Flere dager hendelser',
      event_type: 'exam',
      start: '2024-07-01T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      //Flere dager hendelser
      end: '2024-08-04T12:00:00.000000Z',
      all_day: false,
      description: '<b>Oral exam<b />',
      location: [],
      url: '',
      parent_code: 'INFO371',
      title: '6 dager hendelser',
      event_type: 'exam',
      start: '2024-07-30T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: null,
      all_day: false,
      description: '<b>Written exam<b />',
      location: [],
      url: '',
      parent_code: 'INFO371',
      title: 'Exam paper due date',
      event_type: 'exam',
      start: '2024-07-16T08:18:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: null,
      all_day: false,
      description: '<b>Semesteroppgåver<b /><p> - Innleveringsfrist: 14.05.2024 14:00 </p>',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Semesteroppgåver (Innleveringsfrist)',
      event_type: 'exam',
      start: '2024-05-14T14:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      //Inklusive dager
      end: '2024-05-20T21:59:00.000000Z',
      all_day: false,
      description: '<b>Inklusive dager<b />',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Inklusive dager',
      event_type: 'exam',
      start: '2024-05-18T22:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      //Inklusiv dag
      end: '2024-05-22T21:59:00.000000Z',
      all_day: false,
      description: '<b>Inklusiv dag<b />',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Inklusiv dag',
      event_type: 'exam',
      start: '2024-05-21T22:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      //VINTERTID
      end: '2024-10-28T12:00:00.000000Z',
      all_day: false,
      description: '<b>Vintertid<b /><p> Tid forskyves 1 time bak kl 03:00 27.10.24 </p>',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Vintertid',
      event_type: 'exam',
      start: '2024-10-26T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: '2024-10-30T12:00:00.000000Z',
      all_day: false,
      description: '<b>Etter vintertid<b />',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Etter vintertid',
      event_type: 'exam',
      start: '2024-10-29T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: '2024-10-25T12:00:00.000000Z',
      all_day: false,
      description: '<b>Før vintertid<b />',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Før vintertid',
      event_type: 'exam',
      start: '2024-10-24T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      //SOMMERTID
      end: '2024-04-01T12:00:00.000000Z',
      all_day: false,
      description: '<b>Sommertid<b /><p> Tid forskyves 1 time fram kl 02:00 31.05.24 </p>',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Sommertid',
      event_type: 'exam',
      start: '2024-03-30T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: '2024-03-29T12:00:00.000000Z',
      all_day: false,
      description: '<b>Før Sommertid<b />',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Før Sommertid',
      event_type: 'exam',
      start: '2024-03-28T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
    {
      end: '2024-04-03T12:00:00.000000Z',
      all_day: false,
      description: '<b>Etter Sommertid<b />',
      location: [],
      url: '',
      parent_code: 'INFO382',
      title: 'Etter Sommertid',
      event_type: 'exam',
      start: '2024-04-02T12:00:00.000000Z',
      source_event_type: 'fs.vurdering',
      source: 'fs',
    },
  ],
  dataLoaded: '2024-07-03T12:34:33',
}
