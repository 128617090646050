import { useCallback, useEffect, useMemo, useState } from 'react'

import { useMatomo } from '@/contexts/matomo'
import useAuthenticatedUser from '@/contexts/user/useAuthenticatedUser'
import { DEVICE_TYPES, unreliablyGetUserPlatform } from '@/utils/platformDetectionUtils/platformDetectionUtils'
import { isUsingPwa, serviceWorkerSupported } from '@/utils/pwaUtils'
import { DateTime } from 'luxon'
import useLocalStorageState from 'use-local-storage-state'

import { PwaInstallationBannerVariant } from './PwaInstallation'

const ONE_MINUTE_AS_FRACTION_OF_DAYS = 1.0 / 24 / 60
const LOCAL_STORAGE_KEY_PWA_INSTALLATION_BANNER = 'pwa_installation_banner_state'

/////
// #region OPTIONS
// - Contains options related to how often the banners should be shown,
//    and fn for checking if it should be show
/////
/** Developer flag which when set to true displays the banner more often */
const SHOW_PWA_BANNER_OFTEN = import.meta.env.VITE_PWA_BANNER_SHOW_OFTEN

/** Milliseconds to wait before showing the banner to relevant users */
const DELAY_BEFORE_SHOWING_BANNER_IN_MS = SHOW_PWA_BANNER_OFTEN ? 5000 : 60000 // 1 minute
/** Days to wait before showing the banner again if user has dismissed it */
const DAYS_BETWEEN_SHOWING_BANNER = SHOW_PWA_BANNER_OFTEN ? ONE_MINUTE_AS_FRACTION_OF_DAYS : 7
/** Maps (approximated) user device to banner variant */
const USER_DEVICE_TO_BANNER_VARIANT: Record<DEVICE_TYPES, PwaInstallationBannerVariant> = {
  android: 'chromium_mobile',
  ios: 'ios',
  // iPad rendered using ios variant, since a variant hasn't been impl yet
  //  - the "share" button is sometimes located in a different place
  ipad: 'ios',

  windows: 'chromium_desktop',
  linux: 'chromium_desktop',
  // mac/safari doesn't support pwa, so we know the user is in chrome
  // - @todo check if the install button is located elsewhere
  mac: 'chromium_desktop',
  // If we're unsure of device type just show the chromium desktop banner
  // - it will only be shown if pwa is supported so it is almost certainly chromium desktop
  other: 'chromium_desktop',

  /** @todo edge variant */
}
// #endregion

/////
//#region BannerVariant
/////
/** Calculates if any pwa banner should be shown, depending on support, time since shown, etc*/
function shouldAnyPwaBannerBeShown(
  authenticated: boolean | null,
  previousDateTimeShown: DateTime | null,
  previousDateTimeDismissed: DateTime | null
) {
  // Don't show banner to guests, when not supported, or if they already use the PWA
  if (!authenticated) return false
  if (!serviceWorkerSupported()) return false
  if (isUsingPwa()) return false

  // Show banner if it has never been shown or dismissed before
  if (previousDateTimeShown === null) {
    return true
  }
  if (previousDateTimeDismissed === null) {
    return true
  }

  // Show banner if sufficient time has passed since it was previously dismissed
  const daysSinceDismissed = -previousDateTimeDismissed.diffNow('days').days
  console.log('[PwaInstallationBanner] Banner Days since dismissed: ', daysSinceDismissed)

  return daysSinceDismissed > DAYS_BETWEEN_SHOWING_BANNER
}

/**
 * Calculates which variant of pwa banner should be shown,
 *  which depends on the users OS, device, and browser
 **/
function usePwaBannerVariant(shouldBannerBeShown: boolean, updatePreviousDateTimeShown: () => void) {
  const [bannerVariant, setBannerVariant] = useState<PwaInstallationBannerVariant | null>(null)

  const userDevice = useMemo(() => unreliablyGetUserPlatform(), [])

  useEffect(() => {
    // If we're not showing a banner set banner to be shown to null and stop calculations
    if (!shouldBannerBeShown) {
      if (bannerVariant) setBannerVariant(null)
      return
    }

    // Set a timer to show the relevant banner
    const variant = USER_DEVICE_TO_BANNER_VARIANT[userDevice]
    setTimeout(() => {
      setBannerVariant(variant)
      updatePreviousDateTimeShown()
    }, DELAY_BEFORE_SHOWING_BANNER_IN_MS)
  }, [shouldBannerBeShown])

  return { bannerVariant, setBannerVariant, userDevice }
}
//#endregion BannerVariant

/////
//#region LocalStorage
/////
type PwaBannerStorageState = {
  /** Stores the previous DateTime that the PWA installation banner was shown */
  previousDateTimeShown: string | null
  /** Stores the previous DateTime that the user closed the PWA installation banner */
  previousDateTimeDismissed: string | null
}

/** Gets the DateTime the pwa banner was last shown and dismissed and fn for updating them */
function usePwaBannerStorage() {
  // Get datetime strings from local storage
  const [localStorageState, setLocalStorageState] = useLocalStorageState<PwaBannerStorageState>(
    LOCAL_STORAGE_KEY_PWA_INSTALLATION_BANNER,
    {
      defaultValue: {
        previousDateTimeShown: null,
        previousDateTimeDismissed: null,
      },
    }
  )
  // .. and transform to DateTime for easier handling
  const previousDateTimeShown = useMemo(
    () =>
      localStorageState.previousDateTimeShown !== null
        ? DateTime.fromISO(localStorageState.previousDateTimeShown)
        : null,
    [DateTime, localStorageState.previousDateTimeShown]
  )
  const previousDateTimeDismissed = useMemo(
    () =>
      localStorageState.previousDateTimeDismissed !== null
        ? DateTime.fromISO(localStorageState.previousDateTimeDismissed)
        : null,
    [DateTime, localStorageState.previousDateTimeDismissed]
  )

  // Create memoized fn for updating the time the banner was last shown
  const updatePreviousDateTimeShown = useCallback(() => {
    setLocalStorageState({ ...localStorageState, previousDateTimeShown: DateTime.now().toISO() })
  }, [localStorageState, setLocalStorageState])
  const updatePreviousDateTimeDismissed = useCallback(() => {
    setLocalStorageState({ ...localStorageState, previousDateTimeDismissed: DateTime.now().toISO() })
  }, [localStorageState, setLocalStorageState])

  return {
    previousDateTimeDismissed,
    previousDateTimeShown,
    updatePreviousDateTimeDismissed,
    updatePreviousDateTimeShown,
  }
}
//#endregion LocalStorage

/////
//#region usePwaBanner
/////
/**
 * Hook calculating which PWA banner (if any) should be shown,
 *  and other display rules such as time before showing the banner
 **/
export default function usePwaInstallationBanner() {
  const {
    previousDateTimeShown,
    previousDateTimeDismissed,
    updatePreviousDateTimeShown,
    updatePreviousDateTimeDismissed,
  } = usePwaBannerStorage()
  const { authenticated } = useAuthenticatedUser()

  const shouldBannerBeShown = useMemo(
    () => shouldAnyPwaBannerBeShown(authenticated, previousDateTimeShown, previousDateTimeDismissed),
    [previousDateTimeShown, previousDateTimeDismissed, authenticated]
  )
  const { bannerVariant, setBannerVariant, userDevice } = usePwaBannerVariant(
    shouldBannerBeShown,
    updatePreviousDateTimeShown
  )

  const dismissBanner = useCallback(() => {
    updatePreviousDateTimeDismissed()

    setBannerVariant(null)
  }, [setBannerVariant, updatePreviousDateTimeDismissed])

  console.log(
    '[PwaInstallationBanner] shouldShowBanner, bannerToShow, device',
    shouldBannerBeShown,
    bannerVariant,
    userDevice
  )

  return {
    bannerVariant,
    userDevice,
    dismissBanner,
  }
}
//#endregion
