import { UibCustomIcon } from '@/assets'
import useAuthenticatedUser from '@/contexts/user/useAuthenticatedUser'
import { langOptions, useUibLanguage } from '@/language'
import { defaultFocusStyleLight, uibRed200 } from '@/styles'
import { FormControl, MenuItem, Select, SxProps, Theme, Typography, useTheme } from '@mui/material'

/** New language selector. Intended to be used in the Menu */
export const LanguageSelector = () => {
  const { user, setLocale: setUserLocale, revalidate } = useAuthenticatedUser()
  const theme = useTheme()
  const { setLocale, locale, langDict } = useUibLanguage()

  const changeLanguage = async (language: string) => {
    localStorage.setItem('uibTreetLanguageSettingKey', language)
    setLocale(language)
    if (!!user) {
      await setUserLocale(language)
      revalidate()
    }
  }

  return (
    <FormControl
      fullWidth
      sx={{
        paddingLeft: '3rem',
        paddingRight: '3rem',
        paddingTop: '2rem',
      }}>
      <Select
        fullWidth
        value={locale}
        aria-label={langDict('menu.chooseLanguage')}
        sx={
          {
            fieldSet: {
              borderColor: uibRed200,
              '&::focus-visible': {
                '&:before': defaultFocusStyleLight,
              },
            },
            color: theme.palette.text.secondary,
            'div p': {
              fontWeight: 400,
            },
          } as SxProps<Theme>
        }
        IconComponent={(props) => (
          <UibCustomIcon
            icon='chevron_down'
            sx={{ svg: { path: { stroke: theme.palette.text.secondary } } }}
            {...props}
          />
        )}>
        {langOptions.map((langOption, idx) => (
          <MenuItem key={idx} value={langOption.locale} onClick={() => changeLanguage(langOption.locale)}>
            <Typography variant='body1' fontWeight={locale === langOption.locale ? 600 : 400}>
              {langOption.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
