import { LinkCategory } from '@/hooks'
import { Box, Divider } from '@mui/material'

import { CategoryLinks } from './LinkCategory'
import { MainLinks } from './MainLinks'

export const ShortcutContent = ({
  isMobile,
  organizedLinks,
}: {
  isMobile: boolean
  organizedLinks?: LinkCategory[]
}) => {
  const tools = organizedLinks?.find((entry) => entry.categoryKey === 'tools')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        flexWrap: { xs: 'wrap', md: 'nowrap' },
        marginTop: '1.5rem',
        marginBottom: { xs: '2rem', md: '3rem', lg: '5rem' },
        padding: '0',
      }}>
      {tools && <MainLinks categoryEntry={tools} isMobile={isMobile} />}
      {!isMobile && (
        <Divider orientation='vertical' sx={{ height: 'inherit', borderColor: '#D2CABC', margin: '0rem 2rem' }} />
      )}
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          gridColumnGap: '1rem',
          gridRowGap: '0.5rem',
          gridTemplateColumns: { xs: '1fr', md: 'repeat(auto-fill, minmax(340px,1fr))' },
          gridTemplateRows: 'repeat(auto-fill, 36px)',
        }}>
        {organizedLinks &&
          organizedLinks.map((entry, idx) => {
            if (entry.categoryKey !== 'tools') {
              return <CategoryLinks key={idx} categoryEntry={entry} />
            }
          })}
      </Box>
    </Box>
  )
}
