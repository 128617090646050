import { pushNotificationSupported } from '../pushSubscriptionUtils/pushUtils'

const createMessageSetupLoadHandler = (messageHandler: (msg: MessageEvent) => void) => {
  return () => {
    let sw = navigator.serviceWorker
    sw.ready.then((reg) => {
      sw.addEventListener('message', messageHandler)
    })
  }
}

/**
 * Set up a {messageHandler} responsible for messages FROM the serviceworker to the frontend.
 * - This is especially Useful for refreshing data or updating views.
 *
 * @param messageHandler the message handler
 * @returns cleanup function (used for removing the on load and message eventListeners in useEffects)
 */
export const addPushMessageHandlerOnLoadWithCleanup = (messageHandler: (msg: MessageEvent) => void) => {
  if (!pushNotificationSupported()) return () => {}

  const loadHandler = createMessageSetupLoadHandler(messageHandler)
  window.addEventListener('load', loadHandler)
  return () => {
    window.removeEventListener('load', loadHandler)
    navigator?.serviceWorker?.removeEventListener('message', messageHandler)
  }
}
