import { ReactNode } from 'react'

import { defaultFocusStyleLight } from '@/styles'
import { Badge, Box, IconButton, ListItemIcon, ListItemText, SxProps, Theme, Typography } from '@mui/material'
import { Link } from '@tanstack/react-location'

import { StyledMenuListButton } from './MenuListButton'

type MenuBadgeData = {
  label: string | number
  ariaLabel: string
}

interface NavigationListItemProps {
  label: string | ReactNode
  icon: ReactNode
  path: string
  closeMenu: () => void
  badge?: MenuBadgeData
}
export const NavigationListItem = ({ label, icon, path, closeMenu, badge }: NavigationListItemProps) => {
  const menuItemLabel = (
    <Typography color='text.secondary' variant='h3'>
      {label}
    </Typography>
  )

  return (
    <Box
      sx={
        {
          a: {
            all: 'unset',
            '&:focus-visible': {
              '.MuiButtonBase-root': {
                '&::before': defaultFocusStyleLight,
              },
            },
          },
        } as SxProps<Theme>
      }>
      <Link to={path} onClick={closeMenu} style={{ textDecoration: 'none' }}>
        <StyledMenuListButton tabIndex={-1}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText>
            {badge !== undefined && badge.label !== 0 ? (
              <Badge
                variant='menuBadge'
                badgeContent={badge.label}
                overlap='rectangular'
                max={99}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                {menuItemLabel}
              </Badge>
            ) : (
              menuItemLabel
            )}
          </ListItemText>
        </StyledMenuListButton>
      </Link>
    </Box>
  )
}
