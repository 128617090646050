import { useUibLanguage } from '@/language'
import { uibFooterRed, uibRed50, uibRed500 } from '@/styles'
import { Box, Link, Typography, useTheme } from '@mui/material'

export const SubFooter = () => {
  const { langDict } = useUibLanguage()

  return (
    <Box
      sx={{
        backgroundColor: uibFooterRed,
        fontSize: '14px',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}
      padding={'1rem'}
      gap={'1rem'}>
      <Link href='https://www.uib.no/personvern/118933/personvernerkl%C3%A6ring' sx={{ color: uibRed50, '&:hover': { color: uibRed500 }}}>
        <Typography> {langDict('footer.privacy')}</Typography>
      </Link>
      <Link
        href='https://uustatus.no/nb/erklaringer/publisert/f3969bd8-8112-4646-8e90-a8406e63b6dd'
        sx={{ color: uibRed50, '&:hover': { color: uibRed500 } }}>
        <Typography> {langDict('footer.uu')}</Typography>
      </Link>
      <Link
        href='https://hjelp.uib.no/tas/public/ssp/content/serviceflow?unid=5f0ba8fa90df40ee84838c580413187c&openedFromService=true'
        sx={{ color: uibRed50, '&:hover': { color: uibRed500 } }}>
        <Typography> {langDict('footer.reportIssue')}</Typography>
      </Link>
    </Box>
  )
}
