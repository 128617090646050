import { Autocomplete, TParams, tr, useT as useTr } from 'talkr'

import nb from './translationDictionaries/nb.json'

/**
 * Define our keys by whats present in the nb language file.
 */
export type LangKey = Autocomplete<typeof nb>
/** Type definition of the langDict function - useful when passing it as a prop */
export type langDictFn = (key: LangKey, params?: TParams) => string

export const useUibLanguage = () => {
  const { locale, languages, defaultLanguage, setLocale } = useTr()

  const langDict: langDictFn = (key, params) => tr({ locale, languages, defaultLanguage }, key, params)

  return {
    langDict,
    setLocale,
    locale,
  }
}
