import {
  QUERY_KEY_CALENDAR_SCHEDULE,
  QUERY_KEY_EXAMS,
  calculateDatabucketFromVisibleRange,
  fetchCalendarEvents,
  fetchExamsQueryFn,
} from '@/api/hooks'
import { CalendarEventDetails } from '@/generated/openapi'
import { QueryClient } from '@tanstack/react-query'
import { DateTime } from 'luxon'
import { Event, Views } from 'react-big-calendar'

import { TREET_EVENT_TYPES } from './CalendarComponents/CalendarEvent/CalendarEvent'

/**
 * Contains the fields from our backend `CalendarEventDetail` and react-big-calendar `Event`,
 *  - intersecting fields use `Event` definition to avoid causing type issues in the calendar
 */
export type EnrichedCalendarEvent = Omit<CalendarEventDetails, 'start' | 'end' | 'all_day' | 'title'> &
  Event & {
    event_type: TREET_EVENT_TYPES
    uuid: string
  }

export type LuxonDateRange = {
  start: DateTime
  end: DateTime
}
export type JsDateRange = { start: Date; end: Date }
/** react-big-calendar uses a mixed type for date range depending on the view */
export type ReactBigCalendarDateRange = Date[] | JsDateRange

export const AGENDA_LENGTH = 14

/** Creates a Date object in the correct timezone/locale */
const createTzSafeJsDateFromISO = (dateStr: string) => DateTime.fromISO(dateStr).toJSDate()

/** Transforms CalendarEventDetails into the format needed by react-big-calendar */
const mapScheduleEventsToCalendarData = (scheduleEvents: CalendarEventDetails[] | undefined) => {
  return scheduleEvents
    ?.filter((event) => !!event.start)
    .map(
      (event) =>
        Object.assign({}, event, {
          title: event.title || '?',
          start: createTzSafeJsDateFromISO(event.start || ''),
          end: createTzSafeJsDateFromISO(event.end || event.start || ''),
          allDay: event.all_day || false,
          event_type: event.event_type as TREET_EVENT_TYPES,
          uuid: crypto.randomUUID(),
          // If we ever wish to group calendar events it can be done with resource field:
          // resource: event.parent_code,
        }) as EnrichedCalendarEvent
    )
}

/**
 * Transforms an exam (that is of type `CalendarEventDetails`) into an `EnrichedCalendarEvent`.
 * Handles 3 cases;
 * 1. start- and end date are defined
 * 2. start date is defined, and the event is an all-day event
 * 3. start date is defined, and the event is NOT an all-day event, that is, a moment in time (for example, a deadline)
 */
const mapExamEventsToCalendarData = (scheduleEvents: CalendarEventDetails[]): EnrichedCalendarEvent[] => {
  return scheduleEvents
    .filter((event) => !!event.start)
    .map((event) =>
      Object.assign({}, event, {
        title: event.title || '?',
        start: createTzSafeJsDateFromISO(event.start || ''),
        end: createTzSafeJsDateFromISO(event.end || event.start || ''),
        allDay: event.all_day || false,
        event_type: event.event_type as TREET_EVENT_TYPES,
        uuid: crypto.randomUUID(),
      })
    )
}

const calculateCalendarView = (range: ReactBigCalendarDateRange) => {
  // Week and workweek gives a list of 7 Dates,
  const isWeekView = Array.isArray(range)
  if (isWeekView) {
    return Views.WEEK
  }

  // Month and agenda use an actual range to represent data
  let start = DateTime.fromJSDate(range.start)
  let end = DateTime.fromJSDate(range.end)
  // ..but months start/end at start/end of day, while agenda uses current time
  let view = start.startOf('day').equals(start) && end.endOf('day').equals(end) ? Views.MONTH : Views.AGENDA

  console.log('calculated (attempt) view type', start.toISO(), end.toISO(), '=', view)
  return view
}

/**
 * Creates an localized string of the event duration - somewhat like '8. mai, 14.00-14.45',
 * The format varies depending on the event being `allDay` og over several days
 **/
const createReadableEventDurationTimeString = (start: Date, end: Date, allDay: boolean) => {
  const s = DateTime.fromJSDate(start)
  const e = DateTime.fromJSDate(end)

  // NOTE: quirk in the next loc:
  //  'day' in Luxon usually means day of the month
  //      (e.g. 2022.03.09 would give '09' as day)
  //  .. but asking if days are equal also checks large units
  //      (e.g. 'month, 'year') are equal - so it works!
  const isSingleDayEvent = s.hasSame(e, 'day')

  if (allDay) {
    if (isSingleDayEvent) {
      return `${s.toFormat('d. LLLL')}`
    } else {
      return `${s.toFormat('d. LLLL')} - ${e.toFormat('d. LLLL')}`
    }
  }

  if (isSingleDayEvent) {
    return `${s.toFormat('d. LLLL, HH:mm')} - ${e.toFormat('HH:mm')}`
  }
  // TODO case hvis start/end er samme tid (e.g. frist for innlevering)
  return `${s.toFormat('d. LLLL, HH:mm')} - ${e.toFormat('d. LLLL, HH:mm')}`
}

/**
 * Prefetch calendar events back and forth in time.
 * @param {QueryClient} queryClient
 * @param {DateTime} baseTime - The time that is used as a basis for calculating past and future months
 * @param {Object} options
 * @param {number} options.back - The number of months in the psat to prefetch
 * @param {number} options.forth - The number of months in the future to prefetch
 */
const prefetchScheduleEventsInSurroundingMonths = (
  queryClient: QueryClient,
  baseTime: DateTime,
  { back, forth }: { back: number; forth: number }
) => {
  for (let offset = -back; offset <= forth; offset++) {
    const offsetTime = baseTime.plus({ months: offset })

    const start = offsetTime.startOf('month').startOf('week').toISODate({ format: 'basic' })!
    const end = offsetTime.endOf('month').endOf('week').toISODate({ format: 'basic' })!

    queryClient.prefetchQuery({
      queryKey: [QUERY_KEY_CALENDAR_SCHEDULE, { start, end }],
      queryFn: () => fetchCalendarEvents(start, end),
      staleTime: 1000 * 60 * 60,
    })
  }
}

/**
 * Prefetch all exams, and schedule events in the given month
 * @param queryClient
 * @param date - Any date in the month to prefetch schedule events from
 */
const prefetchAllCalendarDataForMonth = (queryClient: QueryClient, date: DateTime) => {
  queryClient.prefetchQuery({
    queryKey: [QUERY_KEY_EXAMS],
    queryFn: fetchExamsQueryFn,
    staleTime: 1000 * 60 * 60,
  })

  // Get the range that will be initially loaded in the calendar...
  const start = date.startOf('month').startOf('week').toISODate({ format: 'basic' })!
  const end = date.endOf('month').endOf('week').toISODate({ format: 'basic' })!

  // ...and prefetch it
  queryClient.prefetchQuery({
    queryKey: [QUERY_KEY_CALENDAR_SCHEDULE, { start, end }],
    queryFn: () => fetchCalendarEvents(start, end),
    staleTime: 1000 * 60 * 60, // 1 hour
  })
}

/**
 * From a date range, calculate the date in the middle. That is:
 * 1. For an array of consecutive dates, take the middle element
 * 2. For a range with start and end dates, take the "average" date (start + half the number of days between start and end)
 */
const getMiddleDateOfRange = (range: ReactBigCalendarDateRange) => {
  if (Array.isArray(range)) {
    return DateTime.fromJSDate(range[range.length / 2])
  }

  const start = DateTime.fromJSDate(range.start)
  const end = DateTime.fromJSDate(range.end)
  const halfOfDaysBetween = end.diff(start).days / 2

  return start.plus({ days: halfOfDaysBetween })
}

export {
  createReadableEventDurationTimeString,
  calculateCalendarView,
  mapScheduleEventsToCalendarData,
  prefetchScheduleEventsInSurroundingMonths,
  prefetchAllCalendarDataForMonth,
  getMiddleDateOfRange,
}
