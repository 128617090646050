import { Notification } from '@/generated/openapi'

export const groupNotificationsByDate = (notifications: Notification[]) => {
  const notificationsGroupedByDay = notifications.reduce((notifGrouped: Record<string, Notification[]>, notif) => {
    let day = ''
    const dateString = notif.created_at || 'Unknown'
    if (dateString.length >= 10) {
      day = dateString.substring(0, 10)
    }
    notifGrouped[day] = notifGrouped[day] || []
    notifGrouped[day].push(notif)
    return notifGrouped
  }, {})

  return Object.entries(notificationsGroupedByDay).sort(([k1, _1], [k2, _2]) => k2.localeCompare(k1))
}
